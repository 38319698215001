import React from 'react'

const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M11.145 5.85498C11.0513 5.76185 10.9246 5.70959 10.7925 5.70959C10.6604 5.70959 10.5337 5.76185 10.44 5.85498L7.00001 9.29498L5.56 7.85498C5.51518 7.80264 5.46002 7.76013 5.39799 7.73012C5.33595 7.70011 5.26839 7.68325 5.19953 7.68059C5.13067 7.67793 5.062 7.68952 4.99784 7.71466C4.93368 7.7398 4.87541 7.77793 4.82668 7.82666C4.77795 7.87539 4.73982 7.93366 4.71468 7.99782C4.68955 8.06198 4.67795 8.13065 4.68061 8.19951C4.68327 8.26837 4.70013 8.33593 4.73014 8.39796C4.76015 8.46 4.80266 8.51516 4.855 8.55998L6.64501 10.355C6.69149 10.4018 6.74679 10.439 6.80772 10.4644C6.86864 10.4898 6.934 10.5029 7.00001 10.5029C7.06601 10.5029 7.13137 10.4898 7.1923 10.4644C7.25323 10.439 7.30852 10.4018 7.355 10.355L11.145 6.55998C11.2381 6.4663 11.2904 6.33958 11.2904 6.20749C11.2904 6.0754 11.2381 5.94866 11.145 5.85498Z"
      fill="#14C415"
    />
    <path
      d="M8 1C6.61553 1 5.26215 1.41055 4.11101 2.17972C2.95987 2.94889 2.06265 4.04213 1.53284 5.32121C1.00303 6.6003 0.864412 8.00776 1.13451 9.36563C1.40461 10.7235 2.07128 11.9708 3.05025 12.9498C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.39971 14.997 10.6788 14.4672C11.9579 13.9374 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14349 14.2625 4.363 12.9498 3.05025C11.637 1.73749 9.85652 1 8 1ZM8 14C6.81331 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88379 7.99334 2.1153 6.82945C2.34681 5.66557 2.91824 4.59647 3.75736 3.75735C4.59647 2.91824 5.66557 2.34681 6.82946 2.1153C7.99334 1.88378 9.19974 2.0026 10.2961 2.45673C11.3925 2.91085 12.3295 3.67989 12.9888 4.66658C13.6481 5.65328 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
      fill="#14C415"
    />
  </svg>
)

CheckIcon.propTypes = {}

CheckIcon.defaultProps = {}

export default CheckIcon
