import React from 'react'
import i18next from 'i18next'
import Icon from 'lib/utils/icon'
import {openURL} from 'lib/utils/utility'
import './itktutorial.scss'

export function renderItktutorial() {
  return (
    <div className="sph__st__popup__wrapper">
      <p>{i18next.t('bp245')}</p>
      <a
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
        className="sph__st__popup__wrapper__link"
        onClick={() => {
          openURL('https://staging-new-enphase.pantheonsite.io/en-us/support/faq/what-profile-and-when-chose-each-one')
        }}>
        {i18next.t('bp246')}
        <Icon src="externalLink" />
      </a>
    </div>
  )
}
