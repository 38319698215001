/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {isWeb} from 'lib/utils/utility'
import {hasEnpower, systemWithEnchargeAndEnpower, showSavingsMode} from '../../../../utils/siteDetails'
import Icon from '../../../../utils/icon'
import './styles.scss'

const StoragePreferenceChoiceCard = ({value, _id, title, subtitle, icon, tutorialView, tutorialClick}) => {
  if (!(value && title && subtitle && icon)) {
    return ''
  }
  return (
    <div className="storage-preference-choice-card">
      <Icon src="draggable" />
      <div className="card-icon">
        <Icon src={icon || 'saveMoney'} />
      </div>
      <div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>

      {tutorialView && _id === 1 && (
        <div className="ftrp__tutorial ftrp__tutorial__uphand-draggable">
          <Icon src="upHandDraggable" />

          <div className="ftrp__tutorial__wrapper">
            <div
              className={`ftrp__tutorial__wrapper__desc ${!(systemWithEnchargeAndEnpower() && showSavingsMode()) && 'without-encharge'}`}>
              {i18next.t('bp149')}
            </div>
            <button type="button" onClick={() => tutorialClick('gotIt')}>
              {i18next.t('bp121')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default class StoragePreferenceList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      choices: this.choices(),
      tutorialView: false,
    }

    this.initializeChoices()
    this.onDragEnd = this.onDragEnd.bind(this)
    this.tutorialClick = this.tutorialClick.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
  }

  componentDidMount() {
    if (!isWeb()) {
      this.canShowTutorial(true)
    }
  }

  onSubmit = () => {
    const {choices} = this.state
    const firstVal = choices[0].value
    const secondVal = choices[1].value
    this.props.onSubmit(firstVal, secondVal)
  }

  onDragEnd(result) {
    const {choices} = this.state

    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newChoices = this.reorder(choices, result.source.index, result.destination.index)

    this.setState({
      choices: newChoices,
    })

    const ref = document.querySelector('.storageprofileassistant')
    ref.style = null
  }

  onDragStart() {
    // eslint-disable-next-line no-unused-vars
    const self = this
    const ref = document.querySelector('.storageprofileassistant')
    ref.style.overflow = 'hidden'
  }

  reorder = (list, startIndex, endIndex) => {
    // eslint-disable-next-line no-unused-vars
    const self = this
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  choices = () => {
    // eslint-disable-next-line no-unused-vars
    const self = this
    if (!showSavingsMode()) {
      return [
        {
          id: 0,
          value: 1,
          title: i18next.t('bp150'),
          subtitle: i18next.t('bp153'),
          icon: 'engInd',
        },

        {
          id: 1,
          value: 3,
          title: i18next.t('bp152'),
          subtitle: i18next.t('bp155'),
          icon: 'powerBkp',
        },
      ]
    }
    return [
      {
        id: 0,
        value: 1,
        title: i18next.t('bp150'),
        subtitle: i18next.t('bp153'),
        icon: 'engInd',
      },
      {
        id: 1,
        value: 2,
        title: i18next.t('bp151'),
        subtitle: i18next.t('bp154'),
        icon: 'saveMoney',
      },
      {
        id: 2,
        value: 3,
        title: i18next.t('bp152'),
        subtitle: i18next.t('bp155'),
        icon: 'powerBkp',
      },
    ]
  }

  initializeChoices() {
    if (!systemWithEnchargeAndEnpower()) {
      const {choices} = this.state
      this.setState({choices: choices.pop()})
    }
  }

  dragDropProfile() {
    const {choices, tutorialView} = this.state

    const isDragDisabled = () => tutorialView !== false

    return (
      <DragDropContext onDragEnd={this.onDragEnd} isDragDisabled={isDragDisabled()} onDragStart={this.onDragStart}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {choices.map((obj, index) => (
                <Draggable key={obj.id} draggableId={obj.id.toString()} index={index} isDragDisabled={isDragDisabled()}>
                  {providedd => (
                    <div ref={providedd.innerRef} {...providedd.draggableProps} {...providedd.dragHandleProps}>
                      <StoragePreferenceChoiceCard
                        key={obj.id}
                        value={obj.value}
                        _id={obj.id}
                        title={obj.title}
                        subtitle={obj.subtitle}
                        icon={obj.icon}
                        tutorialView={tutorialView}
                        tutorialClick={this.tutorialClick}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  canShowTutorial(showTutorial) {
    // const showTutorial = cookies.get('ftrp-tutorial') !== 'dismissed'

    if (showTutorial) {
      this.setState({tutorialView: true})
      // this.tutorialView = 'show'
    } else {
      this.setState({tutorialView: false})
      // this.tutorialView = false
    }
  }

  tutorialClick(val) {
    if (val === 'gotIt') {
      // cookies.set('ftrp-tutorial', 'dismissed', { expires: 2147483647 })
      this.canShowTutorial(false)
    }
  }

  render() {
    const {tutorialView} = this.state
    const className = [!hasEnpower() ? 'ftrp__no_enpower' : '']
    className.push(
      tutorialView
        ? 'storageprofileassistant__ftrp__has__tutorial'
        : 'storageprofileassistant__ftrp__has__withouttutorial'
    )

    return (
      <div className={className.join(' ')}>
        <div className={`${className.join(' ')}__storage-pref-list-screen`}>
          <div className={`${className.join(' ')}__storage-pref-list-screen__ques`}>{i18next.t('bp156')}</div>
          <div className={`${className.join(' ')}__storage-pref-list-screen__priority`}>{i18next.t('bp157')}</div>
          <div>{this.dragDropProfile()}</div>
          <div
            role="button"
            tabIndex={0}
            className={`${className.join(' ')}__storage-pref-list-screen__button sticky-at-home`}
            onClick={this.onSubmit}
            onKeyDown={this.onSubmit}>
            {i18next.t('bp158')}
            {/* I18n.t('mobile.button.continue') */}
          </div>
        </div>
      </div>
    )
  }
}

StoragePreferenceList.propTypes = {
  onSubmit: PropTypes.func,
}

StoragePreferenceList.defaultProps = {
  onSubmit: null,
}

StoragePreferenceChoiceCard.propTypes = {
  value: PropTypes.number,
  _id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  tutorialView: PropTypes.bool,
  tutorialClick: PropTypes.func,
}

StoragePreferenceChoiceCard.defaultProps = {
  value: 0,
  _id: 0,
  title: null,
  subtitle: null,
  icon: null,
  tutorialView: true,
  tutorialClick: null,
}
