import React from 'react'
import {Provider} from 'react-redux'
import ProfileDetails from 'lib/component/common/organisms/ProfileDetails'
import SubPage from 'lib/component/common/organisms/SubPage'
import {useTheme, useAddUiClasses} from 'lib/hook'
import '../../../locale/i18n'
import store from '../../../store'
import './styles.scss'

const Profile = () => (
  // Profile
  <Provider store={store}>
    <div id="enho-profile">
      {useTheme()}
      {useAddUiClasses()}

      <ProfileDetails />
      <SubPage />
    </div>
  </Provider>
)

Profile.propTypes = {}

Profile.defaultProps = {}

export default Profile
