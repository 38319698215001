import React from 'react'
import PropTypes from 'prop-types'

export default class EnergyGeneratorCircle extends React.PureComponent {
  render() {
    const {nightMode} = this.props
    const color = nightMode ? '#dca2ff' : '#9062AC'
    const bgColor = nightMode ? '#262627' : '#ffffff'

    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.37 22.636h15.26v11.711H16.37V22.636Zm1.067 1.067v9.58h13.13v-9.58h-13.13Z"
            fill={color}
            stroke={color}
            strokeWidth=".3"
          />
          <path
            d="m24.201 28.369.458-2.362-2.078 3.374h1.17l-.388 2.3 2.057-3.316-1.219.004Z"
            fill={color}
            stroke={color}
            strokeWidth=".3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.37 37.363v-3.549h1.067v3.549H16.37Zm14.196 0v-3.549h1.065v3.549h-1.065Z"
            fill={color}
            stroke={color}
            strokeWidth=".3"
          />
          <path d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z" fill={bgColor} />
          <path
            d="M24 47.25c12.84 0 23.25-10.41 23.25-23.25S36.84.75 24 .75.75 11.16.75 24 11.16 47.25 24 47.25Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M32.398 36.6v-1.8h-15.6v1.8a.57.57 0 0 1-.6.6.571.571 0 0 1-.6-.6v-1.8h-3a.57.57 0 0 1-.6-.6V18.6a.568.568 0 0 1 .6-.6h9v-2.4h-9a.567.567 0 0 1-.6-.6v-2.4a.568.568 0 0 1 .6-.6h13.2a.564.564 0 0 1 .6.6V15a.566.566 0 0 1-.6.6h-.6V18h11.4a.566.566 0 0 1 .6.6v15.6a.569.569 0 0 1-.6.6h-3v1.8a.6.6 0 1 1-1.2 0Zm-19.2-3h22.8V19.2h-22.8v14.4Zm10.8-15.6v-2.4h-1.2V18h1.2Zm-10.8-3.6h12v-1.2h-12v1.2Zm1.8 16.8a.571.571 0 0 1-.6-.6v-8.4a.568.568 0 0 1 .6-.6h8.4a.564.564 0 0 1 .6.6v8.4a.569.569 0 0 1-.6.6h-8.4Zm.6-1.2h7.2v-7.2h-7.2V30Z"
            fill={color}
            stroke={color}
            strokeWidth=".3"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h48v48H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

EnergyGeneratorCircle.propTypes = {nightMode: PropTypes.bool}

EnergyGeneratorCircle.defaultProps = {nightMode: false}
