/* eslint-disable fp/no-mutation */
import HTTP_REQUEST, {HTTPS_METHOD, HTTPS_API_STATUS} from './httpRequest'

/* *********************** *********************** *********************** *
 *  Disclaimer API
 * *********************** *********************** *********************** */

let data = null
let promise = null

function callAPI(type, pathName, params = {}) {
  promise = HTTP_REQUEST.makeRequest(type, pathName, params)
  return promise
}

// Set Disclaimer
async function setDisclaimer(body) {
  try {
    data = await callAPI(HTTPS_METHOD.POST, 'SET_DISCLAIMER', body)
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      error,
    }
    promise = null
    throw data
  }

  return data
}

export {setDisclaimer}
