import React from 'react'
import i18next from 'i18next'
import {getStatusIcon} from 'lib/utils/utility'
import Icon from '../../../../utils/icon'
import {LIVE_STATUS} from '../../../../utils/constants'
import LiveStatusWithArrows from '../../molecules/LiveStatusWithArrows'
import {hasEnpower, isSavingsModeDisabledForEmea, isEvseSite, hasHemsDevices} from '../../../../utils/siteDetails'

function showStatusIcon(statusType, type) {
  const iconType = getStatusIcon(type)

  if (statusType === 'grid' || statusType === 'generator') {
    return <Icon className="mqtt-status-icon" src={iconType} />
  }
  return type === LIVE_STATUS.UNKNOWN ? <Icon src="systemError" /> : <span className={type} />
}

export const renderSavingsModePeak = () => {
  const batteryCharged = 90
  const gridIcon = 'onGrid'
  const gridStatus = `${i18next.t('bp140')}`
  const lsData = {
    className: 'savings-mode-peak',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: true,
      icon: null,
      label: null,
      rowValue: 1.6,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 3.3,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: -1.6,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 3.3,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__peak" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp122')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>

          <div className="bp__profile__wrapper__status__grid">
            <div className="bp__profile__wrapper__status__grid__indicator">{showStatusIcon('grid', gridIcon)}</div>
            <div className="bp__profile__wrapper__status__grid__text">{gridStatus}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper__live-status noEnergyIndependence">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp123')}</div>
        <ul>
          <li>{i18next.t('bp124')}</li>
          <li>{i18next.t('bp125')}</li>
          {isEvseSite() && <li>{i18next.t('bp248')}</li>}
        </ul>
      </div>
    </div>
  )
}

export const renderSavingsModeOffPeakWithSolar = () => {
  const batteryCharged = 70
  const gridIcon = 'onGrid'
  const gridStatus = `${i18next.t('bp140')}`

  // eslint-disable-next-line no-unused-vars
  const toggleSwitchObj = {
    data: {
      className: 'eISwitch_toggle',
      handleColor: '#01b4de',
      height: 14,
      width: 34,
      label: null,
      bgColorOn: '#e3e3e6',
      bgColorOff: '#e3e3e6',
      handleDiameter: 20,
    },
  }

  const lsData = {
    className: 'savings-mode-off-peak-with-solar',
    solar: {
      toHome: true,
      toBattery: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 4.6,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 3.6,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 2.8,
      unit: 'kW',
    },
    generator: {},
    battery: {
      batteryToHome: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: -3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-with-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp122')}</div>

        <div className="bp__profile__wrapper__ei">
          <div className="bp__profile__wrapper__ei__title">
            {i18next.t('bp35')}: &nbsp;
            <span className="bp__profile__wrapper__ei__title__disabled">{i18next.t('bp8')}</span>
          </div>
          {/* <ToggleSwitch
            data={ toggleSwitchObj.data }
            inProgress={false}
            checked={false}
            handleChange={this.eiToggle}
          /> */}
        </div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>

          <div className="bp__profile__wrapper__status__grid">
            <div className="bp__profile__wrapper__status__grid__indicator">{showStatusIcon('grid', gridIcon)}</div>
            <div className="bp__profile__wrapper__status__grid__text">{gridStatus}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper_live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp126')}</div>
        <ul>
          <li>{i18next.t('bp127')}</li>
          <li>{i18next.t('bp128')}</li>
          {isEvseSite() && <li>{i18next.t('bp249')}</li>}
        </ul>
      </div>
    </div>
  )
}

export const renderSavingsModeOffPeakWithoutSolar = () => {
  const batteryCharged = 80
  const gridIcon = 'onGrid'
  const gridStatus = `${i18next.t('bp140')}`

  const lsData = {
    className: 'savings-mode-off-peak-without-solar',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 1.3,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 1.3,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp122')}</div>

        <div className="bp__profile__wrapper__ei">
          <div className="bp__profile__wrapper__ei__title">
            {i18next.t('bp35')}: &nbsp;
            <span className="bp__profile__wrapper__ei__title__enabled">{i18next.t('bp7')}</span>
          </div>
          {/* <ToggleSwitch
            data={ toggleSwitchObj.data }
            inProgress={false}
            checked={false}
            handleChange={this.eiToggle}
          /> */}
        </div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>

          <div className="bp__profile__wrapper__status__grid">
            <div className="bp__profile__wrapper__status__grid__indicator">{showStatusIcon('grid', gridIcon)}</div>
            <div className="bp__profile__wrapper__status__grid__text">{gridStatus}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper__live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp126')}</div>
        <ul>
          {hasEnpower() ? (
            <>
              <li>{i18next.t('bp130')}</li>
              <li>{i18next.t('bp131')}</li>
              {isEvseSite() && <li>{i18next.t('bp247')}</li>}
            </>
          ) : (
            <>
              <li>{i18next.t('bp132')}</li>
              <li>{i18next.t('bp131')}</li>
              {isEvseSite() && <li>{i18next.t('bp247')}</li>}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

export const renderSelfConsumption = myRef => {
  const gridIcon = 'onGrid'
  const gridStatus = `${i18next.t('bp140')}`
  let batteryCharged = 100

  if (hasEnpower()) {
    // eslint-disable-next-line fp/no-mutation
    batteryCharged = 70
  }

  const lsData = {
    className: 'self-consumption',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 4.6,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 8.9,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 0.5,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__self-consumption" className="bp__profile bp__self-consumption" ref={myRef}>
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{hasEnpower() ? i18next.t('bp164') : i18next.t('bp163')}</div>{' '}
        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>

          <div className="bp__profile__wrapper__status__grid">
            <div className="bp__profile__wrapper__status__grid__indicator">{showStatusIcon('grid', gridIcon)}</div>
            <div className="bp__profile__wrapper__status__grid__text">{gridStatus}</div>
          </div>
        </div>
        <div className="bp__profile__wrapper__live-status noEnergyIndependence">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        {!isSavingsModeDisabledForEmea() && <div className="bp__desc__title">{i18next.t('bp133')}</div>}
        <ul>
          <li>{i18next.t('bp134')}</li>
          <li>{i18next.t('bp135')}</li>
          {isEvseSite() && <li>{i18next.t('bp250')}</li>}
        </ul>
      </div>
    </div>
  )
}

export const renderFullBackup = () => {
  const batteryCharged = 100
  const gridIcon = 'onGrid'
  const gridStatus = `${i18next.t('bp140')}`

  const lsData = {
    className: 'full-backup',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 1.7,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 5.0,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 3.3,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__full-backup" className="bp__profile bp__full-backup">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp137')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>

          <div className="bp__profile__wrapper__status__grid">
            <div className="bp__profile__wrapper__status__grid__indicator">{showStatusIcon('grid', gridIcon)}</div>
            <div className="bp__profile__wrapper__status__grid__text">{gridStatus}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper__live-status noEnergyIndependence">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp136')}</div>
        <ul>
          <li>{i18next.t('bp138')}</li>
          <li>{i18next.t('bp139')}</li>
          {isEvseSite() && <li>{i18next.t('bp249')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT supported site -- Battery charge is greater than reserve
export const renderSelfConsumptionModeDTSupported = myRef => {
  const batteryCharged = 100

  const lsData = {
    className: 'self-consumption',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 4.6,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 8.9,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 0.5,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__self-consumption" className="bp__profile bp__self-consumption" ref={myRef}>
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp205')}</div>{' '}
        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>
        <div className="bp__profile__wrapper__live-status noEnergyIndependence">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp320')}</div>
        <ul>
          <li>{i18next.t('bp321')}</li>
          <li>{i18next.t('bp322')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp323')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT supported site -- System behavior when prices are negative
export const renderSavingsModePriceAreNegativeDTSupported = () => {
  const batteryCharged = 70

  const lsData = {
    className: 'savings-mode-grid-to-storage',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 5.0,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: true,
      icon: null,
      label: null,
      rowValue: 8.8,
      unit: 'kW',
    },
    generator: {},
    battery: {
      batteryToHome: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: -3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-with-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper_live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp324')}</div>
        <ul>
          <li>{i18next.t('bp325')}</li>
          <li>{i18next.t('bp326')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp377')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT supported site -- System behavior when prices are low
export const renderSavingsModePriceAreLowDTSupported = () => {
  const batteryCharged = 70

  const lsData = {
    className: 'savings-mode-off-peak-with-solar',
    solar: {
      toHome: true,
      toBattery: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 7.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 5.0,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 1.8,
      unit: 'kW',
    },
    generator: {},
    battery: {
      batteryToHome: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: -3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-with-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper_live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp328')}</div>
        <ul>
          <li>{i18next.t('bp329')}</li>
          <li>{i18next.t('bp330')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp377')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT supported site -- System behavior when prices are high
export const renderSavingsModePriceAreHighDTSupported = () => {
  const batteryCharged = 70

  const lsData = {
    className: 'savings-mode-off-peak-without-solar',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: true,
      icon: null,
      label: null,
      rowValue: 7.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 3.8,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: i18next.t('bp143'),
      rowValue: 7.0,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper__live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp332')}</div>
        <ul>
          <li>{i18next.t('bp333')}</li>
          <li>{i18next.t('bp334')}</li>
          <li>{i18next.t('bp335')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp336')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For NEM 3.0 with NEM 3.0 Tariff
export const renderNEMSavingsModePeak = () => {
  const batteryCharged = 90
  const gridIcon = 'onGrid'
  const gridStatus = `${i18next.t('bp140')}`
  const lsData = {
    className: 'savings-mode-off-peak-with-solar',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 1.6,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 3.3,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 1.7,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__peak" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp122')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>

          <div className="bp__profile__wrapper__status__grid">
            <div className="bp__profile__wrapper__status__grid__indicator">{showStatusIcon('grid', gridIcon)}</div>
            <div className="bp__profile__wrapper__status__grid__text">{gridStatus}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper__live-status noEnergyIndependence">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp123')}</div>
        <ul>
          <li>{i18next.t('bp124')}</li>
          <li>{i18next.t('bp343')}</li>
          {isEvseSite() && <li>{i18next.t('bp248')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For NEM 3.0 with NEM 3.0 Tariff
export const renderNEMSavingsModeOffPeakWithoutSolar = () => {
  const batteryCharged = 80
  const gridIcon = 'onGrid'
  const gridStatus = `${i18next.t('bp140')}`

  const lsData = {
    className: 'savings-mode-off-peak-without-solar',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 1.3,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 1.3,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp122')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>

          <div className="bp__profile__wrapper__status__grid">
            <div className="bp__profile__wrapper__status__grid__indicator">{showStatusIcon('grid', gridIcon)}</div>
            <div className="bp__profile__wrapper__status__grid__text">{gridStatus}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper__live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp126')}</div>
        <ul>
          {hasEnpower() ? (
            <>
              <li>{i18next.t('bp130')}</li>
              <li>{i18next.t('bp131')}</li>
              {isEvseSite() && <li>{i18next.t('bp247')}</li>}
            </>
          ) : (
            <>
              <li>{i18next.t('bp132')}</li>
              <li>{i18next.t('bp131')}</li>
              {isEvseSite() && <li>{i18next.t('bp247')}</li>}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

// For PVOnly Evse site - self-consumption
export const renderSelfConsumptionModeInPVOnlyForEvseSite = myRef => {
  const lsData = {
    className: 'self-consumption',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 5.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 8.3,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 3.3,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__self-consumption" className="bp__profile bp__self-consumption" ref={myRef}>
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp378')}</div>
        <div className="bp__profile__wrapper__live-status noEnergyIndependence arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp164')}</div>
        <ul>
          <li>{i18next.t('bp370')}</li>
          <li>{i18next.t('bp371')}</li>
          <li>{i18next.t('bp372')}</li>
        </ul>
      </div>
    </div>
  )
}

// For PVOnly DT supported site - self-consumption
export const renderSelfConsumptionModeInPVOnlyForDTSupported = myRef => {
  const lsData = {
    className: 'self-consumption',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 5.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 8.3,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 3.3,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__self-consumption" className="bp__profile bp__self-consumption" ref={myRef}>
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp378')}</div>
        <div className="bp__profile__wrapper__live-status noEnergyIndependence arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp164')}</div>
        <ul>
          <li>{i18next.t('bp370')}</li>
          <li>{i18next.t('bp371')}</li>
        </ul>
      </div>
    </div>
  )
}

// For PVOnly DT supported site - savings rates are positive
export const renderSavingsModePositiveRatesInPVOnlyForDTSupported = () => {
  const lsData = {
    className: 'savings-mode-solar-to-grid-home',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: true,
      icon: null,
      label: null,
      rowValue: 7.3,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 3.0,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: i18next.t('bp143'),
      rowValue: 4.3,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>
        <div className="bp__profile__wrapper__live-status arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp373')}</div>
        <ul>
          <li>{i18next.t('bp374')}</li>
          <li>{i18next.t('bp375')}</li>
        </ul>
      </div>
    </div>
  )
}

// For PVOnly DT supported site - savings rates are negative
export const renderSavingsModeNegativeRatesInPVOnlyForDTSupported = () => {
  const lsData = {
    className: 'savings-mode-peak-grid-to-home',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 9.4,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 9.4,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>
        <div className="bp__profile__wrapper__live-status arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp324')}</div>
        <ul>
          <li>{i18next.t('bp376')}</li>
        </ul>
      </div>
    </div>
  )
}

// For PVOnly DT supported site with HEMS or EVSE Devices - self-consumption
export const renderSelfConsumptionModeInPVOnlyForDTSupportedWithDevices = myRef => {
  const lsData = {
    className: 'self-consumption',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 5.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 8.3,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 3.3,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__self-consumption" className="bp__profile bp__self-consumption" ref={myRef}>
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp378')}</div>
        <div className="bp__profile__wrapper__live-status noEnergyIndependence arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp164')}</div>
        <ul>
          <li>{i18next.t('bp370')}</li>
          <li>{i18next.t('bp371')}</li>
          {(hasHemsDevices() || isEvseSite()) && <li>{i18next.t('bp372')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For PVOnly DT supported site with HEMS or EVSE Devices - savings rates are negative
export const renderSavingsModeNegativeRatesInPVOnlyForDTSupportedWithDevices = () => {
  const lsData = {
    className: 'savings-mode-peak-grid-to-home',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 9.4,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 9.4,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>
        <div className="bp__profile__wrapper__live-status arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp324')}</div>
        <ul>
          <li>{i18next.t('bp376')}</li>
          {(hasHemsDevices() || isEvseSite()) && <li>{i18next.t('bp377')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For PVOnly DT supported site with HEMS or EVSE Devices -- System behavior when prices are low
export const renderSavingsModePriceAreLowInPVOnlyForDTSupportedWithDevices = () => {
  const lsData = {
    className: 'savings-mode-off-peak-with-solar',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 8.5,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 9.6,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 1.1,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-with-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>
        <div className="bp__profile__wrapper__live-status arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp328')}</div>
        <ul>
          <li>{i18next.t('bp472')}</li>
          {(hasHemsDevices() || isEvseSite()) && <li>{i18next.t('bp377')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For PVOnly DT supported site with HEMS or EVSE Devices - savings rates are High
export const renderSavingsModePriceAreHighInPVOnlyForDTSupportedWithDevices = () => {
  const lsData = {
    className: 'savings-mode-solar-to-grid-home',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: true,
      icon: null,
      label: null,
      rowValue: 7.3,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 3.0,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: i18next.t('bp143'),
      rowValue: 4.3,
      unit: 'kW',
    },
    generator: {},
    battery: {},
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>
        <div className="bp__profile__wrapper__live-status arrows-without-battery">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp332')}</div>
        <ul>
          <li>{i18next.t('bp473')}</li>
          {(hasHemsDevices() || isEvseSite()) && <li>{i18next.t('bp336')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT Germany site -- Battery charge is greater than reserve
export const renderSelfConsumptionModeDTGermanySupported = myRef => {
  const batteryCharged = 100

  const lsData = {
    className: 'self-consumption',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 4.6,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 8.9,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 0.5,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__self-consumption" className="bp__profile bp__self-consumption" ref={myRef}>
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp205')}</div>{' '}
        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>
        <div className="bp__profile__wrapper__live-status noEnergyIndependence">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp320')}</div>
        <ul>
          <li>{i18next.t('bp321')}</li>
          <li>{i18next.t('bp322')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp323')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT Germany site -- System behavior when prices are negative
export const renderSavingsModePriceAreNegativeDTGermanySupported = () => {
  const batteryCharged = 70

  const lsData = {
    className: 'savings-mode-grid-to-storage',
    solar: {
      toHome: false,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 3.1,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 3.1,
      unit: 'kW',
    },
    generator: {},
    battery: {
      batteryToHome: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-with-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper_live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp324')}</div>
        <ul>
          <li>{i18next.t('bp325')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp377')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT Germany site -- System behavior when prices are low
export const renderSavingsModePriceAreLowDTGermanySupported = () => {
  const batteryCharged = 70

  const lsData = {
    className: 'savings-mode-off-peak-with-solar',
    solar: {
      toHome: true,
      toBattery: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 7.0,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 5.0,
      unit: 'kW',
    },
    grid: {
      toHome: true,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 1.8,
      unit: 'kW',
    },
    generator: {},
    battery: {
      batteryToHome: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: -3.8,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-with-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper_live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp328')}</div>
        <ul>
          <li>{i18next.t('bp329')}</li>
          <li>{i18next.t('bp330')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp377')}</li>}
        </ul>
      </div>
    </div>
  )
}

// For DT Germany site -- System behavior when prices are high
export const renderSavingsModePriceAreHighDTGermanySupported = () => {
  const batteryCharged = 70

  const lsData = {
    className: 'full-backup',
    solar: {
      toHome: true,
      toBattery: false,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 8.5,
      unit: 'kW',
    },
    home: {
      icon: null,
      label: null,
      rowValue: 9.6,
      unit: 'kW',
    },
    grid: {
      toHome: false,
      toBattery: false,
      icon: null,
      label: null,
      rowValue: 0.0,
      unit: 'kW',
    },
    generator: {},
    battery: {
      toHome: true,
      toGrid: false,
      icon: null,
      label: null,
      rowValue: 1.1,
      unit: 'kW',
    },
    isGridConnected: true,
    isGenConnected: false,
  }

  return (
    <div key="bp__savings-mode__off-peak-without-solar" className="bp__profile bp__savings-mode">
      <div className="bp__profile__wrapper">
        <div className="bp__profile__wrapper__heading">{i18next.t('bp206')}</div>

        <div className="bp__profile__wrapper__status">
          <div className="bp__profile__wrapper__status__battery">
            <Icon src="batteryCharge" percent={batteryCharged} />
            <div className="bp__profile__wrapper__status__battery__text">{`${batteryCharged}%`}</div>
          </div>
        </div>

        <div className="bp__profile__wrapper__live-status">
          <LiveStatusWithArrows lsData={JSON.stringify(lsData)} />
        </div>
      </div>

      <div className="bp__desc">
        <div className="bp__desc__title">{i18next.t('bp332')}</div>
        <ul>
          <li>{i18next.t('bp333')}</li>
          <li>{i18next.t('bp418')}</li>
          <li>{i18next.t('bp419')}</li>
          {hasHemsDevices() && <li>{i18next.t('bp336')}</li>}
        </ul>
      </div>
    </div>
  )
}
