import React from 'react'
import {isDarkTheme} from 'lib/hook/useTheme'
import Icon from 'lib/utils/icon'
import './styles.scss'

const LazyFallback = () => (
  <div className={isDarkTheme() ? 'dark-lazy-fallback' : 'lazy-fallback'}>
    <Icon src="loaderV4" />
  </div>
)

LazyFallback.propTypes = {}

export default LazyFallback
