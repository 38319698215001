import React from 'react'

const Draggable = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12">
    <g transform="translate(11.41 -4.29) rotate(90)">
      <g fill="#01b5de" stroke="none" strokeWidth="0.5px" transform="translate(4.29 -3.59)">
        <rect stroke="none" width="1.5" height="15" />
        <rect fill="none" x="0.25" y="0.25" width="2.5" height="14.5" />
      </g>
      <g fill="#01b5de" stroke="none" strokeWidth="0.5px" transform="translate(8.79 -3.59)">
        <rect stroke="none" width="1.5" height="15" />
        <rect fill="none" x="0.25" y="0.25" width="2.5" height="14.5" />
      </g>
      <g fill="#01b5de" stroke="none" strokeWidth="0.5px" transform="translate(13.29 -3.59)">
        <rect stroke="none" width="1.5" height="15" />
        <rect fill="none" x="0.25" y="0.25" width="2.5" height="14.5" />
      </g>
    </g>
  </svg>
)
export default Draggable
