/* eslint-disable fp/no-mutation */
import React from 'react'

const energySolarToHomeLmab = ({className}) => {
  let pointer = 'pointer-solar-home'
  let motionPath = 'motion-path-solar-home'

  if (className) {
    pointer = `${pointer}-${className}`
    motionPath = `${motionPath}-${className}`
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56.498" height="29.318" viewBox="0 0 56.498 29.318">
      <g id="SH" transform="translate(1.988 -0.987)">
        <g id="line">
          <path
            id="Path_20511"
            data-name="Path 20511"
            d="M-12650.872-16320.572a.225.225,0,0,1-.127-.205v-6.441a.23.23,0,0,1,.127-.205.2.2,0,0,1,.226.049l3.024,3.222a.217.217,0,0,1,.062.155.212.212,0,0,1-.062.155l-3.024,3.221a.2.2,0,0,1-.148.065A.2.2,0,0,1-12650.872-16320.572Z"
            transform="translate(12702.07 16350.862)"
            fill="#01b5de"
          />
          <path
            id={motionPath}
            data-name="Path 20512"
            d="M-2,0V19a7,7,0,0,0,7,7H46"
            transform="translate(6.512 0.987)"
            fill="none"
            stroke="#01b5de"
            strokeWidth="1"
          />
        </g>
        <g id={pointer} transform="translate(0 -6)">
          <circle
            id="Oval_Copy_8"
            data-name="Oval Copy 8"
            cx="6.5"
            cy="6.5"
            r="6.5"
            transform="translate(0 0.453)"
            fill="#01b5de"
            opacity="0.2"
          />
          <circle
            id="Oval_Copy_9"
            data-name="Oval Copy 9"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(4 4.452)"
            fill="#01b5de"
          />
        </g>
      </g>
      <animateMotion xlinkHref={`#${pointer}`} dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
        <mpath xlinkHref={`#${motionPath}`} />
      </animateMotion>
    </svg>
  )
}

export default energySolarToHomeLmab
