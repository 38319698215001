import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'
import {TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'

const initialState = {
  loading: false,
  requestType: null,
  isDbtgInfoChanged: false,
  toggleStatus: TOGGLE_SWITCH_STATUS.OFF,
  scheduleSupported: false,
  startTime: {formatted12: '04:00 pm'},
  endTime: {formatted12: '07:00 pm'},
  showTimeKeeper: false,
  isDisclaimerSelected: false,
}

export const dbtgSlice = createSlice({
  name: 'dischargeBatteryToGrid',
  initialState,
  reducers: {
    setDbtgValue: (state, action) => {
      const {payload} = action
      const states = {...state}

      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setDbtgValue} = dbtgSlice.actions
export default dbtgSlice.reducer
