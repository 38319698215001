/* eslint-disable fp/no-mutation */
import {useEffect} from 'react'
import {SCREEN_TYPE} from 'lib/utils/constants'
import {getQueryParam} from '../utils/utility'

function getUiType() {
  return `${getQueryParam('uiType')}-view`
}

function getSourceType() {
  const source = getQueryParam('source')
  const uiType = getQueryParam('uiType')
  const elu = getQueryParam('elu')

  let text = 'source'
  if (source) {
    text += `-${source}`
  }

  if (elu && uiType === SCREEN_TYPE.MOBILE) {
    text += '-mobile'
  } else if (uiType === SCREEN_TYPE.WEB) {
    text += '-web'
  }

  return text
}

export function useAddUiClasses(value) {
  useEffect(() => {
    document.body.classList.add(getUiType())
    document.body.classList.add(getSourceType())

    if (value) {
      document.body.classList.add(value)
    }

    return () => {}
  })
}
