import React from 'react'

const BatteryShutDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Batery" transform="translate(1.6 6.4)">
      <rect
        id="Rectangle_5236"
        data-name="Rectangle 5236"
        width="24"
        height="24"
        transform="translate(-1.6 -6.4)"
        fill="#f37320"
        opacity="0"
      />
      <g className="icon-dark-fill-white" data-name="Group 17322" transform="translate(-60.991 -224.269)">
        <path
          id="Rectangle_6874"
          data-name="Rectangle 6874"
          d="M.455-.75H9.077a1.206,1.206,0,0,1,1.2,1.2V16.567a1.206,1.206,0,0,1-1.2,1.2H.455a1.206,1.206,0,0,1-1.2-1.2V.455A1.206,1.206,0,0,1,.455-.75ZM8.836.7H.7v15.63h8.14Z"
          transform="translate(66.626 222.098)"
          fill="#545456"
        />
        <path
          id="Path_20140"
          data-name="Path 20140"
          d="M18.485,1.155H13.5V.36c0-.2.236-.36.528-.36h3.929c.292,0,.528.161.528.36Z"
          transform="translate(55.399 219.869)"
          fill="#545456"
        />
        <g id="Group_17319" data-name="Group 17319" transform="translate(68.664 229.549)">
          <path
            id="Path_48900"
            data-name="Path 48900"
            d="M942.172,22549.645h-5.454v-1.926h5.454Z"
            transform="translate(-936.718 -22541.629)"
            fill="#545456"
          />
        </g>
        <path
          id="Path_48911"
          data-name="Path 48911"
          d="M5.221,2.155a2.715,2.715,0,1,1-4.015,0"
          transform="translate(68.177 224.994)"
          fill="none"
          stroke="#545456"
          strokeLinecap="round"
          strokeWidth="1.4"
        />
        <line
          id="Line_24281"
          data-name="Line 24281"
          y2="3"
          transform="translate(71.392 225.494)"
          fill="none"
          stroke="#545456"
          strokeLinecap="round"
          strokeWidth="1.4"
        />
      </g>
    </g>
  </svg>
)

BatteryShutDown.propTypes = {}

BatteryShutDown.defaultProps = {}

export default BatteryShutDown
