import React from 'react'

const FindRightProfileTutorial = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g transform="translate(0 52.001)">
      <rect fill="none" width="32" height="32" transform="translate(0 -52.001)" />
      <path
        fill="#01b5de"
        d="M18069.342,18370.5l-8.381-.736a1.721,1.721,0,0,1-1.59-1.746v-5.541a.42.42,0,0,0-.15-.268,12.193,12.193,0,0,1-4.217-9.059,10.641,10.641,0,0,1,21.24-.941,1.315,1.315,0,0,0,.119.35l2.447,3.752a1.92,1.92,0,0,1,0,2.064,1.97,1.97,0,0,1-1.738,1.031h-.828v3.332a2.559,2.559,0,0,1-2.535,2.541h-2.479v3.449a1.768,1.768,0,0,1-1.742,1.771Zm-12.512-17.375a10.419,10.419,0,0,0,3.6,7.67,2.242,2.242,0,0,1,.766,1.648v5.574l8.291.744-.055-3.9a1.379,1.379,0,0,1,1.381-1.385h2.924a.733.733,0,0,0,.736-.74v-3.922a1.248,1.248,0,0,1,1.24-1.24h1.414a.108.108,0,0,0,.121-.117.217.217,0,0,0,0-.178l-2.482-3.773a2.768,2.768,0,0,1-.35-1.152,8.736,8.736,0,0,0-8.766-8.057A8.827,8.827,0,0,0,18056.83,18353.129Zm10.891,7.135v-1.295h-1.148v1.295a.629.629,0,0,1-.625.623.618.618,0,0,1-.615-.623v-1.295h-1.152v1.295a.607.607,0,0,1-.623.623.626.626,0,0,1-.621-.623v-1.295a1.808,1.808,0,0,1-1.8-1.8h-1.3a.616.616,0,1,1,0-1.232h1.3v-1.127h-1.3a.619.619,0,0,1,0-1.238h1.3v-1.148h-1.3a.607.607,0,0,1-.617-.621.621.621,0,0,1,.617-.619h1.3a1.807,1.807,0,0,1,1.8-1.8v-1.3a.609.609,0,0,1,.621-.619.626.626,0,0,1,.623.619v1.3h1.152v-1.3a.622.622,0,0,1,.615-.619.631.631,0,0,1,.625.619v1.3h1.148v-1.3a.619.619,0,0,1,1.238,0v1.3a1.805,1.805,0,0,1,1.8,1.8h1.3a.62.62,0,0,1,0,1.24h-1.3v1.148h1.3a.6.6,0,0,1,.615.59.622.622,0,0,1-.615.619h-1.3v1.156h1.3a.616.616,0,0,1,0,1.232h-1.3a1.8,1.8,0,0,1-1.8,1.8v1.295a.619.619,0,1,1-1.238,0Zm-5.283-9.086v5.986a.561.561,0,0,0,.561.564h5.99a.543.543,0,0,0,.529-.59v-5.961a.559.559,0,0,0-.559-.561H18063A.56.56,0,0,0,18062.438,18351.178Zm1.771,5.43a.622.622,0,0,1-.619-.621v-3.6a.625.625,0,0,1,.619-.621h3.568a.649.649,0,0,1,.646.621v3.6a.623.623,0,0,1-.617.621Zm.586-1.236h2.363v-2.363h-2.363Z"
        transform="translate(-18051.004 -18392.502)"
      />
    </g>
  </svg>
)

export default FindRightProfileTutorial
