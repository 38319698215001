import React from 'react'
import PropTypes from 'prop-types'

export default class EnergyGenToHomeWoEncharge extends React.PureComponent {
  render() {
    const {nightMode} = this.props
    const color = nightMode ? '#dca2ff' : '#9062AC'
    return (
      <svg
        id="Battery_to_consume_"
        data-name="Battery to consume "
        xmlns="http://www.w3.org/2000/svg"
        width="92"
        height="92.002"
        viewBox="0 0 92 92.002">
        <g id="Battery-to-consume">
          <rect id="container" width="92" height="92" fill="rgba(255,83,83,0.11)" opacity="0" />
          <g id="pointer-generator-home" transform="translate(0 -3)">
            <circle id="Oval_Copy_8" cx="6" cy="6" r="6" fill={color} opacity="0.2" />
            <circle id="Oval_Copy_9" cx="2" cy="2" r="2" transform="translate(4 4)" fill={color} />
          </g>
          <g id="B_C" transform="translate(6 2.002)">
            <path
              id="motion-path-generator-home"
              d="M0,90V14A10,10,0,0,1,10,4H83"
              fill="none"
              stroke={color}
              strokeWidth="1"
              fillRule="evenodd"
            />
            <path
              id="Arrow_head"
              d="M86,3.967a.4.4,0,0,1-.089.25L82.482,7.908a.258.258,0,0,1-.313.063.34.34,0,0,1-.169-.3V.329a.323.323,0,0,1,.17-.3.27.27,0,0,1,.312.05l3.429,3.648A.368.368,0,0,1,86,3.967Z"
              fill={color}
            />
          </g>
        </g>
        <animateMotion xlinkHref="#pointer-generator-home" dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
          <mpath xlinkHref="#motion-path-generator-home" />
        </animateMotion>
      </svg>
    )
  }
}

EnergyGenToHomeWoEncharge.propTypes = {nightMode: PropTypes.bool}

EnergyGenToHomeWoEncharge.defaultProps = {nightMode: false}
