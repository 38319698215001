import React from 'react'
import PropTypes from 'prop-types'

const LoaderV1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.0"
    width="20px"
    height="20px"
    viewBox="0 0 128 128"
    xmlSpace="preserve">
    <rect x="0" y="0" width="100%" height="100%" fill="none" />
    <g>
      <path d="M59.6 0h8v40h-8V0z" fill="#01b4de" fillOpacity="1" />
      <path d="M59.6 0h8v40h-8V0z" fill="#ccf0f8" fillOpacity="0.2" transform="rotate(30 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#ccf0f8" fillOpacity="0.2" transform="rotate(60 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#ccf0f8" fillOpacity="0.2" transform="rotate(90 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#ccf0f8" fillOpacity="0.2" transform="rotate(120 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#b2e8f5" fillOpacity="0.3" transform="rotate(150 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#99e1f2" fillOpacity="0.4" transform="rotate(180 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#80d9ee" fillOpacity="0.5" transform="rotate(210 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#67d2eb" fillOpacity="0.6" transform="rotate(240 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#4dcae8" fillOpacity="0.7" transform="rotate(270 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#34c3e5" fillOpacity="0.8" transform="rotate(300 64 64)" />
      <path d="M59.6 0h8v40h-8V0z" fill="#1abbe1" fillOpacity="0.9" transform="rotate(330 64 64)" />
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
        calcMode="discrete"
        dur="1440ms"
        repeatCount="indefinite"
      />
    </g>
  </svg>
)

LoaderV1.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

LoaderV1.defaultProps = {
  width: 16,
  height: 16,
}

export default LoaderV1
