import React from 'react'
import {useTheme, useAddUiClasses} from 'lib/hook'
import './styles.scss'

const HomePage = () => (
  <div id="homepage">
    {useTheme()}
    {useAddUiClasses()}
    <div>
      Battery-Profile
      <div>Enphase Energy, Inc.</div>
    </div>
  </div>
)

HomePage.propTypes = {}

export default HomePage
