import React from 'react'

const ClockCircular = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22">
    <g id="clock" transform="translate(-0.365 -0.366)">
      <circle
        id="Ellipse_504"
        data-name="Ellipse 504"
        cx="10"
        cy="10"
        r="10"
        transform="translate(1.365 1.366)"
        fill="none"
        stroke="#6B7073"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_20082"
        data-name="Path 20082"
        d="M12,6v5.428l3.619,1.809"
        transform="translate(-0.697 -0.125)"
        fill="none"
        stroke="#6B7073"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

ClockCircular.propTypes = {}

ClockCircular.defaultProps = {}

export default ClockCircular
