import React from 'react'

const PowerBkp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
    <g transform="translate(-36 -311)">
      <rect width="63" height="63" rx="10" transform="translate(36 311)" className="background" />
      <g transform="translate(19016 21429)">
        <rect fill="#fff" width="44" height="44" transform="translate(-18970 -21108)" style={{opacity: 0}} />
        <path
          fill="#01b5de"
          d="M18741,20792.232a16.372,16.372,0,0,1,26.754-12.619,1.184,1.184,0,0,1-1.516,1.82,14.148,14.148,0,0,0-8.906-3.184,14,14,0,1,0,13.6,16.824,4.545,4.545,0,0,1-3.068-4.281v-1.82a.841.841,0,0,1,.834-.832h.832v-2.35a1.023,1.023,0,0,1,2.047,0v2.35h1.705v-2.35a1.023,1.023,0,1,1,2.047,0v2.35h.834a.841.841,0,0,1,.834.832v1.82a4.512,4.512,0,0,1-3.639,4.434,16.318,16.318,0,0,1-32.361-2.994Zm10.877,8.717a1.018,1.018,0,0,1-1.023-1.023v-14.7a1.018,1.018,0,0,1,1.023-1.023h2.766v-.832a1.017,1.017,0,0,1,1.023-1.023h3.3a.963.963,0,0,1,.984,1.023v.832h2.766a1.018,1.018,0,0,1,1.023,1.023v14.7a1.018,1.018,0,0,1-1.023,1.023Zm1.023-2.047h8.828v-12.7h-8.828Zm1.363-1.326v-2.465h6.139v2.465Zm0-3.791v-2.463h6.139v2.463Zm0-3.75v-2.463h6.139v2.463Z"
          transform="translate(-37707 -41877.898)"
        />
      </g>
    </g>
  </svg>
)
export default PowerBkp
