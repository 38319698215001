import React from 'react'
import {Helmet} from 'react-helmet'

export function useDescription(value) {
  return (
    <Helmet>
      <meta name="description" content={value} />
    </Helmet>
  )
}
