import React from 'react'
import {Provider} from 'react-redux'
import {BatteryDetails} from 'lib/component/common/organisms/BatteryDetails'
import {useTheme, useAddUiClasses} from 'lib/hook'
import store from '../../../store'
import '../../../locale/i18n'
import './styles.scss'

const Battery = () => (
  // Battery
  <Provider store={store}>
    <div id="enho-battery">
      {useTheme()}
      {useAddUiClasses()}

      <BatteryDetails />
    </div>
  </Provider>
)

Battery.propTypes = {}

Battery.defaultProps = {}

export default Battery
