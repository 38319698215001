/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import {DIALOG_TYPE, PAGE_NAME} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {getGridInterconnectionDetails} from 'lib/utils/siteDetails'
import {contactSupport, popupFromIframe} from 'lib/common'
import './styles.scss'

function getGicDescription({checkHandler, redirectFrom}) {
  const gridInterconnectionDetails = getGridInterconnectionDetails()

  const redirectToConcatUs = () => {
    popupFromIframe({displayFooter: false, dialogType: DIALOG_TYPE.CENTER})
    contactSupport({redirectFrom})
  }

  return (
    <div className="e_dialog__body__wrapper">
      {gridInterconnectionDetails.capacity && (
        <>
          <p className="gic__title">{i18next.t('bp422')}</p>
          <p className="gic__value">
            <span className="value">{gridInterconnectionDetails.capacity}</span>
            <span>{i18next.t('bp364')}</span>
          </p>
        </>
      )}
      <p className="gic__description">{i18next.t('bp363')}</p>
      <div className="gic__contact__installer">
        <p className="gic__contact-installer__info">
          <Icon src="infoAlert" />
          <span>
            <span className="text-link" tabIndex="0" role="button" onClick={redirectToConcatUs} onKeyDown={null}>
              {i18next.t('bp366')}
            </span>
            <span>{redirectFrom === PAGE_NAME.PROFILE ? i18next.t('bp369') : i18next.t('bp367')}</span>
          </span>
        </p>
      </div>
      <div className="e_dialog__content bp__radio-checkbox__button">
        <input type="checkbox" id="itc-checkbox" onChange={e => checkHandler(e.target.checked)} />
        <div className="bp__radio-checkbox__description">
          <label htmlFor="itc-checkbox">{i18next.t('bp365')}</label>
        </div>
      </div>
    </div>
  )
}

export const getGicDisclaimerDetails = ({closeGicDisclaimer, confirmGicDisclaimer, redirectFrom}) => {
  let isDoNotShowMeChecked = false
  const checkHandler = value => {
    isDoNotShowMeChecked = value
  }

  const getGicPopupDescription = getGicDescription({checkHandler, redirectFrom})
  const obj = {
    className: 'bp__gic__profile-popup',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: i18next.t('bp362'),
    content: getGicPopupDescription,
    onClickCloseIcon: closeGicDisclaimer,
  }

  obj.buttons = [
    {
      value: i18next.t('bp99'),
      action: closeGicDisclaimer,
      disabled: false,
      className: 'bp__ab--mobile-view',
    },
    {
      value: i18next.t('bp368'),
      action: () => {
        confirmGicDisclaimer(isDoNotShowMeChecked)
      },
      disabled: false,
      className: 'bp__ab--mobile-view',
    },
  ]

  return obj
}
