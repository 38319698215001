/* eslint-disable fp/no-mutation */

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import $ from 'jquery'
import {OS_TYPE} from 'lib/utils/constants'
import {isOSType, openURL} from 'lib/utils/utility'
import './styles.scss'

/*
  --- --- --- --- --- ---
  How to use <YtVideoPlayer />
  --- --- --- --- --- ---
  <YtVideoPlayer
    className="String"
    videoId="String"
    videoContainerId="String"
    bannerId="String"
    onCloseCallback=Function
    openAsExternalURL=Boolean
  />
*/

export class YtVideoPlayer extends React.Component {
  constructor() {
    super()

    this.videoObj = {}
    this.player = null

    this.state = {
      onlineMode: true,
    }

    this.onFullScreenExitCloseVideo = this.onFullScreenExitCloseVideo.bind(this)
  }

  componentDidMount() {
    const {videoId, videoContainerId, bannerId, allowFullScreen, openInFullScreenMode} = this.props

    this.playVideo(videoId, videoContainerId, bannerId, {allowFullScreen, openInFullScreenMode})

    // if (onCloseMinimiseView) {
    //   this.onFullScreenExitCloseVideo()
    // }
  }

  onFullScreenExitCloseVideo() {
    const fsChangeKeys = this.getFullScreenChangeEventNames()

    fsChangeKeys.forEach(key => {
      document.addEventListener(
        key,
        () => {
          if (!document.fullscreenElement) {
            this.exitFullScreenCallback()
          }
        },
        false
      )
    })
  }

  getFullScreenChangeEventNames() {
    const fsChangeEvents = [
      'fullscreenchange',
      'msfullscreenchange',
      'mozfullscreenchange',
      'webkitfullscreenchange',
      'onwebkitfullscreenchange',
    ]
    return fsChangeEvents
  }

  getFullScreenElement(ele) {
    const fsChangeEvents = [
      'fullscreenElement',
      'msFullscreenElement',
      'mozFullScreen',
      'webkitIsFullScreen',
      'webkitCurrentFullScreenElement',
      'webkitDisplayingFullscreen',
    ]
    let check = false

    _.forEach(fsChangeEvents, key => {
      if (ele[key]) {
        check = ele[key]
      }
    })

    return check
    // return ele.fullscreenElement || ele.msFullscreenElement || ele.mozFullScreen || ele.webkitIsFullScreen
  }

  exitFullScreenCallback() {
    const {onCloseCallback} = this.props

    this.player && this.player.stopVideo && this.player.stopVideo()
    onCloseCallback && onCloseCallback()
  }

  loadScript(id, url) {
    return new Promise((resolve, reject) => {
      const existingScript = document.getElementById(id)
      if (!existingScript) {
        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.id = id
        document.body.appendChild(script)
        script.onload = () => {
          resolve()
        }
        script.onerror = () => {
          reject()
        }
      }
      if (existingScript) resolve()
    })
  }

  injectYoutubePlayerApi() {
    return this.loadScript('player-api', 'https://www.youtube.com/player_api').then(
      () =>
        new Promise(resolve => {
          window.YT.ready(resolve)
        })
    )
  }

  resetVideoContainer(videoContainerId, bannerId) {
    if (!_.isNull($(`#${videoContainerId}`))) $(`#${videoContainerId}`).remove()
    if (!_.isNull(document.querySelector(`#${bannerId}`)))
      document.querySelector(`#${bannerId}`).style.display = 'block'
    if (!_.isNull($(`#${bannerId}`))) $(`#${bannerId}`).after(`<div id="${videoContainerId}"></div>`)
  }

  playYtVideo({videoId, videoContainerId, bannerId, obj}, onCloseCallback) {
    const {id, onCloseMinimiseView, showLoader} = this.props

    const enterFullScreen = (event, ele) => {
      const isFullScreenEnabled =
        document.fullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled

      function showMinimizedView() {
        const dialogEle = document.getElementById(id)
        dialogEle?.firstChild?.classList?.remove('bp__video-dialog--hidden')
      }

      // Ref -
      // https://developer.apple.com/forums/thread/133248
      // https://caniuse.com/?search=requestFullscreen#google_vignette
      if (isOSType(OS_TYPE.IOS) && ele.allowFullscreen) {
        console.log(`Element - iPhone: ${videoContainerId} entered fullscreen mode.`)

        const iframe = document.getElementById(videoContainerId)
        const requestFullScreen =
          iframe.requestFullScreen || iframe.webkitRequestFullScreen || iframe.webkitRequestFullscreen
        if (requestFullScreen) {
          requestFullScreen.bind(iframe)()
        } else {
          showMinimizedView()
        }
      } else if (obj.openInFullScreenMode && isFullScreenEnabled) {
        const iframe = document.getElementById(videoContainerId)
        const requestFullScreen =
          iframe.requestFullscreen ||
          iframe.mozRequestFullScreen ||
          iframe.msRequestFullscreen ||
          iframe.webkitRequestFullscreen ||
          iframe.webkitRequestFullScreen ||
          iframe.webkitEnterFullscreen ||
          document.webkitEnterFullscreen

        if (requestFullScreen) {
          try {
            requestFullScreen
              .bind(iframe)()
              .catch(e => {
                console.error('[playYtVideo requestFullScreen catch]', e)
                showMinimizedView()
              })
          } catch (e) {
            console.error('[playYtVideo catch]', e)
            showMinimizedView()
          }
        } else {
          showMinimizedView()
        }

        // event.target.g.requestFullscreen()
        // ele.requestFullscreen()
      } else {
        showMinimizedView()
      }
    }

    const exitFullScreen = () => {
      this.exitFullScreenCallback()

      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    }

    const toggleFullScreen = (event, ele) => {
      const fullscreenElement = this.getFullScreenElement(ele) || this.getFullScreenElement(document)

      if (!fullscreenElement) {
        enterFullScreen(event, ele)
      } else if (ele.exitFullscreen) {
        exitFullScreen()
      }
    }

    const fullScreenChanged = event => {
      const fullscreenElement = this.getFullScreenElement(document)

      if (fullscreenElement) {
        console.log(`Element: ${fullscreenElement?.id} entered fullscreen mode.`)

        if (event && event.target && event.target.style) {
          event.target.style.visibility = 'visible'
        }
      } else {
        const dialogEle = document.getElementById(id)
        onCloseMinimiseView
          ? dialogEle?.firstChild?.classList?.remove('bp__video-dialog--hidden')
          : this.exitFullScreenCallback()
      }
    }

    const player = new window.YT.Player(videoContainerId, {
      videoId,
      playerVars: {
        rel: 0,
        modestbranding: 1,
        autohide: 1,
        showinfo: 0,
        autoplay: 0,
        enablejsapi: 1,
        playsinline: 0,
        fs: 1,
      },
      events: {
        onReady: event => {
          $(`#${videoContainerId}`).attr({
            donotallowfullscreen: '0',
            allowfullscreen: obj.allowFullScreen ? '1' : '0',
            webkitallowfullscreen: obj.allowFullScreen ? 'true' : 'false',
            mozallowfullscreen: obj.allowFullScreen ? 'true' : 'false',
          })

          // Register events
          const fsChangeEvents = this.getFullScreenChangeEventNames()

          fsChangeEvents.forEach(key => {
            document.addEventListener(key, fullScreenChanged, false)
          })

          // document.addEventListener('fullscreenchange', fullScreenChanged, false)

          event.target.playVideo()

          if (obj.openInFullScreenMode) {
            const ele = document.getElementById(videoContainerId)
            toggleFullScreen(event, ele)
          }

          showLoader({show: false})
        },
        onStateChange: event => {
          showLoader({show: false})
          if (event.data === window.YT.PlayerState.PLAYING) {
            if (document.querySelector(`#${bannerId}`)?.style) {
              document.querySelector(`#${bannerId}`).style.display = 'none'
            }
          } else if (event.data === window.YT.PlayerState.ENDED || event.data === window.YT.PlayerState.CUED) {
            this.resetVideoContainer(videoContainerId, bannerId)
            onCloseCallback()
          }
        },
        onError: error => {
          console.error('[Error] playYtVideo', error)

          showLoader({show: false})
          onCloseCallback()
        },
      },
    })
    return player
  }

  playVideo(videoId, videoContainerId, bannerId, obj) {
    const {openAsExternalURL, onCloseCallback, showLoader} = this.props
    const {onlineMode} = this.state

    if (onlineMode) {
      if (openAsExternalURL) {
        openURL(`https://www.youtube.com/watch?v=${videoId}`)
        showLoader({show: false})
        onCloseCallback()
      } else {
        this.injectYoutubePlayerApi()
          .then(() => {
            this.player && this.player.stopVideo()
            !_.isEmpty(this.videoObj) &&
              this.resetVideoContainer(this.videoObj.videoContainerId, this.videoObj.bannerId)
            this.player = this.playYtVideo({videoId, videoContainerId, bannerId, obj}, () => {
              onCloseCallback()
            })
            this.videoObj = {videoContainerId, bannerId}
          })
          .catch(error => {
            console.error('[Error] playVideo', error)
          })
      }
    }
  }

  render() {
    const {className, videoContainerId, bannerId} = this.props

    return (
      <div className={`yt-video ${className}`}>
        <div id={bannerId} />
        <div id={videoContainerId} />
      </div>
    )
  }
}

YtVideoPlayer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  videoId: PropTypes.string,
  videoContainerId: PropTypes.string,
  bannerId: PropTypes.string,
  allowFullScreen: PropTypes.bool,
  openAsExternalURL: PropTypes.bool,
  openInFullScreenMode: PropTypes.bool,
  onCloseMinimiseView: PropTypes.bool,
  showLoader: PropTypes.func,
  onCloseCallback: PropTypes.func,
}

YtVideoPlayer.defaultProps = {
  id: null,
  className: '',
  videoId: null,
  videoContainerId: null,
  bannerId: null,
  allowFullScreen: false,
  openAsExternalURL: false,
  openInFullScreenMode: false,
  onCloseMinimiseView: false,
  showLoader: () => {},
  onCloseCallback: () => {},
}
