import React from 'react'
import PropTypes from 'prop-types'

export default class EnergyGridCircle extends React.PureComponent {
  render() {
    const {nightMode} = this.props
    const color = nightMode ? '#cbced1' : '#6B7073'

    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_19_4)">
          <path
            d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
            fill="none"
          />
          <path
            d="M24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.042 24.293L31.975 37.787L24.642 10.8H21.708L14.375 37.787L28.308 24.293"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.321 21.36H13.201"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.802 16.666H15.549"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_19_4">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

EnergyGridCircle.propTypes = {nightMode: PropTypes.bool}

EnergyGridCircle.defaultProps = {nightMode: false}
