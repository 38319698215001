import React from 'react'
import {useSelector} from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {PAGE_NAME} from 'lib/utils/constants'
import StormGuardPage from '../StormGuardDetails/stormGuardPage'
import EvTopUpSchedulePage from '../EvTopUpSchedulePage'
import EditProfilePage from '../ProfileDetails/editProfilePage'
import './styles.scss'

/* *********************** *********************** *********************** *
 * SubPage
 * *********************** *********************** *********************** */

const SubPage = () => {
  const batteryProfile = useSelector(batteryProfileSelector)
  const showSubPage = !_.isNil(batteryProfile.subPage) && batteryProfile.subPage.length > 0
  const currentPage = _.last(batteryProfile.subPage)

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const getPage = () => {
    switch (currentPage) {
      case PAGE_NAME.EDIT_PROFILE:
        return <EditProfilePage id={currentPage} />
      case PAGE_NAME.STORM_GUARD:
        return <StormGuardPage id={currentPage} />
      case PAGE_NAME.EV_SCHEDULE:
        return <EvTopUpSchedulePage id={currentPage} />
      default:
        return null
    }
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    showSubPage && (
      <div id={`bp-page-${currentPage}`} className={classNames('bp__page', {'bp__page--open': showSubPage})}>
        {getPage()}
      </div>
    )
  )
}

SubPage.propTypes = {}

SubPage.defaultProps = {}

export default SubPage
