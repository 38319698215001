import React from 'react'

const energySolarCircle = () => (
  <svg id="solar" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g id="Oval" fill="#fff" stroke="#01b4de" strokeWidth="1.5">
      <circle cx="24" cy="24" r="24" stroke="none" />
      <circle cx="24" cy="24" r="23.25" fill="none" />
    </g>
    <g id="Produced" transform="translate(9.598 9.6)">
      <rect
        id="Rectangle_5234"
        data-name="Rectangle 5234"
        width="28.8"
        height="28.8"
        transform="translate(0 0)"
        fill="#f37320"
        opacity="0"
      />
      <path
        id="Path_12530"
        data-name="Path 12530"
        d="M15.526,21.766a6.24,6.24,0,1,0-6.24-6.24A6.24,6.24,0,0,0,15.526,21.766Z"
        transform="translate(-1.124 -1.126)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeWidth="1.5"
      />
      <path
        id="Path_12531"
        data-name="Path 12531"
        d="M15,3.572v2.5"
        transform="translate(-0.598 -1.651)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_12532"
        data-name="Path 12532"
        d="M15,24.143v2.5"
        transform="translate(-0.598 0.241)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_12533"
        data-name="Path 12533"
        d="M23.228,6.92l-1.76,1.76"
        transform="translate(-0.003 -1.343)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_12534"
        data-name="Path 12534"
        d="M8.68,21.469l-1.76,1.76"
        transform="translate(-1.341 -0.005)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_12535"
        data-name="Path 12535"
        d="M26.639,15h-2.5"
        transform="translate(0.243 -0.6)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_12536"
        data-name="Path 12536"
        d="M6.067,15h-2.5"
        transform="translate(-1.65 -0.6)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_12537"
        data-name="Path 12537"
        d="M23.228,23.228l-1.76-1.76"
        transform="translate(-0.003 -0.005)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_12538"
        data-name="Path 12538"
        d="M8.68,8.68,6.92,6.92"
        transform="translate(-1.341 -1.343)"
        fill="rgba(0,0,0,0)"
        stroke="#01b4de"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default energySolarCircle
