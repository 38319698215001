/* eslint-disable fp/no-mutation */
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18next from 'i18next'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {DIALOG_TYPE, PROFILE_STATUS} from 'lib/utils/constants'
import {isWeb} from 'lib/utils/utility'
import Icon from '../../../../utils/icon'
import {ToggleSwitch, toggleStatusInfo} from '../../atoms/ToggleSwitch'
import {setPdValue} from '../../organisms/ProfileDetails/pdSlice'
import {restrictUserToPerformAction} from '../../organisms/bpCommon'
import {isAnyRequestPendingForOperationModeSubType, isRequestPendingForProfile} from '../../organisms/reqPending'
import {getInfoDescription} from './eaphCore'
import './styles.scss'

/* *********************** *********************** *********************** *
 * Edit Use Battery After Peak Hours
 * *********************** *********************** *********************** */

const EditAfterPeakHours = props => {
  const {fromTutorial} = props
  const batteryProfile = useSelector(batteryProfileSelector)
  const profileDetails = useSelector(state => state.components.profileDetails)
  const {details} = batteryProfile
  const title = i18next.t('bp35')

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setPdSlice = obj => dispatch(setPdValue({...obj}))

  const pcDetails = {} // TODO
  const disabledClass =
    props.fromTutorial ||
    !_.isNull(profileDetails.inProgress) ||
    profileDetails.isAfterPeakLoading ||
    (isRequestPendingForProfile(details) && pcDetails.type === PROFILE_STATUS.ACTIVE)

  const tStatus = profileDetails.operationModeSubType
  const disabled = profileDetails.isAfterPeakLoading
  let tsLoading = profileDetails.isAfterPeakLoading || isAnyRequestPendingForOperationModeSubType(details)

  if (fromTutorial) {
    tsLoading = false
  }

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // After Peak :: Info Details
  const getAfterPeakInfoDetails = () => {
    const apInfoDescription = getInfoDescription()
    const obj = {
      className: 'sp__info-popup',
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title,
      content: apInfoDescription,
      buttons: null,
      onClickCloseIcon: closeThisDialog,
    }
    return obj
  }

  // After Peak :: onClick Info Icon
  const onClickAfterPeakInfo = () => {
    const obj = getAfterPeakInfoDetails()
    showDialog(obj)
  }

  // After Peak :: Handle Toggle Change
  const handleAfterPeakToggleChange = value => {
    if (
      fromTutorial ||
      restrictUserToPerformAction({
        details,
        inProgress: profileDetails.inProgress,
        checkUserAccess: false,
        showUserAccessToast: false,
        showDialog,
        closeThisDialog,
      })
    ) {
      return
    }

    // TODO
    // if (activeProfile !== PROFILE_TYPE.COST_SAVINGS) {
    //   showToast({
    //     type: TOAST_TYPE.INFO,
    //     message: isLoading
    //       ? 'You have an active request. Please wait for confirmation or cancel that request to proceed with the selection.'
    //       : 'Please activate Savings profile to use this feature',
    //   })

    //   return
    // }

    setPdSlice({operationModeSubType: value})
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    <div className="edit-after-peak-hours atom-design--t1">
      <div className="com__ad__title">
        <div>{title}</div>
        <div className="sp__icon" onClick={onClickAfterPeakInfo} onKeyDown={() => {}} role="button" tabIndex="0">
          <Icon src="info" />
        </div>
      </div>

      <div className="com__ad__value">
        {toggleStatusInfo({
          loading: tsLoading,
          status: tStatus,
        })}
      </div>

      <div className="com__ad__action">
        <ToggleSwitch
          key="sp-ei-toggle"
          id="sp-ei-toggle"
          className={disabledClass ? 'bp_ts_disabled' : ''}
          status={tStatus}
          disabled={disabled}
          handleChange={handleAfterPeakToggleChange}
        />
      </div>
    </div>
  )
}

EditAfterPeakHours.propTypes = {
  fromTutorial: PropTypes.bool,
}

EditAfterPeakHours.defaultProps = {
  fromTutorial: false,
}

export default EditAfterPeakHours
