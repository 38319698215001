/* eslint-disable fp/no-mutation */
import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'
import {popupFromIframe} from 'lib/common'
import {DIALOG_TYPE} from '../../utils/constants'

const initialState = {
  id: null,
  className: '',
  type: null,
  showCloseIcon: true,
  showDialog: false,
  title: 'Chandradeo Chakravarti',
  content: null,
  buttons: null,
  onClickCloseIcon: () => {},
}

export const dialogDetailsSlice = createSlice({
  name: 'dialogDetails',
  initialState,
  reducers: {
    closeDialog: state => {
      state.showDialog = false
      popupFromIframe({displayFooter: true})
    },
    setDialogValue: (state, action) => {
      const {payload} = action
      const states = {...state}

      if (payload.className === 'sph__choose-right-profile-popup')
        popupFromIframe({displayFooter: false, dialogType: DIALOG_TYPE.BOTTOM})
      else {
        payload.type === DIALOG_TYPE.BOTTOM && popupFromIframe({displayFooter: false, dialogType: DIALOG_TYPE.BOTTOM})
        payload.type === DIALOG_TYPE.CENTER && popupFromIframe({displayFooter: true, dialogType: DIALOG_TYPE.CENTER})
      }

      states.showCloseIcon = true
      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {closeDialog, setDialogValue} = dialogDetailsSlice.actions
export default dialogDetailsSlice.reducer
