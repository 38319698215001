/* eslint-disable fp/no-mutation */
import HTTP_REQUEST, {HTTPS_METHOD, HTTPS_API_STATUS} from './httpRequest'

/* *********************** *********************** *********************** *
 *  Storm Guard API
 * *********************** *********************** *********************** */

let data = null
let promise = null

function callAPI(type, pathName, params = {}) {
  promise = HTTP_REQUEST.makeRequest(type, pathName, params)
  return promise
}

async function setStormGuard(body) {
  try {
    data = await callAPI(HTTPS_METHOD.PUT, 'SET_STORM_GUARD', body)
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      // message: I18n.t('mobile.form.default.error'),
      error,
    }
    promise = null
    throw data
  }

  return data
}

async function setStormGuardAlert(body) {
  try {
    data = await callAPI(HTTPS_METHOD.POST, 'SET_STORM_GUARD_ALERT', body)
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      // message: I18n.t('mobile.form.default.error'),
      error,
    }
    promise = null
    throw data
  }

  return data
}

async function getStormAlert() {
  try {
    data = await callAPI(HTTPS_METHOD.GET, 'STORM_ALERT')
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      // message: I18n.t('mobile.form.default.error'),
      error,
    }
    promise = null
    throw data
  }

  return data
}

async function setStormAlert(body) {
  try {
    data = await callAPI(HTTPS_METHOD.PUT, 'STORM_ALERT', body)
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      // message: I18n.t('mobile.form.default.error'),
      error,
    }
    promise = null
    throw data
  }

  return data
}

export {setStormGuard, setStormGuardAlert, getStormAlert, setStormAlert}
