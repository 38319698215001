/* eslint-disable fp/no-mutation */
import React from 'react'
import PropTypes from 'prop-types'
import ReactSlider from 'react-slider'
import classNames from 'classnames'
import './styles.scss'

/*
  Ref: https://zillow.github.io/react-slider/
  https://codesandbox.io/s/zillowreact-slider-l3vuc?file=/src/styles.css
*/

const CONSTANT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

export const RangeSlider = props => {
  const {
    className,
    disabled,
    isDisabled,
    marks,
    min,
    max,
    rangeMin,
    rangeMax,
    minDistance,
    showTrackValue,
    ticks,
    rangeText,
    onChange,
    onClick,
    onAfterChange,
  } = props

  const defaultValue = props.value || 0
  const setDefaultValue = (val, index, cb) => cb(val, index)

  function isUnderMinMaxRange(val, index, cb) {
    const trackValue = val[index]
    const currValue = [...val]

    if ((rangeMin && trackValue < rangeMin) || (rangeMax && trackValue > rangeMax)) {
      if (trackValue < rangeMin) {
        currValue[index] = rangeMin
      } else if (trackValue > rangeMax) {
        currValue[index] = rangeMax
      }

      setDefaultValue(currValue, index, cb)
      return false
    }
    return true
  }

  function onChangeSlider(val, index, cb) {
    if (isUnderMinMaxRange(val, index, cb)) {
      setDefaultValue(val, index, cb)
    }
  }

  function renderThumb(prop, state, obj) {
    const {valueNow} = state

    return <div {...prop}>{obj.showTrackValue ? valueNow : null}</div>
  }

  function renderMark(prop, obj) {
    const {key} = prop
    const dotValue = obj.ticks && !obj.ticks[key]
    const tickValue = obj.ticks && obj.ticks[key]
    const val = dotValue ? '.' : tickValue || key
    const classes = classNames(prop.className, {
      'rs__mark-value__dot': dotValue,
      'rs__mark-min': key === obj.rangeMin,
      'rs__mark-max': key === obj.rangeMax,
    })

    return (
      <span {...prop} className={classes}>
        <span className="rs__mark-value">{val}</span>
      </span>
    )
  }

  // eslint-disable-next-line no-unused-vars
  function renderTrack(prop, state, obj) {
    if (prop.key === 'rs__track-1') {
      prop.style.left += 12
    }

    return <div {...prop} />
  }

  return (
    <div className="bp__range-slider">
      <ReactSlider
        {...props}
        className={classNames(
          'rs__wrapper',
          {'rs__with-ticks': ticks || marks},
          className,
          `rs__with-${props.value.length}-thumb`,
          {disabled: isDisabled}
        )}
        min={min}
        max={max}
        defaultValue={defaultValue}
        value={defaultValue}
        disabled={disabled}
        snapDragDisabled
        markClassName="rs__mark"
        marks={marks}
        minDistance={minDistance}
        thumbClassName="rs__thumb"
        trackClassName="rs__track"
        renderThumb={(prop, state) => renderThumb(prop, state, {showTrackValue})}
        renderMark={(...obj) => renderMark(...obj, {ticks, rangeMin, rangeMax})}
        renderTrack={(...obj) => renderTrack(...obj, {rangeMin, marks})}
        onChange={(...obj) => onChangeSlider(...obj, onChange)}
        onSliderClick={() => onClick()}
        onAfterChange={(...obj) => onAfterChange(...obj, onAfterChange)}
      />

      {rangeText && <div className="rs__text">{rangeText}</div>}
    </div>
  )
}

RangeSlider.propTypes = {
  className: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  marks: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.bool, PropTypes.number]),
  ticks: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.number), PropTypes.objectOf(PropTypes.string)]),
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  rangeText: PropTypes.node,
  minDistance: PropTypes.number,
  orientation: PropTypes.oneOf([CONSTANT.HORIZONTAL, CONSTANT.VERTICAL]),
  showTrackValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onAfterChange: PropTypes.func,
}

RangeSlider.defaultProps = {
  disabled: false,
  isDisabled: false,
  value: null,
  marks: true,
  ticks: null,
  step: 1,
  rangeMin: null,
  rangeMax: null,
  rangeText: null,
  minDistance: 0,
  orientation: CONSTANT.HORIZONTAL,
  showTrackValue: false,
  onClick: null,
  onAfterChange: null,
}
