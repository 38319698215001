import React from 'react'

const infoAlert = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <g id="Group_18466" data-name="Group 18466" transform="translate(-117 -30.5)">
      <path
        id="Path_3976"
        data-name="Path 3976"
        d="M212.6,117.6a25,25,0,1,0,17.678,7.322A25,25,0,0,0,212.6,117.6Zm0,47.413a22.415,22.415,0,1,1,15.848-6.565A22.409,22.409,0,0,1,212.6,165.013Z"
        transform="translate(-70.598 -87.1)"
        fill="#6f7070"
      />
      <path
        id="Path_3977"
        data-name="Path 3977"
        d="M334.078,252l-.88.923v15.739l.88.923h3.517l.88-.923V252.923l-.88-.923Z"
        transform="translate(-193.836 -201.029)"
        fill="#6f7070"
        stroke="#fff"
        strokeWidth="1"
      />
      <path
        id="Path_3978"
        data-name="Path 3978"
        d="M334.078,196l-.88.88V200.4l.88.88h3.517l.88-.88V196.88l-.88-.88Z"
        transform="translate(-193.836 -153.459)"
        fill="#6f7070"
        stroke="#fff"
        strokeWidth="1"
      />
    </g>
  </svg>
)

infoAlert.propTypes = {}

infoAlert.defaultProps = {}

export default infoAlert
