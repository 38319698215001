/* eslint-disable fp/no-mutation */
import HTTP_REQUEST, {HTTPS_METHOD, HTTPS_API_STATUS} from './httpRequest'

/* *********************** *********************** *********************** *
 *  No Export Credit API
 * *********************** *********************** *********************** */

let data = null
let promise = null

function callAPI(type, pathName, params = {}) {
  promise = HTTP_REQUEST.makeRequest(type, pathName, params)
  return promise
}

async function setNoExportCredit() {
  try {
    data = await callAPI(HTTPS_METHOD.POST, 'SET_NO_EXPORT_CREDIT', {noExportCredit: true})
    if (typeof data === 'string') {
      data = {
        message: data,
      }
    }
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      // message: I18n.t('mobile.form.default.error'),
      error,
    }
    promise = null
    throw data
  }

  return data
}

export {setNoExportCredit}
