import React from 'react'

const ChooseRightProfileTutorial = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32.102" height="32.102" viewBox="0 0 32.102 32.102">
    <g transform="translate(0.064)">
      <rect width="32.102" height="32.102" transform="translate(-0.064)" fill="none" />
      <g transform="translate(-357.083 -365.917)">
        <path
          d="M387.212,373.072h0a.562.562,0,0,0-.561.561h0v14.52a2.506,2.506,0,0,1-2.506,2.507c-.055,0-.109,0-.164,0a18.281,18.281,0,0,0-9.144,1.813.561.561,0,0,0,.475,1.017h0a17.22,17.22,0,0,1,8.594-1.71,3.63,3.63,0,0,0,3.859-3.384c.006-.079.008-.158.008-.238v-14.52A.561.561,0,0,0,387.212,373.072Z"
          transform="translate(-0.753 -0.105)"
          fill="#01b5de"
        />
        <path
          d="M371.958,392.47h0a18.274,18.274,0,0,0-9.147-1.813,2.508,2.508,0,0,1-2.663-2.34c0-.053,0-.108,0-.162v-14.52a.561.561,0,0,0-1.123,0v14.52a3.63,3.63,0,0,0,3.63,3.63c.079,0,.157,0,.236-.008a17.178,17.178,0,0,1,8.6,1.71.561.561,0,1,0,.478-1.015Z"
          transform="translate(0 -0.105)"
          fill="#01b5de"
        />
        <path
          d="M383.511,388.609a.561.561,0,0,0,.6-.523l0-.036V371.483a.562.562,0,0,0-.561-.561h0a25.566,25.566,0,0,0-10.37,1.992,25.566,25.566,0,0,0-10.37-1.992h0a.561.561,0,0,0-.561.561h0V388.05a.561.561,0,0,0,.561.561l.036,0a20.463,20.463,0,0,1,10.1,1.993.555.555,0,0,0,.476,0A20.487,20.487,0,0,1,383.511,388.609Zm-10.334.867a21.727,21.727,0,0,0-9.059-2.03c-.25,0-.5,0-.75.013V372.05a23.978,23.978,0,0,1,9.579,1.99.565.565,0,0,0,.461,0,23.974,23.974,0,0,1,9.579-1.99v15.409A21.671,21.671,0,0,0,373.177,389.476Z"
          transform="translate(-0.157)"
          fill="#01b5de"
        />
        <rect width="5.68" height="3.992" transform="translate(368.78 378.788)" fill="#01b5de" />
        <path
          d="M378.655,378.591h-.5v-.5a1,1,0,0,0-1-1h-8.977a1,1,0,0,0-1,1v5.988a1,1,0,0,0,1,1l8.977,0a1,1,0,0,0,1-1v-.5h.5a1,1,0,0,0,1-1v-2.994A1,1,0,0,0,378.655,378.591Zm0,3.992h-1.5v1.5h-8.977v-5.99h8.978v1.5h1.5Z"
          transform="translate(-0.397 -0.3)"
          fill="#01b5de"
        />
      </g>
    </g>
  </svg>
)

export default ChooseRightProfileTutorial
