/* eslint-disable fp/no-mutation */
import {assign} from 'lodash'
import appDomains from './appDomains'
import envDetails from './envDetails'
import {getSiteId} from './siteDetails'
import {getUserId} from './userDetails'
import {getDeviceUId, getLocale, getQueryParam, getSerialNumber} from './utility'

const e = require('e_modules')

const BASE_URL = '/service/batteryConfig/api/v1'
const HEMS_BASE_URL = '/api/v1/hems'

// URLs
const productionUrls = {
  TOPIC_DETAILS: `${BASE_URL}/mqttSignedUrl/@SITE_ID`,
  SITE_SETTINGS: `${BASE_URL}/siteSettings/@SITE_ID?@USER_ID`,
  BATTERY_CONFIG: `${BASE_URL}/batterySettings/@SITE_ID?@SOURCE&@USER_ID`,
  SET_BATTERY_EV_CONFIG: `${BASE_URL}/device/battery/preference/@SITE_ID`,
  PROFILE_CONFIG: `${BASE_URL}/profile/@SITE_ID?@SOURCE&@USER_ID`,
  SET_BATTERY_CONFIG: `${BASE_URL}/batterySettings/@SITE_ID?@USER_ID`,
  SET_PROFILE_CONFIG: `${BASE_URL}/profile/@SITE_ID?@USER_ID`,
  SET_STORM_GUARD: `${BASE_URL}/stormGuard/toggle/@SITE_ID?@USER_ID`,
  SET_STORM_GUARD_ALERT: `${BASE_URL}/stormGuard/userAlerted/@SITE_ID`,
  STORM_ALERT: `${BASE_URL}/stormGuard/@SITE_ID/stormAlert`,
  SET_NO_EXPORT_CREDIT: `${BASE_URL}/tariff/setZeroExport/@SITE_ID`,
  CANCEL_PROFILE_REQUEST: `${BASE_URL}/cancel/profile/@SITE_ID?@USER_ID`,
  CANCEL_BATTERY_REQUEST: `${BASE_URL}/cancel/battery/@SITE_ID?@USER_ID`,
  SET_DISCLAIMER: `${BASE_URL}/batterySettings/acceptDisclaimer/@SITE_ID`,
  HEMS_ACTIVATION_STATUS: `${HEMS_BASE_URL}/@SITE_ID/activation-status?refresh-subscription=false`,
}

const blueUrls = {}

const preprodUrls = {}

const releaseUrls = {}

const qaBlueUrls = {}

const qaUrls = {}

const qa4Urls = {}

const developmentUrls = {}

const APP_URL = {
  [e.envDetails.constants.PRODUCTION]: productionUrls,
  [e.envDetails.constants.BLUE]: blueUrls,
  [e.envDetails.constants.PREPROD]: preprodUrls,
  [e.envDetails.constants.RELEASE]: releaseUrls,
  [e.envDetails.constants.QA_BLUE]: qaBlueUrls,
  [e.envDetails.constants.QA]: qaUrls,
  [e.envDetails.constants.QA4]: qa4Urls,
  [e.envDetails.constants.DEVELOPMENT]: developmentUrls,
}

function getUrlString(key) {
  const env = envDetails.getEnv()
  const DEFAULT = APP_URL[e.envDetails.constants.PRODUCTION]
  const envBasedUrls = APP_URL[env] || {}

  if (env === e.envDetails.constants.PRODUCTION) {
    return DEFAULT[key]
  }
  return assign({}, DEFAULT, envBasedUrls)[key]
}

function getURL(url, excludeOrigin, origin) {
  // const env = envDetails.getEnv()
  let str = ''

  try {
    if (url && excludeOrigin) {
      const originName = origin ? appDomains.getAppOrigin(origin) : null
      if (origin && originName) {
        str = `${originName}${getUrlString(url)}`
      } else {
        // env
        str = `${getUrlString(url)}`
      }
    } else if (url) {
      str = `${envDetails.getOrigin()}${getUrlString(url)}`
    } else {
      return url
    }

    if (str.includes('@SITE_ID')) {
      const siteId = getSiteId()
      if (siteId) str = str.replace(/@SITE_ID/, siteId)
      else str = str.replace(/\/@SITE_ID/, siteId)
    }

    if (str.includes('@USER_ID')) {
      str = str.replace(/@USER_ID/, `userId=${getUserId()}`)
    }

    if (str.includes('@SERIAL_NUMBER')) {
      str = str.replace(/@SERIAL_NUMBER/, getSerialNumber())
    }

    if (str.includes('@SOURCE')) {
      const source = getQueryParam('source')
      if (source) str = str.replace(/@SOURCE/, `source=${source}`)
      else str = str.replace(/@SOURCE/, '')
    }

    if (str.includes('@LOCALE')) {
      const locale = getLocale()
      if (locale && locale !== '') str = str.replace(/@LOCALE/, `locale=${locale}`)
      else str = str.replace(/@LOCALE/, '')
    }

    if (str.includes('@UID')) {
      str = str.replace(/@UID/, getDeviceUId())
    }

    return str
  } catch (err) {
    console.error('[Error] urlStrings getURL', err)
    return null
  }
}

const urlStrings = {
  APP_URL,
  getUrlString,
  getURL,
}

export default urlStrings
