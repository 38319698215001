/* eslint-disable fp/no-mutation */
import e from 'e_modules'
import _ from 'lodash'
import i18next from 'i18next'
import {openUrlFromIframe} from 'lib/common'
import {getSiteSettingsDetails} from 'lib/store'
import moment from 'moment-timezone'
import {KEY, MOMENT_JS_FORMAT, SCREEN_TYPE, SOURCE_TYPE, SUPPORTED_LOCALE} from './constants'
import './nativeDetails'

export function getEnlighten4Session() {
  const session =
    e.storage.get('session') || window.getCookie('_enlighten_4_session') || localStorage.getItem('session')
  const token = session && session.toString().replace(/^"|"$/g, '')

  // if (isDevServer()) {
  //   return getDeviceDetails('token', 'token') || token
  // }

  // return token
  return getDeviceDetails('token', 'token') || token
}

// Kebab Case
export function toKebabCase(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

// Get Nano Seconds
export function getNanoSecTime() {
  return window.performance.now()
}

// Get key's value from Local Storage
export function getFromLocalStorage(key) {
  return localStorage.getItem(key)
}

// Get Specific / all Query Params
export function getQueryParam(key, getAll) {
  const {search} = window.location
  const params = new URLSearchParams(search)

  let value = params.get(key)
  if (!value && key === KEY.ORIGIN) {
    value = window.location.origin
  }

  if (!key && getAll) {
    const paramObj = {}
    for (const pk of params.keys()) {
      paramObj[pk] = params.get(pk)
    }
    return paramObj
  }

  return value
}

// Get Device Details
export function getDeviceDetails(paramKey, localStorageKey) {
  return (
    (paramKey && getQueryParam(paramKey)) ||
    (localStorageKey && getFromLocalStorage(localStorageKey)) ||
    window.getCookie(localStorageKey)
  )
}

// Native :: Can Launch External URL
export function canLaunchExternalUrl() {
  const elu = getDeviceDetails('elu', 'elu')
  return (elu != null) === true
}

// Open Url
export function openURL(url) {
  const isExternalLaunchURLSupported = canLaunchExternalUrl()

  if (!url || url === '') {
    return
  }

  if (isExternalLaunchURLSupported) {
    let link = `enphase://user?url=${url}`
    link += '&eluEnc=1'

    openUrlFromIframe({link, url, target: '_self'})
    // window.open(link, '_self')
  } else {
    window.open(url, '_blank')
  }
}

// Get Locale
export function getLocale() {
  return getDeviceDetails('locale', 'locale') || getSiteSettingsDetails().locale || SUPPORTED_LOCALE.EN
}

// Set Locale
export function setLocale() {
  const appLocale = getDeviceDetails('locale', 'locale')
  const locale = _.includes(SUPPORTED_LOCALE, appLocale) && appLocale
  if (locale) {
    i18next.changeLanguage(locale)
  }
}

// Get Source
export function getSource() {
  return getDeviceDetails('source', 'source')
}

// Get OS Type
export function getOSType() {
  return getDeviceDetails('osType', 'osType')
}

// Get Serial Number
export function getSerialNumber() {
  // TODO
  return 123
}

// Get Device UId
export function getDeviceUId() {
  // TODO
  return 234123
}

// Delete the keys given in the array from an object
const transform = (obj, predicate) =>
  Object.keys(obj).reduce((memo, key) => {
    if (predicate(obj[key], key)) {
      memo[key] = obj[key]
    }
    return memo
  }, {})

export const omit = (obj, items) => transform(obj, (value, key) => !items.includes(key))

// Different between two objects
export const objDiff = (a, b) =>
  Object.keys(b).reduce((diff, key) => {
    if (a[key] === b[key]) return diff
    return {
      ...diff,
      [key]: b[key],
    }
  }, {})

// Get isActivePage
export const isActivePage = name => {
  const page = getDeviceDetails('type', 'type')
  return page === name
}

// Check whether the screen type is web
export const isWeb = () => getDeviceDetails('uiType', 'uiType') === SCREEN_TYPE.WEB

// Check whether application is enho web
export const isEnhoWeb = () => isWeb() && isSource(SOURCE_TYPE.ENHO)

// Checks the source of the application.
export const isSource = type => getSource() === type

// Checks the device OS type of the application. (ios / android)
export const isOSType = type => getOSType() === type

// Get status icon method
export function getStatusIcon(type) {
  switch (type) {
    case 'onGrid': // GRID_RELAY.OPER_RELAY_CLOSED.TOGGLE_LABEL
      return 'menuOnGrid'
    case 'offGrid':
      return 'menuOffGrid'
    case 'unknown':
    case 'menuUnknownGrid':
      return 'menuUnknownGrid'
    case 'generatorOn':
      return 'generatorOn'
    case 'generatorOff':
      return 'generatorOff'
    default:
      return 'systemError'
  }
}

// Get fixed value with a specified precision
export function getFixedValue(value, precision = 2) {
  if (value || value >= 0) {
    const locale = 'en' // I18n.locale
    const {ES, DE, NL, IT, FR, PT, PL} = SUPPORTED_LOCALE
    let valueToChange = value.toString()
    valueToChange = valueToChange.split(',')
    valueToChange = valueToChange.join('.')
    valueToChange = parseFloat(valueToChange).toFixed(precision)
    if (
      locale === ES ||
      locale === DE ||
      locale === NL ||
      locale === IT ||
      locale === FR ||
      locale === PT ||
      locale === PL
    ) {
      valueToChange = valueToChange.split('.')
      valueToChange = valueToChange.join(',')
    }
    return valueToChange
  }
  return value
}

// capitalizing First Letter
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// find the nearest value for slider
export const findNearestValue = value => {
  let val = 0
  const arr = [0, 20, 40, 60, 80, 100]

  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i] < value) val = arr[i]
  }
  return val
}

// Ellipsis for long string
export const textEllipsis = (text, charLimit = 25) =>
  text.length > charLimit ? `${text.substring(0, charLimit)}...` : text

export function getUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

export function scrollToView(ele, opt) {
  ele?.scrollIntoView({block: opt?.block || 'center', behavior: 'smooth'})
}

// Get Time Error
export function getTimeError(a, b) {
  const current = a?.formatted12
  const latest = b?.formatted12
  const startTimeSeconds = moment(current, MOMENT_JS_FORMAT.TIME_12H_AM_PM)
  const endTimeSeconds = moment(latest, MOMENT_JS_FORMAT.TIME_12H_AM_PM)
  let timeDiffInMinute = endTimeSeconds.diff(startTimeSeconds, 'minute')

  if (b?.hour === 24) timeDiffInMinute = startTimeSeconds.diff(endTimeSeconds, 'minute')

  if (timeDiffInMinute < 0) timeDiffInMinute = -timeDiffInMinute

  if (timeDiffInMinute < 0 || (b?.hour === 24 && b?.minute >= 1)) return i18next.t('bp72')

  if (timeDiffInMinute < 15 || timeDiffInMinute > 1425) return i18next.t('bp73')

  return null
}
