/* eslint-disable fp/no-mutation */

import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18next from 'i18next'
import {DIALOG_TYPE, PROFILE_TYPE, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import {isWeb} from 'lib/utils/utility'
import {closeDialog, setDialogValue} from '../../../../store/slices/dialogDetails'
import Icon from '../../../../utils/icon'
import {
  IQGWScheduleSupportWithSavingsMode,
  isNem3Supported,
  isNem3TariffSupported,
  systemWithEnchargeAndEnpower,
} from '../../../../utils/siteDetails'
import SystemProfileCard from '../../atoms/SystemProfileCard'
import {setPdValue} from '../../organisms/ProfileDetails/pdSlice'
import {setBrValue} from '../BatteryReserve/brSlice'
import ChooseRightProfile from '../ChooseRightProfile'
import StoragePreferenceList from '../StoragePreferenceList'
import './styles.scss'

class FindRightProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screen: this.props.screen,
      firstVal: this.props.tutorialObj.firstVal || 1,
      secondVal: this.props.tutorialObj.secondVal || 2,
    }
    this.onSubmitPriority = this.onSubmitPriority.bind(this)
  }

  handleDialogChange = () => {
    const obj = {
      className: `sph__choose-right-profile-popup`,
      type: isWeb() ? DIALOG_TYPE.CENTER : DIALOG_TYPE.BOTTOM,
      showDialog: true,
      showCloseIcon: true,
      title: i18next.t('bp129'),
      content: <ChooseRightProfile />,
      buttons: null,
      onClickCloseIcon: this.props.closeDialog,
    }

    obj && this.props.setDialogValue({...obj})
  }

  onSubmitPriority(firstVal, secondVal) {
    this.setState({
      screen: 2,
      firstVal,
      secondVal,
    })
  }

  getRecommendationScreen() {
    const batteryConfigResponse = JSON.parse(this.props.batteryConfigResponse)
    const mode = 'savings'
    let active = true
    let inActive = false
    const {profile, content, recommendationText} = this.getProfileAssistantCardContent(batteryConfigResponse)
    let heading = i18next.t('bp162')

    const soc = batteryConfigResponse.batteryBackupPercentage

    if (batteryConfigResponse.requestedConfig && !_.isEmpty(batteryConfigResponse.requestedConfig)) {
      inActive = true
    } else if (batteryConfigResponse.profile === profile.usage) {
      if (batteryConfigResponse.requestedConfig && !_.isEmpty(batteryConfigResponse.requestedConfig)) {
        // eslint-disable-next-line no-unused-vars
        inActive = true
      } else if (
        profile.usage === PROFILE_TYPE.COST_SAVINGS &&
        profile.battery_backup_percentage === soc &&
        profile.operationModeSubType === batteryConfigResponse.operationModeSubType
      ) {
        active = false
        heading = i18next.t('bp161')
      } else if (profile.usage === PROFILE_TYPE.SELF_CONSUMPTION && profile.battery_backup_percentage === soc) {
        active = false
        heading = i18next.t('bp161')
      }
    }

    const cardContent = (
      <div className={`as-card ${mode}__card`}>
        <div className="content">{content}</div>
      </div>
    )

    return (
      <div className={`prf-assist ${!active ? 'already-recommended-mode' : ''}`}>
        <Icon src="prfAssistant" />
        <div className="t1">{heading}</div>
        {systemWithEnchargeAndEnpower() && <div className="t2">{recommendationText}</div>}
        {cardContent}
        <div className="prf-assist_footer-actions">
          {
            <div
              role="button"
              tabIndex={0}
              className="learn-how"
              onClick={() => {
                this.handleDialogChange()
              }}
              onKeyDown={() => {}}>
              {i18next.t('bp129')}
            </div>
          }{' '}
          <div
            role="button"
            tabIndex={0}
            className="edit-pref"
            onClick={() => {
              this.gotoScreen(1)
            }}
            onKeyDown={() => {
              this.gotoScreen(1)
            }}>
            {i18next.t('bp160')}
          </div>
        </div>
      </div>
    )
  }

  getRecommendationText(type) {
    let desc
    switch (type) {
      case 'firstString': {
        if (isNem3Supported() && isNem3TariffSupported()) {
          desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp441') : i18next.t('bp355')
        } else {
          desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp442') : i18next.t('bp175')
        }
        return desc
      }
      case 'secondString': {
        if (isNem3Supported() && isNem3TariffSupported()) {
          desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp443') : i18next.t('bp356')
        } else {
          desc = IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp444') : i18next.t('bp176')
        }
        return desc
      }
      default:
        return false
    }
  }

  getProfileAssistantCardContent = () => {
    const {firstVal, secondVal} = this.state
    const tutorialObj = {
      firstVal,
      secondVal,
    }
    const profile = {
      usage: PROFILE_TYPE.COST_SAVINGS,
      battery_backup_percentage: 30,
      operationModeSubType: '',
    }
    let content = ''
    let reserveValue = 30
    let recommendationText = ''

    if (!systemWithEnchargeAndEnpower()) {
      if (firstVal === 1 && secondVal === 2) {
        reserveValue = 0
        const reserveProfileObj = {
          [PROFILE_TYPE.SELF_CONSUMPTION]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.SELF_CONSUMPTION} tutorialObj={tutorialObj} />
        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.SELF_CONSUMPTION
        recommendationText = i18next.t('bp174')
      } else {
        reserveValue = 0
        const reserveProfileObj = {
          [PROFILE_TYPE.COST_SAVINGS]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        this.props.setPdValue({operationModeSubType: TOGGLE_SWITCH_STATUS.ON})
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.COST_SAVINGS} tutorialObj={tutorialObj} />
        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.COST_SAVINGS
        profile.operationModeSubType = 'prioritize-energy'
        recommendationText = this.getRecommendationText('firstString')
      }
    } else if (firstVal === 1) {
      if (secondVal === 2) {
        reserveValue = 30
        const reserveProfileObj = {
          [PROFILE_TYPE.SELF_CONSUMPTION]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.SELF_CONSUMPTION} tutorialObj={tutorialObj} />
        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.SELF_CONSUMPTION
        recommendationText = i18next.t('bp174')
      } else if (secondVal === 3) {
        reserveValue = 60
        const reserveProfileObj = {
          [PROFILE_TYPE.SELF_CONSUMPTION]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.SELF_CONSUMPTION} tutorialObj={tutorialObj} />
        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.SELF_CONSUMPTION
        recommendationText = i18next.t('bp177')
      }
    } else if (firstVal === 2) {
      if (secondVal === 1) {
        reserveValue = 30
        const reserveProfileObj = {
          [PROFILE_TYPE.COST_SAVINGS]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        this.props.setPdValue({operationModeSubType: TOGGLE_SWITCH_STATUS.ON})
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.COST_SAVINGS} tutorialObj={tutorialObj} />
        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.COST_SAVINGS
        profile.operationModeSubType = 'prioritize-energy'
        recommendationText = this.getRecommendationText('firstString')
      } else if (secondVal === 3) {
        reserveValue = 60
        const reserveProfileObj = {
          [PROFILE_TYPE.COST_SAVINGS]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        this.props.setPdValue({operationModeSubType: TOGGLE_SWITCH_STATUS.OFF})
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.COST_SAVINGS} tutorialObj={tutorialObj} />
        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.COST_SAVINGS
        profile.operationModeSubType = ''
        recommendationText = this.getRecommendationText('secondString')
      }
    } else if (firstVal === 3) {
      if (secondVal === 1) {
        reserveValue = 60
        const reserveProfileObj = {
          [PROFILE_TYPE.SELF_CONSUMPTION]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.SELF_CONSUMPTION} tutorialObj={tutorialObj} />

        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.SELF_CONSUMPTION
        recommendationText = i18next.t('bp177')
      } else if (secondVal === 2) {
        reserveValue = 60
        const reserveProfileObj = {
          [PROFILE_TYPE.COST_SAVINGS]: reserveValue,
        }
        this.props.setBrValue({
          brReserve: reserveValue,
          reserveProfile: reserveProfileObj,
        })
        this.props.setPdValue({operationModeSubType: TOGGLE_SWITCH_STATUS.OFF})
        content = <SystemProfileCard fromTutorial profile={PROFILE_TYPE.COST_SAVINGS} tutorialObj={tutorialObj} />
        profile.battery_backup_percentage = reserveValue
        profile.usage = PROFILE_TYPE.COST_SAVINGS
        profile.operationModeSubType = ''
        recommendationText = this.getRecommendationText('secondString')
      }
    }

    return {
      profile,
      content,
      recommendationText,
    }
  }

  getChoicesScreen() {
    return (
      <StoragePreferenceList onSubmit={this.onSubmitPriority} /> // triggerGAEvent={this.triggerGAEvent}
    )
  }

  gotoScreen(screen) {
    this.setState({
      screen,
    })
  }

  render() {
    const {screen} = this.state
    const profileAssistantContent = (
      <div className="storageprofileassistant">
        <div className={screen !== 1 ? 'storageprofileassistant__display__none' : ''}>{this.getChoicesScreen()}</div>
        <div className={screen !== 2 ? 'storageprofileassistant__display__none' : ''}>
          {this.getRecommendationScreen()}
        </div>
      </div>
    )
    return profileAssistantContent
  }
}

export default connect(null, {closeDialog, setDialogValue, setBrValue, setPdValue})(FindRightProfile)

FindRightProfile.propTypes = {
  batteryConfigResponse: PropTypes.string.isRequired,
  closeDialog: PropTypes.func,
  setDialogValue: PropTypes.func,
  setBrValue: PropTypes.func,
  setPdValue: PropTypes.func,
  screen: PropTypes.number,
  firstVal: PropTypes.number,
  secondVal: PropTypes.number,
  tutorialObj: PropTypes.shape({
    firstVal: PropTypes.number,
    secondVal: PropTypes.number,
  }),
}

FindRightProfile.defaultProps = {
  closeDialog: null,
  setBrValue: null,
  setPdValue: null,
  setDialogValue: null,
  screen: 1,
  firstVal: 1,
  secondVal: 1,
  tutorialObj: {
    firstVal: 1,
    secondVal: 2,
  },
}
