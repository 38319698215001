import React from 'react'
import PropTypes from 'prop-types'

export default class BatteryCharge extends React.PureComponent {
  renderBatteryPercentage = () => {
    if (this.props.percent > 90) {
      return (
        <>
          <path
            id="Rectangle_6874"
            data-name="Rectangle 6874"
            d="M.5-.75H13.967A1.255,1.255,0,0,1,15.217.507v24.9a1.255,1.255,0,0,1-1.25,1.257H.5A1.255,1.255,0,0,1-.75,25.407V.507A1.255,1.255,0,0,1,.5-.75ZM13.717.758H.75v24.4H13.717Z"
            transform="translate(0.75 3.336)"
          />
          <path
            id="Path_20140"
            data-name="Path 20140"
            d="M21.023,1.753H13.5V.546c0-.3.357-.546.8-.546h5.929c.44,0,.8.244.8.546Z"
            transform="translate(-9.278)"
          />
          <g id="Group_17329" data-name="Group 17329" transform="translate(0.001 -0.225)">
            <path
              id="Path_20142"
              data-name="Path 20142"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22541.363)"
            />
            <path
              id="Path_20144"
              data-name="Path 20144"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22536.852)"
            />
            <path
              id="Path_20145"
              data-name="Path 20145"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22532.273)"
            />
            <path
              id="Path_20146"
              data-name="Path 20146"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22527.695)"
            />
            <path
              id="Path_20147"
              data-name="Path 20147"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22523.078)"
            />
          </g>
        </>
      )
    }
    if (this.props.percent > 70 && this.props.percent <= 90) {
      return (
        <>
          <path
            id="Rectangle_6874"
            data-name="Rectangle 6874"
            d="M.5-.75H13.967A1.255,1.255,0,0,1,15.217.507v24.9a1.255,1.255,0,0,1-1.25,1.257H.5A1.255,1.255,0,0,1-.75,25.407V.507A1.255,1.255,0,0,1,.5-.75ZM13.717.758H.75v24.4H13.717Z"
            transform="translate(0.75 3.336)"
          />
          <path
            id="Path_20140"
            data-name="Path 20140"
            d="M21.023,1.753H13.5V.546c0-.3.357-.546.8-.546h5.929c.44,0,.8.244.8.546Z"
            transform="translate(-9.278)"
          />
          <g id="Group_17329" data-name="Group 17329" transform="translate(0.001 -0.225)">
            <path
              id="Path_20144"
              data-name="Path 20144"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22536.852)"
            />
            <path
              id="Path_20145"
              data-name="Path 20145"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22532.273)"
            />
            <path
              id="Path_20146"
              data-name="Path 20146"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22527.695)"
            />
            <path
              id="Path_20147"
              data-name="Path 20147"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22523.078)"
            />
          </g>
        </>
      )
    }
    if (this.props.percent > 50 && this.props.percent <= 70) {
      return (
        <>
          <path
            id="Rectangle_6874"
            data-name="Rectangle 6874"
            d="M.5-.75H13.967A1.255,1.255,0,0,1,15.217.507v24.9a1.255,1.255,0,0,1-1.25,1.257H.5A1.255,1.255,0,0,1-.75,25.407V.507A1.255,1.255,0,0,1,.5-.75ZM13.717.758H.75v24.4H13.717Z"
            transform="translate(0.75 3.336)"
          />
          <path
            id="Path_20140"
            data-name="Path 20140"
            d="M21.023,1.753H13.5V.546c0-.3.357-.546.8-.546h5.929c.44,0,.8.244.8.546Z"
            transform="translate(-9.278)"
          />
          <g id="Group_17329" data-name="Group 17329" transform="translate(0.001 -0.225)">
            <path
              id="Path_20145"
              data-name="Path 20145"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22532.273)"
            />
            <path
              id="Path_20146"
              data-name="Path 20146"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22527.695)"
            />
            <path
              id="Path_20147"
              data-name="Path 20147"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22523.078)"
            />
          </g>
        </>
      )
    }
    if (this.props.percent > 20 && this.props.percent <= 50) {
      return (
        <>
          <path
            id="Rectangle_6874"
            data-name="Rectangle 6874"
            d="M.5-.75H13.967A1.255,1.255,0,0,1,15.217.507v24.9a1.255,1.255,0,0,1-1.25,1.257H.5A1.255,1.255,0,0,1-.75,25.407V.507A1.255,1.255,0,0,1,.5-.75ZM13.717.758H.75v24.4H13.717Z"
            transform="translate(0.75 3.336)"
          />
          <path
            id="Path_20140"
            data-name="Path 20140"
            d="M21.023,1.753H13.5V.546c0-.3.357-.546.8-.546h5.929c.44,0,.8.244.8.546Z"
            transform="translate(-9.278)"
          />
          <g id="Group_17329" data-name="Group 17329" transform="translate(0.001 -0.225)">
            <path
              id="Path_20146"
              data-name="Path 20146"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22527.695)"
            />
            <path
              id="Path_20147"
              data-name="Path 20147"
              d="M944.949,22549.719h-8.231v-2h8.231Z"
              transform="translate(-932.851 -22523.078)"
            />
          </g>
        </>
      )
    }
    return (
      <>
        <path
          id="Rectangle_6874"
          data-name="Rectangle 6874"
          d="M.5-.75H13.967A1.255,1.255,0,0,1,15.217.507v24.9a1.255,1.255,0,0,1-1.25,1.257H.5A1.255,1.255,0,0,1-.75,25.407V.507A1.255,1.255,0,0,1,.5-.75ZM13.717.758H.75v24.4H13.717Z"
          transform="translate(0.75 3.336)"
        />
        <path
          id="Path_20140"
          data-name="Path 20140"
          d="M21.023,1.753H13.5V.546c0-.3.357-.546.8-.546h5.929c.44,0,.8.244.8.546Z"
          transform="translate(-9.278)"
        />
        <g id="Group_17319" data-name="Group 17319" transform="translate(3.867 24.416)">
          <path
            id="Path_48900"
            data-name="Path 48900"
            d="M944.949,22549.719h-8.231v-2h8.231Z"
            transform="translate(-936.718 -22547.719)"
          />
        </g>
      </>
    )
  }

  renderFillColor() {
    if (this.props.percent <= 20) {
      return '#dd3c41'
    }
    return '#7acf38'
  }

  render() {
    const fillColor = this.renderFillColor()
    const batteryChargeIcon = this.renderBatteryPercentage()

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="30" viewBox="0 0 16 30">
        <g id="Batery" transform="translate(-7 0.394)">
          <rect
            id="Rectangle_5236"
            data-name="Rectangle 5236"
            width="16"
            height="16"
            transform="translate(7 6.606)"
            fill="#f37320"
            opacity="0"
          />
          <g fill={fillColor} id="Group_17320" data-name="Group 17320" transform="translate(7.017 -0.394)">
            {batteryChargeIcon}
          </g>
        </g>
      </svg>
    )
  }
}

BatteryCharge.propTypes = {percent: PropTypes.number}

BatteryCharge.defaultProps = {percent: 0}
