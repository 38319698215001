import React from 'react'

const ExternalLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 18">
    <g fill="#01b5de" strokeWidth=".5">
      <path
        stroke="#00B4E0"
        d="M14.286 9.322h-.665c-.097 0-.176.031-.238.094-.063.062-.094.141-.094.238v3.322c0 .457-.163.848-.488 1.174-.325.325-.716.487-1.173.487H2.99c-.457 0-.848-.162-1.173-.487-.326-.326-.488-.717-.488-1.174V4.34c0-.457.162-.848.488-1.174.325-.325.716-.487 1.173-.487h7.309c.097 0 .177-.032.239-.094s.093-.142.093-.239v-.664c0-.097-.031-.177-.093-.239s-.142-.093-.24-.093H2.99c-.824 0-1.528.292-2.113.877S0 3.516 0 4.339v8.638c0 .823.292 1.527.877 2.112.585.585 1.29.878 2.113.878h8.638c.823 0 1.528-.293 2.113-.878s.877-1.289.877-2.112V9.654c0-.097-.031-.176-.094-.238-.062-.063-.141-.094-.238-.094z"
        transform="translate(1 1)"
      />
      <path
        stroke="none"
        d="M18.407.217C18.276.086 18.12.02 17.94.02h-5.316c-.18 0-.335.066-.467.197-.131.132-.197.287-.197.467 0 .18.066.336.197.468l1.827 1.827-6.769 6.769c-.069.07-.103.149-.103.239s.034.17.103.238L8.4 11.41c.07.07.149.104.239.104s.17-.035.239-.104l6.769-6.77 1.827 1.828c.131.132.287.197.467.197.18 0 .336-.065.467-.197.132-.131.198-.287.198-.467V.684c0-.18-.066-.335-.198-.467z"
        transform="translate(1 1)"
      />
    </g>
  </svg>
)

ExternalLink.propTypes = {}

ExternalLink.defaultProps = {}

export default ExternalLink
