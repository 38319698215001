import React from 'react'
import {SOURCE_TYPE} from 'lib/utils/constants'
import {isSource} from 'lib/utils/utility'
import ItkHelp from './itkHelp'

const Info = () =>
  isSource(SOURCE_TYPE.ITK) ? (
    <ItkHelp />
  ) : (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00009 0.000127627C7.0702 -0.00691003 8.12207 0.277231 9.04309 0.822128C9.93412 1.32842 10.6718 2.06609 11.1781 2.95713C11.723 3.87814 12.0071 4.93002 12.0001 6.00013C12.0071 7.07024 11.723 8.12211 11.1781 9.04313C10.6718 9.93416 9.93412 10.6718 9.04309 11.1781C8.12207 11.723 7.0702 12.0072 6.00009 12.0001C4.92998 12.0072 3.8781 11.723 2.95709 11.1781C2.06744 10.6638 1.33059 9.92212 0.822086 9.02913C0.278472 8.11269 -0.00566754 7.06566 8.56631e-05 6.00013C0.000312772 4.95281 0.274674 3.9238 0.795892 3.0154C1.31711 2.107 2.06703 1.35085 2.97109 0.822128C3.88753 0.278514 4.93456 -0.00562558 6.00009 0.000127627ZM5.099 2.261V4.077H6.873V2.261H5.099ZM5.099 9.515V5.008H6.873V9.515H5.099Z"
        fill="#A2ABB3"
      />
    </svg>
  )

Info.propTypes = {}

Info.defaultProps = {}

export default Info
