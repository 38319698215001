import React from 'react'

const Sun = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g className="icon-dark-fill-white" fill="#545456" fillRule="evenodd">
      <path d="M13.239 4.787L11.855.117 10.47 4.814a6.442 6.442 0 0 1 1.384-.15c.469 0 .939.051 1.384.124zM9.532 5.034L5.974 1.7 7.11 6.443c.716-.617 1.532-1.111 2.422-1.409zM6.444 7.11l-4.77-1.136L5.037 9.53a7.008 7.008 0 0 1 1.408-2.42zM4.664 11.855c0-.47.049-.94.148-1.384L.118 11.855l4.67 1.384c-.1-.445-.124-.915-.124-1.384zM5.034 14.177l-3.36 3.558 4.744-1.137a7.38 7.38 0 0 1-1.384-2.421zM5.974 22.01l3.557-3.36a7.178 7.178 0 0 1-2.421-1.384L5.974 22.01zM10.446 18.922l1.385 4.695 1.384-4.695a6.442 6.442 0 0 1-1.384.149c-.47-.025-.915-.076-1.385-.149zM14.177 18.675l3.534 3.36-1.112-4.744c-.717.593-1.532 1.087-2.422 1.384zM17.265 16.599l4.744 1.137-3.334-3.559c-.322.89-.792 1.73-1.41 2.422zM23.592 11.855l-4.67-1.384c.098.444.149.914.149 1.384 0 .469-.05.939-.149 1.384l4.67-1.384zM18.675 9.532L22.01 6l-4.744 1.137c.617.667 1.087 1.507 1.408 2.396zM17.71 1.699l-3.533 3.335c.89.297 1.704.767 2.422 1.385l1.112-4.72zM17.563 11.855a5.709 5.709 0 1 1-11.417 0 5.709 5.709 0 0 1 11.417 0" />
    </g>
  </svg>
)

Sun.propTypes = {}

Sun.defaultProps = {}

export default Sun
