/* eslint-disable fp/no-mutation */
import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import urlStrings from 'lib/utils/urlStrings'
import {getUserId} from 'lib/utils/userDetails'
import {getEnlighten4Session, getUUID} from '../utils/utility'
import {KEY, GRID_SERVICES} from '../utils/constants'

export const HTTPS_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
}

export const HTTPS_REQUEST_TYPE = {
  MQTT: 'mqtt',
  API: 'api',
  TASK: 'task',
}

export const HTTPS_API_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  CONNECTION_LOST: 'connection-lost',
}

const DATA_TYPE = {
  HTML: 'html',
  JSON: 'JSON',
}

const HTTP_REQUEST = (function httpRequest() {
  axios.defaults.headers.put['Content-Type'] = 'application/json'

  function isFunction(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply)
  }

  function getHeaders(details = {}) {
    let options = details && details.headers
    options = _.omit(options, ['contentType'])

    options.Username = getUserId()
    options.Requestid = getUUID()

    if (details && details.isHemsAPI) {
      const returnObj = {}
      if (details.username && details.requestId) {
        returnObj.username = details.username
        returnObj.requestId = details.requestId
      }
      if (details && details.bearerToken) returnObj['Authorization'] = `Bearer ${details.bearerToken}`

      // sending csrf token for only HEMS API
      if (details['X-XSRF-TOKEN'] && details) returnObj['x-xsrf-token'] = details['X-XSRF-TOKEN']

      return {...returnObj, ...options}
    }

    // Authorization Bearer
    if (details && details?.bearerToken) {
      return {
        Authorization: `Bearer ${details.bearerToken}`,
        ...options,
      }
    }

    // Authorization with e-auth-token
    if (details && details.source === GRID_SERVICES) {
      return {
        Authorization: details.token,
        [KEY.E_AUTH_TOKEN]: getEnlighten4Session(),
        ...options,
      }
    }

    // Skip Headers
    if (details && details.headers && details.skipEAuth) {
      return details.headers
    }

    // e-auth-token
    return {
      [KEY.E_AUTH_TOKEN]: getEnlighten4Session(),
      ...options,
    }
  }

  function getDataType(details = {}) {
    return [DATA_TYPE.HTML, DATA_TYPE.JSON].includes(details?.dataType) ? details?.dataType : DATA_TYPE.JSON
  }

  /* *********************** *********************** *********************** *
   *  GET API Call
   * *********************** *********************** *********************** */

  function GET(...args) {
    const url = args[0]
    const details = !isFunction(args[1]) ? args[1] : null
    const success = details ? args[2] : args[1]
    const error = details ? args[3] : args[2]

    const getData = {
      method: HTTPS_METHOD.GET,
      headers: getHeaders(details),
      params: details && details.params,
    }

    if (!url) {
      return
    }

    const options = {
      url,
      method: getData.method,
      headers: getData.headers,
      data: getData.params || null,
      success,
      error,
    }

    // Adding xhrField for Hems API
    if (details && details.xhrFields) {
      options['xhrFields'] = {
        ...details.xhrFields,
      }
    }

    axios(options)
      .then(res => success(res.data))
      .catch(err => error(err))
  }

  /* *********************** *********************** *********************** *
   *  POST API Call
   * *********************** *********************** *********************** */

  function POST(url, details, success, error) {
    const postData = {
      method: HTTPS_METHOD.POST,
      headers: getHeaders(details),
      contentType: details?.headers?.contentType || 'application/json',
      dataType: getDataType(details),
    }
    postData.body = details?.body ? details?.body : details || {}

    if (!url) {
      return
    }

    if (!isFunction(success)) {
      return
    }

    if (!isFunction(error)) {
      return
    }

    const options = {
      url,
      method: postData.method,
      headers: postData.headers,
      data: postData.dataType === DATA_TYPE.JSON ? JSON.stringify(postData.body) : postData.body,
      responseType: postData.dataType.toLowerCase(),
      success,
      error,
    }

    if (postData?.contentType) {
      options.headers['Content-Type'] = postData.contentType
    }

    // Adding xhrField for Hems API
    if (details && details?.xhrFields) {
      options['xhrFields'] = {
        ...details.xhrFields,
      }
    }

    axios(options)
      .then(res => success(res.data))
      .catch(err => error(err))
  }

  /* *********************** *********************** *********************** *
   *  POST MULTIPART API Call
   * *********************** *********************** *********************** */

  function POST_MULTIPART(url, details, success, error) {
    const postData = {
      method: HTTPS_METHOD.POST,
      headers: getHeaders(details),
      dataType: 'json', // ['html', 'JSON'].includes(details.dataType) ? details.dataType : 'JSON'
    }

    postData.body = details?.body

    if (!url) {
      return
    }

    if (!isFunction(success)) {
      return
    }

    if (!isFunction(error)) {
      return
    }

    $.ajax({
      // type: "POST",
      method: postData.method,
      url,
      data: postData.body,
      dataType: postData.dataType,
      // contentType: "multipart/form-data",
      processData: false,
      contentType: false,
      crossDomain: true,
      headers: {
        // "Accept": "application/json",
        ...getHeaders(),
      },
      success,
      error,
    })
  }

  /* *********************** *********************** *********************** *
   *  PUT API Call
   * *********************** *********************** *********************** */

  function PUT(url, details, success, error) {
    const putData = {
      method: HTTPS_METHOD.PUT,
      headers: getHeaders(details),
      contentType: details?.headers?.contentType || 'application/json',
      dataType: getDataType(details),
    }
    putData.body = details?.body ? details?.body : details || {}

    if (!url) {
      return
    }

    if (!isFunction(success)) {
      return
    }

    if (!isFunction(error)) {
      return
    }

    const options = {
      url,
      method: putData.method,
      headers: putData.headers,
      data: putData.dataType === DATA_TYPE.JSON ? JSON.stringify(putData.body) : putData.body,
      responseType: putData.dataType.toLowerCase(),
      success,
      error,
    }

    if (putData?.contentType) {
      options.headers['Content-Type'] = putData.contentType
    }

    // Adding xhrField for Hems API
    if (details && details?.xhrFields) {
      options['xhrFields'] = {
        ...details.xhrFields,
      }
    }

    axios(options)
      .then(res => success(res.data))
      .catch(err => error(err))
  }

  /* *********************** *********************** *********************** *
   *  POST FORM API Call
   * *********************** *********************** *********************** */

  function POST_FORM(url, details, success, error) {
    const postData = {
      method: HTTPS_METHOD.POST,
      headers: getHeaders(details),
    }

    const headers = {
      contentType: 'application/x-www-form-urlencoded',
      accessControlAllowOrigin: '*',
    }

    if (!url) {
      return
    }

    postData.body = details?.body ? details?.body : details
    if (details?.session) {
      postData.body['_enlighten_4_session'] = getEnlighten4Session()
    }

    // const formData = new FormData()
    // _.each(postData.body, (value, key) => {
    //   formData.append(encodeURIComponent(key), encodeURIComponent(value))
    // })

    // const formData = $.param(postData.body)

    const formData = postData.body

    if (!isFunction(success)) {
      return
    }

    if (!isFunction(error)) {
      return
    }

    $.ajax({
      url,
      type: postData.method,
      headers: postData.headers,
      data: formData,
      processData: true,
      contentType: headers.contentType,
      accessControlAllowOrigin: headers.accessControlAllowOrigin,
      success,
      error,
    })
  }

  /* *********************** *********************** *********************** *
   *  Make Request
   * *********************** *********************** *********************** */

  function makeRequest(type, pathName, params) {
    return new Promise((resolve, reject) => {
      const url = params?.domain ? urlStrings.getURL(pathName, true, params.domain) : urlStrings.getURL(pathName)

      function cbSuccess(response) {
        resolve(response)
      }

      function cbError(error) {
        reject(error)
      }

      if (type === HTTPS_METHOD.POST) {
        HTTP_REQUEST.POST(url, params, cbSuccess, cbError)
      } else if (type === HTTPS_METHOD.PUT) {
        HTTP_REQUEST.PUT(url, params, cbSuccess, cbError)
      } else if (type === HTTPS_METHOD.GET && params) {
        HTTP_REQUEST.GET(url, params, cbSuccess, cbError)
      } else {
        HTTP_REQUEST.GET(url, cbSuccess, cbError)
      }
    })
  }

  return {
    GET,
    POST,
    POST_FORM,
    PUT,
    POST_MULTIPART,
    makeRequest,
  }
})()

export default HTTP_REQUEST
