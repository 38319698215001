import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {showHeader} from 'lib/utils/pageDetails'
import Header from '../../atoms/Header'
import './styles.scss'

/* *********************** *********************** *********************** *
 * EV Top Up Schedule Page
 * *********************** *********************** *********************** */

const EvTopUpSchedulePage = props => {
  const {id} = props

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // eslint-disable-next-line no-unused-vars
  const onClickChargerOption = () => {
    console.log('onClickChargerOption')
  }

  /* *********************** *********************** *********************** *
   * Render Functions
   * *********************** *********************** *********************** */

  function renderEvChargerOptions() {
    return 'EV Top Up Schedule'
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    // EV Top Up Schedule
    <>
      {showHeader() && <Header id={id} title={i18next.t('bp214')} subTitle={i18next.t('bp230')} />}
      <div className="bp__page--wrapper">{renderEvChargerOptions()}</div>
    </>
  )
}

EvTopUpSchedulePage.propTypes = {
  id: PropTypes.string.isRequired,
}

EvTopUpSchedulePage.defaultProps = {}

export default EvTopUpSchedulePage
