/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import {siteHasPv} from 'lib/utils/siteDetails'
import {OMST_STATUS, PENDING_REQ_KEY, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import {isAfterPeakRequestPending} from '../../organisms/reqPending'

// After Peak :: Get Toggle Status (on | off | loading)
export function getAfterPeakToggleStatus(details, loading) {
  const onSubType = getOperationModeSubType(details)

  if (loading) {
    return TOGGLE_SWITCH_STATUS.LOADING
  }

  if (onSubType === OMST_STATUS.PRIORITIZE_ENERGY) {
    return TOGGLE_SWITCH_STATUS.ON
  }

  return TOGGLE_SWITCH_STATUS.OFF
}

// After Peak :: Get After Peak Toggle Value (prioritize-energy | '')
export function getAfterPeakToggleValue(value) {
  if (value === TOGGLE_SWITCH_STATUS.ON) {
    return OMST_STATUS.PRIORITIZE_ENERGY
  }

  return ''
}

// After Peak :: Get Operation Mode SubType (details, requestedConfig, requestedConfigMqtt)
export function getOperationModeSubType(details) {
  if (isAfterPeakRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].operationModeSubType
  }

  if (isAfterPeakRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    return details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].operationModeSubType
  }

  return details?.operationModeSubType
}

// After Peak :: isCurrent Request has After Peak Changes
export function isCurrentRequestHasAfterPeakChanges(details, operationModeSubType) {
  const omSubType = getAfterPeakToggleStatus(details)

  return omSubType !== operationModeSubType
}

// After Peak Hours :: Get Info Description
export function getInfoDescription() {
  let descEnabled = i18next.t('bp36')
  let descDisabled = i18next.t('bp37')

  if (siteHasPv()) {
    descEnabled = i18next.t('bp38')
    descDisabled = i18next.t('bp39')
  }

  return (
    <div className="e_dialog__body__wrapper">
      <div className="e_dialog__content">
        <span className="bp__status-text--enabled">{i18next.t('bp7')}</span>: {descEnabled}
        <br />
        <br />
        <span className="bp__status-text--disabled">{i18next.t('bp8')}</span>: {descDisabled}
      </div>
    </div>
  )
}
