import React from 'react'
import {PropTypes} from 'prop-types'

const BatteryReserve = ({value}) => (
  <svg id="Batery" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect
      id="Rectangle_5236"
      data-name="Rectangle 5236"
      width="24"
      height="24"
      transform="translate(0)"
      fill="#f37320"
      opacity="0"
    />
    <g id="Group_17322" data-name="Group 17322" transform="translate(6.49 2)">
      <path
        id="Rectangle_6874"
        data-name="Rectangle 6874"
        d="M.455-.75H9.077a1.206,1.206,0,0,1,1.2,1.2V16.567a1.206,1.206,0,0,1-1.2,1.2H.455a1.206,1.206,0,0,1-1.2-1.2V.455A1.206,1.206,0,0,1,.455-.75ZM8.836.7H.7V16.33H8.84Z"
        transform="translate(0.745 2.229)"
        fill="#545456"
      />
      <path
        id="Path_20140"
        data-name="Path 20140"
        d="M18.485,1.155H13.5V.36c0-.2.236-.36.528-.36h3.929c.292,0,.528.161.528.36Z"
        transform="translate(-10.482 0)"
        fill="#545456"
      />
      <path
        id="Path_48899"
        data-name="Path 48899"
        d="M942.172,22549.645h-5.454v-1.926h5.454Z"
        transform="translate(-933.935 -22534.875)"
        fill="#545456"
      />
      <path
        id="Path_48900"
        data-name="Path 48900"
        d="M942.172,22549.645h-5.454v-1.926h5.454Z"
        transform="translate(-933.935 -22531.949)"
        fill="#545456"
      />
      <path
        id="Path_48900-2"
        data-name="Path 48900"
        d="M942.172,22549.645h-5.454v-1.926h5.454Z"
        transform="translate(-933.935 -22537.801)"
        fill="#545456"
      />

      {value === 100 && (
        <>
          <path
            id="Path_48900-3"
            data-name="Path 48900"
            d="M942.172,22549.645h-5.454v-1.926h5.454Z"
            transform="translate(-933.935 -22540.801)"
            fill="#545456"
          />
          <path
            id="Path_48900-4"
            data-name="Path 48900"
            d="M942.172,22549.645h-5.454v-1.926h5.454Z"
            transform="translate(-933.935 -22543.801)"
            fill="#545456"
          />
        </>
      )}
    </g>
    <path
      id="Polygon_1"
      data-name="Polygon 1"
      d="M2.25,0,4.5,3H0Z"
      transform="translate(17.5 14.5) rotate(-90)"
      fill="#545457"
    />
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M2.25,0,4.5,3H0Z"
      transform="translate(6.5 10) rotate(90)"
      fill="#545457"
    />
  </svg>
)

BatteryReserve.propTypes = {
  value: PropTypes.number,
}

BatteryReserve.defaultProps = {
  value: null,
}

export default BatteryReserve
