import React from 'react'

const ToastInfo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
    <g id="Group_424" data-name="Group 424" transform="translate(-129.25 -212.25)">
      <g id="Group_425" data-name="Group 425" transform="translate(0 0)">
        <path
          id="Path"
          d="M13.656,2.343a8,8,0,1,0,0,11.313A8,8,0,0,0,13.656,2.343Z"
          transform="translate(130 213)"
          fill="none"
          stroke="#fff"
          strokeWidth="1.5"
        />
        <path
          id="Union_1"
          data-name="Union 1"
          d="M0,0V5.592H2V0ZM0,6.746V9H2V6.746Z"
          transform="translate(137 216.5)"
          fill="#fff"
          stroke="#a2abb3"
          strokeWidth="0.2"
        />
      </g>
    </g>
  </svg>
)

ToastInfo.propTypes = {}

ToastInfo.defaultProps = {}

export default ToastInfo
