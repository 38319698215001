import React from 'react'

const InfoV2 = () => (
  <svg id="noun-info-862728" xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
    <path
      id="Path_3976"
      data-name="Path 3976"
      d="M213.1,117.6a25.5,25.5,0,1,0,18.032,7.468A25.5,25.5,0,0,0,213.1,117.6Zm0,48.362a22.863,22.863,0,1,1,16.165-6.7,22.857,22.857,0,0,1-16.165,6.7Z"
      transform="translate(-187.598 -117.6)"
      fill="#6f7070"
    />
    <path
      id="Path_3977"
      data-name="Path 3977"
      d="M334.078,252l-.88.923v15.739l.88.923h3.517l.88-.923V252.923l-.88-.923Z"
      transform="translate(-310.336 -230.897)"
      fill="#6f7070"
      stroke="#fff"
      strokeWidth="1"
    />
    <path
      id="Path_3978"
      data-name="Path 3978"
      d="M334.078,196l-.88.88V200.4l.88.88h3.517l.88-.88V196.88l-.88-.88Z"
      transform="translate(-310.336 -183.69)"
      fill="#6f7070"
      stroke="#fff"
      strokeWidth="1"
    />
  </svg>
)

InfoV2.propTypes = {}

InfoV2.defaultProps = {}

export default InfoV2
