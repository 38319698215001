import React from 'react'

const MenuOnGrid = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.63" height="20.107" viewBox="0 0 17.63 20.107">
    <g id="Display_Icons" data-name="Display Icons" transform="translate(0.5)">
      <path
        id="Subtraction_11"
        data-name="Subtraction 11"
        d="M1.3,17.254a.6.6,0,0,1-.58-.755L2.9,8.488c0-.013.008-.027.012-.047l.26-.96H.6a.6.6,0,1,1,0-1.2H3.5l.433-1.595H2a.6.6,0,0,1,0-1.2H4.255L5.084.444A.6.6,0,0,1,5.662,0H7.408A5.569,5.569,0,0,0,7,1.4l-.025-.092L6.948,1.2H6.122L5.5,3.491H7.081a5.563,5.563,0,0,0,.422,1.2H5.173L4.739,6.283H8.328l-.141-.521a5.607,5.607,0,0,0,2.38,1.718H9.9l.262.96a.044.044,0,0,0,0,.013v.012L12.348,16.5a.6.6,0,0,1-.272.671.629.629,0,0,1-.3.082.588.588,0,0,1-.418-.167L6.637,12.522l-.1-.1L1.716,17.084A.59.59,0,0,1,1.3,17.254ZM9.277,9.766l-1.769,1.71-.11.108L10.616,14.7Zm-5.485,0h0L2.454,14.7l3.107-3.01.112-.108L3.792,9.766Zm.62-2.285h0l-.26.96,2.276,2.206.1.1L8.915,8.441l-.262-.96H4.412Zm8.123-.644a4.592,4.592,0,0,1-2.184-.553h2.815a.6.6,0,0,1,.564.4A4.592,4.592,0,0,1,12.535,6.836ZM9.384,5.582l0,0A4.591,4.591,0,0,1,8.124.953L8.785,3.38l.03.11h.724a1.835,1.835,0,0,1,.35,1.2H9.14l.243.889Z"
        transform="translate(0 2.353)"
        fill="#707070"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      <path
        id="Subtraction_12"
        data-name="Subtraction 12"
        d="M4.6,9.19a4.595,4.595,0,1,1,4.595-4.6A4.6,4.6,0,0,1,4.6,9.19ZM3.134,4.432c-.009,0-.03.01-.077.058-.1.1-.241.246-.423.439l-.019.019A.169.169,0,0,0,2.6,5a.153.153,0,0,0,.019.058l.029.028L4,6.379a.139.139,0,0,0,.1.048c.02,0,.049-.019.086-.057L6.576,3.315A.153.153,0,0,0,6.6,3.257.153.153,0,0,0,6.576,3.2l-.529-.42A.155.155,0,0,0,5.98,2.76a.079.079,0,0,0-.058.019L4,5.234,3.23,4.489A.158.158,0,0,0,3.134,4.432Z"
        transform="translate(7.94)"
        fill="#35c37d"
      />
    </g>
  </svg>
)

export default MenuOnGrid
