/* eslint-disable fp/no-mutating-methods */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18next from 'i18next'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {PENDING_REQ_KEY, PROFILE_TYPE, STORM_GUARD_STATUS, PAGE_NAME} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {hasEnpower} from 'lib/utils/siteDetails'
import Analytics from 'lib/utils/analytics'
import {findNearestValue} from 'lib/utils/utility'
import {RangeSlider} from '../../atoms/RangeSlider'
import {updateBatterySliceValues} from '../../organisms/BatteryDetails/bdCore'
import {getActiveRequestAlertDetails} from '../../organisms/ProfileDetails/pdCore'
import {
  isAnyRequestPendingForBatteryReserveOrShutdown,
  isBatteryBackupPercentageRequestPending,
} from '../../organisms/reqPending'
import {getBatteryReserveDescription, getRangeErrorText} from '../BatteryReserve/brCore'
import {setBrValue} from '../BatteryReserve/brSlice'
import {getBatteryShutdownValue} from '../BatteryShutdown/bsCore'
import {setBsValue} from '../BatteryShutdown/bsSlice'
import {getFullBackupDetails} from '../FullBackupProfile/fbpCore'
import {getStormAlertDetails, isStormAlertActive} from '../../organisms/StormGuardDetails/sgCore'
import './styles.scss'

/* *********************** *********************** *********************** *
 *  Edit Battery Reserve
 * *********************** *********************** *********************** */

export const EditBatteryReserve = props => {
  const {className, profile, title, renderAscard, fromTutorial} = props
  const batteryProfile = useSelector(batteryProfileSelector)
  const {batteryShutdown} = useSelector(state => state.components)
  const {loading, brReserve, reserveProfile, rangeErrorText} = useSelector(state => state.components.batteryReserve)
  const {details} = batteryProfile
  const brTitle = title || i18next.t('bp55')
  const brValueText = () => (_.isNumber(brReserve) ? `${brReserve}%` : brReserve)
  const brDescription = getBatteryReserveDescription({value: brValueText()})
  const vls = getBatteryShutdownValue(null, details)
  const showHeader = true

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setBrSlice = obj => dispatch(setBrValue(obj))
  const setLoading = val => dispatch(setBrValue({loading: val}))
  const setBsSlice = obj => dispatch(setBsValue(obj))

  // useEffect on mount
  useEffect(() => {
    !fromTutorial && updateBatterySliceValues({source: 'reserve', dispatch, profile, data: details})
    setLoading(isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT))
    setBrSlice({rangeErrorText: null})
  }, [batteryProfile.loading])

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // RangeSlider :: disable
  function disableRangeSlider() {
    const check =
      loading ||
      batteryShutdown.loading ||
      isStormAlertActive(details) ||
      isAnyRequestPendingForBatteryReserveOrShutdown(details)

    if (renderAscard) {
      return check || details?.profile === profile
    }
    return check
  }

  // RangeSlider :: onChange
  const onChangeRangeSlider = (value, index) => {
    const {rangeMax} = brSlider
    const rangeMin = details?.batteryBackupPercentageMin
    const val = _.isArray(value) ? value[index] : value
    if (!disableRangeSlider()) {
      setBrSlice({rangeErrorText: getRangeErrorText(val, details?.veryLowSoc, rangeMin, rangeMax)})
      if (renderAscard) {
        setBrSlice({brReserve: val})
      } else {
        const reserveProfileObj = {
          ...reserveProfile,
          [profile]: val,
        }
        setBrSlice({reserveProfile: reserveProfileObj, brReserve: val})
      }
      setBsSlice({veryLowSoc: details.veryLowSoc})
    }
  }

  const onAfterChangeRangeSlider = (value, index) => {
    const val = _.isArray(value) ? value[index] : value
    reserveSliderGA(findNearestValue(val))
  }

  const reserveSliderGA = val => {
    Analytics.sendClickEvent(
      `${
        profile === PROFILE_TYPE.SELF_CONSUMPTION ? PROFILE_TYPE.SELF_CONSUMPTION : PROFILE_TYPE.COST_SAVINGS
      }_reserve_${val}_${Number(val) + 20}`,
      PAGE_NAME.EDIT_PROFILE
    )
  }

  // RangeSlider :: onClick
  const onClickRangeSlider = () => {
    if (details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE) {
      showDialog(getStormAlertDetails({details, closeThisDialog}))
    } else if (renderAscard && details?.profile === profile) {
      showDialog(getFullBackupDetails({closeThisDialog}))
    } else if (disableRangeSlider()) {
      showDialog(getActiveRequestAlertDetails({details, closeThisDialog}))
    }
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Battery Reserve
  if (!hasEnpower()) {
    return false
  }

  // Range Slider
  const brMin = 0
  const brMax = details?.batteryBackupPercentageMax
  const rangeMin = Math.max(details?.batteryBackupPercentageMin, vls)
  const brValue = brReserve || 10

  const marks = _.range(brMin, brMax + 1, 20)
  const markHasLastValue = _.includes(marks, brMax)
  if (!markHasLastValue) marks.push(brMax)
  const ticks = marks.reduce((a, v) => ({...a, [v]: `${v}%`}), {})

  const brSlider = {
    className: '',
    isDisabled: disableRangeSlider(),
    value: [rangeMin, brValue],
    min: brMin,
    max: brMax,
    rangeMin,
    rangeText: rangeErrorText,
    marks,
    ticks,
    showTrackValue: false,
    onChange: onChangeRangeSlider,
    onClick: onClickRangeSlider,
    onAfterChange: onAfterChangeRangeSlider,
  }

  return (
    // Edit Battery Reserve
    <div className={`edit-battery-reserve ${className}`}>
      {showHeader && (
        <div className="br__title-wrapper">
          <div className="bp__card-view--title">{brTitle}</div>
          <div className="br__value">{brValueText()}</div>
        </div>
      )}

      <div className="br__slider-wrapper">
        <div className="br__slider-icon">
          <Icon src="batteryReserve" value={brValue} />
        </div>
        <div className="br__slider">
          <RangeSlider {...brSlider} />
        </div>
      </div>

      {renderAscard && <div className="bp__card-view--desc">{brDescription}</div>}
    </div>
  )
}

EditBatteryReserve.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.oneOf(_.values(PROFILE_TYPE)),
  title: PropTypes.string,
  renderAscard: PropTypes.bool,
  fromTutorial: PropTypes.bool,
}

EditBatteryReserve.defaultProps = {
  className: null,
  profile: null,
  title: null,
  renderAscard: false,
  fromTutorial: false,
}
