import React from 'react'

const EvCharger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="EV" transform="translate(-29.998 -236)">
      <rect
        id="Rectangle_5223"
        data-name="Rectangle 5223"
        width="24"
        height="24"
        transform="translate(29.998 236)"
        fill="#f37320"
        opacity="0"
      />
      <g id="EV-2" data-name="EV" transform="translate(32.4 238.4)">
        <path
          id="Path_12521"
          data-name="Path 12521"
          d="M4.966,22.248a1.371,1.371,0,1,0,1.371,1.371A1.371,1.371,0,0,0,4.966,22.248ZM2.68,23.619A2.286,2.286,0,1,1,4.966,25.9,2.286,2.286,0,0,1,2.68,23.619Z"
          transform="translate(-0.842 -6.705)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12522"
          data-name="Path 12522"
          d="M16.966,22.26a1.371,1.371,0,1,0,1.371,1.371A1.371,1.371,0,0,0,16.966,22.26ZM14.68,23.632a2.286,2.286,0,1,1,2.286,2.286A2.286,2.286,0,0,1,14.68,23.632Z"
          transform="translate(-4.614 -6.709)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12523"
          data-name="Path 12523"
          d="M12.322,13.333a.457.457,0,0,1,.324.132L15.407,16.2a.457.457,0,1,1-.643.65l-2.627-2.6-5.074.026L4.444,16.858a.457.457,0,0,1-.175.108l-2.634.89-.268.264-.453.444v2.075H2.277a.457.457,0,1,1,0,.914H.457A.457.457,0,0,1,0,21.095V18.37a.457.457,0,0,1,.138-.328c.164-.159.4-.387.588-.576l.342-.336a.46.46,0,0,1,.175-.108l2.634-.89,2.677-2.641a.457.457,0,0,1,.319-.132Z"
          transform="translate(0 -4.19)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12524"
          data-name="Path 12524"
          d="M8.013,24.458A.457.457,0,0,1,8.47,24h4.581a.457.457,0,0,1,0,.914H8.47A.457.457,0,0,1,8.013,24.458Z"
          transform="translate(-2.518 -7.543)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12525"
          data-name="Path 12525"
          d="M5.347,7.124A.457.457,0,0,1,5.8,6.667H7.623a.457.457,0,1,1,0,.914H5.8A.457.457,0,0,1,5.347,7.124Z"
          transform="translate(-1.68 -2.095)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12526"
          data-name="Path 12526"
          d="M5.347,1.791A.457.457,0,0,1,5.8,1.334H7.623a.457.457,0,0,1,0,.914H5.8A.457.457,0,0,1,5.347,1.791Z"
          transform="translate(-1.68 -0.419)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12527"
          data-name="Path 12527"
          d="M8,.457A.457.457,0,0,1,8.457,0H11.2a.457.457,0,0,1,.457.457V5.943A.457.457,0,0,1,11.2,6.4H8.457A.457.457,0,0,1,8,5.943Zm.914.457V5.486h1.829V.914Z"
          transform="translate(-2.514)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12528"
          data-name="Path 12528"
          d="M12,1.791a.457.457,0,0,1,.457-.457h1.829a.457.457,0,0,1,.457.457V5.448a.457.457,0,0,1-.457.457H12.457A.457.457,0,0,1,12,5.448Zm.914.457V4.991h.914V2.248Z"
          transform="translate(-3.771 -0.419)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
        <path
          id="Path_12529"
          data-name="Path 12529"
          d="M14.68,4.444a.457.457,0,0,1,.458-.457L23.357,4a.457.457,0,0,1,.457.457V18.168a.457.457,0,0,1-.457.457H19.251a.457.457,0,0,1,0-.914H22.9V4.91L15.137,4.9A.457.457,0,0,1,14.68,4.444Z"
          transform="translate(-4.614 -1.253)"
          fill="#545456"
          stroke="#545456"
          strokeWidth="0.3"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
)

EvCharger.propTypes = {}

EvCharger.defaultProps = {}

export default EvCharger
