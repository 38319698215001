import React from 'react'

const loaderV3 = () => (
  <svg className="loaderV3" width="40" height="40" viewBox="0 0 40 40">
    <circle fill="none" cx="20" cy="20" r="17.5" opacity="0.5" />
    <circle
      fill="none"
      cx="20"
      cy="20"
      r="17.5"
      stroke="#01B5DE"
      strokeDasharray="80 84"
      strokeWidth="3px"
      strokeLinecap="round">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 20 20"
        to="360 20 20"
        dur="1s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)

export default loaderV3
