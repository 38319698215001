import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18next from 'i18next'
import {batteryProfileSelector, setBpValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {PAGE_NAME} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {showHeader} from 'lib/utils/pageDetails'
import {hasEncharge, isDTSupportedSite} from 'lib/utils/siteDetails'
import {isEnhoWeb, isWeb} from 'lib/utils/utility'
import ShowErrorDetails from '../../atoms/Error/showErrorDetails'
import Header from '../../atoms/Header'
import {getDevices} from '../../atoms/EvTopUpSchedule/evScheduleCore'
import SystemProfileCard from '../../atoms/SystemProfileCard'
import {setBrValue} from '../../molecules/BatteryReserve/brSlice'
import {removeDevice} from '../../molecules/DeviceBehaviour/dbSlice'
import {SelectProfileHelp} from '../../molecules/SelectProfileHelp'
import {getDiscardPopupDetails, isInfoChanged} from '../bpCommon'
import {selectTutorialDetails} from '../hspCommon'
import {getProfileCurrentDetails} from './pdCore'
import {setPdValue} from './pdSlice'
import './styles.scss'

/* *********************** *********************** *********************** *
 * Edit Profile
 * *********************** *********************** *********************** */

const EditProfilePage = props => {
  const {id} = props
  const batteryProfile = useSelector(batteryProfileSelector)
  const {details} = useSelector(batteryProfileSelector)
  const batteryReserve = useSelector(state => state.components.batteryReserve)
  const profileDetails = useSelector(state => state.components.profileDetails)
  const {deviceChanges} = useSelector(state => state.components.deviceUpdate[profileDetails.openCard]) || []
  const devices = getDevices(details)

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const setBrSlice = obj => dispatch(setBrValue({...obj}))
  const setPdSlice = obj => dispatch(setPdValue({...obj}))
  const removeDeviceUpdate = obj => dispatch(removeDevice(obj))

  const spDetails = getProfileCurrentDetails(
    details,
    details.profile,
    profileDetails.inProgress,
    profileDetails.requestType
  )

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const onClickBack = () => {
    const subPageList = [...batteryProfile.subPage]
    if (
      batteryProfile.subPage.includes(PAGE_NAME.EDIT_PROFILE) &&
      isInfoChanged(profileDetails, deviceChanges, details, devices, spDetails)
    ) {
      const obj = getDiscardPopupDetails(
        setBpDetails,
        closeThisDialog,
        deviceChanges,
        devices,
        removeDeviceUpdate,
        profileDetails.openCard
      )
      showDialog(obj)
      return
    }
    _.remove(subPageList, val => val === id)
    setBpDetails({subPage: subPageList})
  }

  // System Profile Tutorial :: onClick header action
  const onClickHeaderAction = () => {
    if (isWeb()) onClickBack()
    else
      selectTutorialDetails({
        showDialog,
        closeThisDialog,
        details,
        setBrSlice,
        batteryReserve,
        setPdSlice,
        screen: 1,
        tutorialObj: {firstVal: 1, secondVal: 2},
      })
  }

  const setActionTitle = () => {
    if (hasEncharge() || isDTSupportedSite()) {
      if (isEnhoWeb()) return <Icon src="close" />
      // if (!batteryProfile.invalidAccess && isSource(SOURCE_TYPE.ITK)) return <Icon src="tutorialHelp" />
      return null
    }
    return null
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    // Edit Profile
    <>
      {showHeader() && (
        <Header
          id={id}
          title={isWeb() ? i18next.t('bp255') : i18next.t('bp202')}
          actionTitle={setActionTitle()}
          onClickAction={onClickHeaderAction}
        />
      )}

      {!(batteryProfile.apiError || batteryProfile.invalidAccess) && (
        <div className="bp__page--wrapper">
          <div className="pd__profile-container">
            <div className="bp__page--with-action">
              <SelectProfileHelp />
              <SystemProfileCard />
            </div>
          </div>
        </div>
      )}

      {batteryProfile.apiError ? (
        <ShowErrorDetails />
      ) : (
        batteryProfile.invalidAccess && <ShowErrorDetails text={i18next.t('bp194')} subText={i18next.t('bp195')} />
      )}
    </>
  )
}

EditProfilePage.propTypes = {
  id: PropTypes.string.isRequired,
}

EditProfilePage.defaultProps = {}

export default EditProfilePage
