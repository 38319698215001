import React from 'react'
import PropTypes from 'prop-types'

export default class EnergyGeneratorToHome extends React.PureComponent {
  render() {
    const {nightMode} = this.props
    const color = nightMode ? '#dca2ff' : '#9062AC'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="212.001" height="12" viewBox="0 0 212.001 12">
        <g id="Grid_to_consumption" data-name="Grid to consumption" transform="translate(7.001 -0.5)">
          <rect
            id="container"
            width="212"
            height="12"
            transform="translate(-7 0.5)"
            fill="rgba(255,83,83,0.11)"
            opacity="0"
          />
          <g id="G_C" data-name="G&gt;C" transform="translate(11 2.5)">
            <path
              id="motion-path-generator-home"
              data-name="arrow body"
              d="M57,1,267,.5"
              transform="translate(-75 3.499)"
              fill="none"
              stroke={color}
              strokeWidth="1"
            />
            <path
              id="Arrow_head"
              data-name="Arrow head"
              d="M4,3.969a.4.4,0,0,1-.089.25L.482,7.91a.258.258,0,0,1-.313.063A.34.34,0,0,1,0,7.67V.331a.323.323,0,0,1,.17-.3A.27.27,0,0,1,.482.081L3.911,3.729A.368.368,0,0,1,4,3.969Z"
              transform="translate(190 0)"
              fill={color}
            />
          </g>
          <g id="pointer-generator-home" transform="translate(-62 0)">
            <circle id="Oval_Copy_12" data-name="Oval Copy 12" cx="6" cy="6" r="6" fill={color} opacity="0.2" />
            <circle
              id="Oval_Copy_13"
              data-name="Oval Copy 13"
              cx="2"
              cy="2"
              r="2"
              transform="translate(4 4)"
              fill={color}
            />
          </g>
        </g>
        <animateMotion xlinkHref="#pointer-generator-home" dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
          <mpath xlinkHref="#motion-path-generator-home" />
        </animateMotion>
      </svg>
    )
  }
}

EnergyGeneratorToHome.propTypes = {nightMode: PropTypes.bool}

EnergyGeneratorToHome.defaultProps = {nightMode: false}
