import React, {useState} from 'react'
import i18next from 'i18next'
import {BATTERY_GRID_MODE} from 'lib/utils/constants'
import {isBatteryGridMode, isNem3Supported} from 'lib/utils/siteDetails'
import Icon from '../../../../utils/icon'
import './styles.scss'

export const BatteryMode = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const IMPORT_ONLY = isBatteryGridMode(BATTERY_GRID_MODE.IMPORT_ONLY)
  const EXPORT_ONLY = isBatteryGridMode(BATTERY_GRID_MODE.EXPORT_ONLY)

  const handleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  // Hide Battery Mode
  if (!isNem3Supported()) {
    return false
  }

  return (
    <div className="bp-battery-mode">
      <div className="bp-battery-mode-container">
        <div className="bp-battery-mode-container-flex">
          <p className="bp-battery-mode-container-title">
            <span> {i18next.t('bp344')}:</span>
            {IMPORT_ONLY ? (
              <span className="value-margin-left"> {i18next.t('bp345')} </span>
            ) : EXPORT_ONLY ? (
              <span className="value-margin-left"> {i18next.t('bp346')} </span>
            ) : null}
          </p>
          <div className="bp-battery-mode-container-icon" onClick={handleAccordion} aria-hidden="true">
            <span className={`arrow ${isExpanded ? 'arrow-up' : 'arrow-down'}`} />
          </div>
        </div>
        <div className="bp-battery-mode-container-description">
          {IMPORT_ONLY ? <p> {i18next.t('bp347')} </p> : EXPORT_ONLY ? <p> {i18next.t('bp354')} </p> : null}
        </div>
        {isExpanded && (
          <div className={isExpanded ? 'height-auto' : 'height-none'}>
            <div className="bp-battery-mode-description">
              <div className="bp-battery-mode-description-container">
                <p className="title">{i18next.t('bp348')}</p>
                <p className="sub-title">
                  {IMPORT_ONLY ? (
                    <>
                      <Icon src="checkIcon" />
                      <span className="margin-left"> {i18next.t('bp349')} </span>
                    </>
                  ) : EXPORT_ONLY ? (
                    <>
                      <Icon src="checkIcon" />
                      <span className="margin-left"> {i18next.t('bp357')}</span>
                    </>
                  ) : null}
                </p>
                <p className="sub-title">
                  {IMPORT_ONLY ? (
                    <>
                      <Icon src="closeIcon" />
                      <span className="margin-left"> {i18next.t('bp350')} </span>
                    </>
                  ) : EXPORT_ONLY ? (
                    <>
                      <Icon src="closeIcon" />
                      <span className="margin-left"> {i18next.t('bp358')} </span>
                    </>
                  ) : null}
                </p>
              </div>
            </div>
            <div className="bp-battery-mode-information">
              <p>{i18next.t('bp351')}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
