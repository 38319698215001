/* eslint-disable fp/no-mutation */
import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'
import _ from 'lodash'

const initialState = {
  loading: false,
  stormAlerts: [],
  criticalAlertActive: false,
  getApiLoading: false,
  // requestType: null,
}

export const saSlice = createSlice({
  name: 'stormAlert',
  initialState,
  reducers: {
    setSaValue: (state, action) => {
      const {payload} = action
      const states = {...state}

      return updateSpecificKeyValue(payload, states)
    },

    setAlertDetails: (state, action) => {
      const {payload} = action
      const {stormAlerts} = state

      _.forEach(stormAlerts, obj => {
        if (obj.id === payload.id) {
          const pKeys = Object.keys(payload)

          if (pKeys?.length === 2 && _.isBoolean(payload.isLoading)) {
            obj.isLoading = payload.isLoading
          } else {
            updateSpecificKeyValue(payload, obj)
          }
        }
      })
    },
  },
})

// Action creators are generated for each case reducer function
export const {setSaValue, setAlertDetails} = saSlice.actions
export const stormAlertSelector = state => state.components.stormAlert
export default saSlice.reducer
