import React from 'react'

const SystemError = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
    <path
      fill="#E3655E"
      fillRule="nonzero"
      d="M6.5 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 10.65c-.508 0-.856-.392-.856-.9 0-.522.363-.9.856-.9.522 0 .856.378.856.9 0 .508-.334.9-.856.9zm.337-3.434c-.13.441-.537.449-.673 0-.157-.518-.713-2.484-.713-3.76 0-1.685 2.107-1.693 2.107 0 0 1.284-.586 3.3-.721 3.76z"
    />
  </svg>
)

export default SystemError
