/* eslint-disable fp/no-mutation */

// Update specific Key Value
export function updateSpecificKeyValue(obj, uObj) {
  Object.keys(obj).forEach(v => {
    if (v) {
      uObj[v] = obj[v]
    }
  })

  return {...uObj}
}
