import React from 'react'
import PropTypes from 'prop-types'

const Tab = ({tabName, activeClass, click, changeIndex}) => (
  <span
    className={activeClass}
    role="button"
    tabIndex={0}
    onClick={() => click(changeIndex)}
    onKeyDown={() => click(changeIndex)}>
    {tabName}
  </span>
)

export default Tab

Tab.propTypes = {
  tabName: PropTypes.string,
  activeClass: PropTypes.string,
  click: PropTypes.func,
  changeIndex: PropTypes.number,
}

Tab.defaultProps = {
  tabName: null,
  activeClass: null,
  click: null,
  changeIndex: null,
}
