import React, {Suspense, lazy, useEffect} from 'react'
import _ from 'lodash'
import LazyFallback from 'lib/component/common/atoms/LazyFallback'
import {PAGE_NAME} from 'lib/utils/constants'
import {getQueryParam} from 'lib/utils/utility'
import HomePage from 'lib/component/sections/HomePage'
import Pages from 'lib'
import {popupFromIframe} from 'lib/common'
import {envDetails} from 'e_modules'
import classes from './App.module.css'
import '../lib/assets/styles/common.scss'

const NotFound = lazy(() => import('lib/error/NotFound'))

export const App = () => {
  useEffect(() => {
    if (!envDetails.isDevServer()) {
      popupFromIframe({displayFooter: true})
    }
  }, [])

  const renderPage = () => {
    const source = getQueryParam('type')

    if (_.isNil(source)) {
      return <HomePage />
    }

    switch (source) {
      case PAGE_NAME.BATTERY:
        return <Pages.Battery />
      case PAGE_NAME.PROFILE:
        return <Pages.Profile />
      case PAGE_NAME.STORM_GUARD:
        return <Pages.StormGuard />
      default:
        return <NotFound />
    }
  }

  return (
    <div className={classes['chandradeo-battery-profile']}>
      <Suspense fallback={<LazyFallback />}>{renderPage()}</Suspense>
    </div>
  )
}
