/* eslint-disable fp/no-mutation */
import React from 'react'

const energyBatteryToGrid = ({className}) => {
  let pointer = 'pointer-battery-grid'
  let motionPath = 'motion-path-battery-grid'

  if (className) {
    pointer = `${pointer}-${className}`
    motionPath = `${motionPath}-${className}`
  }

  return (
    <svg
      id="batery_to_grid"
      data-name="batery to grid"
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="92.002"
      viewBox="0 0 92 92.002">
      <rect id="container" width="92" height="92" fill="rgba(255,83,83,0.11)" opacity="0" />
      <g id={pointer} transform="translate(-3 -4)">
        <ellipse id="Oval_Copy_8" cx="6" cy="6" rx="6" ry="6" fill="#6a7073" opacity="0.2" />
        <ellipse id="Oval_Copy_9" cx="2" cy="2" rx="2" ry="2" transform="translate(4 4)" fill="#6a7073" />
      </g>
      <g id="B_C" transform="translate(0 2.002)">
        <path
          id={motionPath}
          d="M83,90V14A10,10,0,0,0,73,4H0"
          transform="translate(3 0)"
          fill="none"
          stroke="#6a7073"
          strokeWidth="1"
          fillRule="evenodd"
        />
        <path
          id="Arrow_head"
          d="M82,3.967a.4.4,0,0,0,.089.25l3.429,3.691a.258.258,0,0,0,.313.063.34.34,0,0,0,.169-.3V.329a.323.323,0,0,0-.17-.3.27.27,0,0,0-.312.05L82.089,3.727A.368.368,0,0,0,82,3.967Z"
          transform="translate(-82 0)"
          fill="#6a7073"
        />
      </g>
      <animateMotion xlinkHref={`#${pointer}`} dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
        <mpath xlinkHref={`#${motionPath}`} />
      </animateMotion>
    </svg>
  )
}

export default energyBatteryToGrid
