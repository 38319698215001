import React from 'react'
import PropTypes from 'prop-types'
import SVG from '../assets/icons'
import {toKebabCase} from './utility'

class Icon extends React.Component {
  constructor() {
    super()

    this.TYPE = {
      SVG: 'svg',
    }
  }

  renderInlineSvg() {
    const {className, src} = this.props
    const cName = className || src
    const InlineSVG = SVG[src]

    return InlineSVG ? (
      <span className={`bp__icon ${cName !== '' ? `icon-${toKebabCase(cName)}` : ''}`}>
        <InlineSVG {...this.props} />
      </span>
    ) : null
  }

  render() {
    const {className, src, type, width, height} = this.props
    const {TYPE} = this

    return type === TYPE.SVG ? (
      this.renderInlineSvg()
    ) : (
      <image
        className={`icon ${className !== '' ? `icon-${toKebabCase(className)}` : ''}`}
        href={src}
        x="0"
        y="0"
        width={width}
        height={height}
      />
    )
  }
}

Icon.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired, // SVG = It's a name of icon, Others = File Loaction
  type: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

Icon.defaultProps = {
  className: '',
  type: 'svg',
  width: 50,
  height: 50,
}

export default Icon
