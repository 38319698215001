import React from 'react'

const EngInd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
    <g transform="translate(-36 -311)">
      <rect width="63" height="63" rx="10" transform="translate(36 311)" className="background" />
      <g transform="translate(19016 21429)">
        <rect width="44" height="44" transform="translate(-18970 -21108)" fill="#fff" style={{opacity: 0}} />
        <path
          fill="#01b5de"
          style={{stroke: '#01b5df', strokeWidth: '0.3px'}}
          d="M21.837,3.961a.782.782,0,0,0-.7.794V11.01a.782.782,0,1,0,1.564,0V4.755a.782.782,0,0,0-.867-.794ZM11.674,11a.782.782,0,0,0-.464,1.344l2.345,2.345a.782.782,0,1,0,1.1-1.112L12.309,11.23A.782.782,0,0,0,11.674,11Zm20.314,0a.782.782,0,0,0-.452.232l-2.345,2.345a.782.782,0,1,0,1.1,1.112l2.345-2.345A.782.782,0,0,0,31.988,11ZM20.323,14.137a.782.782,0,0,0-.66.525l-3.176,9.381a.782.782,0,0,0,.745,1.038h1.686l-1.6,5.24a.782.782,0,0,0,1.331.757L27.188,21.7a.782.782,0,0,0-.574-1.307H24.427L27.3,15.3a.782.782,0,0,0-.684-1.16H20.4q-.037,0-.073,0Zm.635,1.564H25.27l-2.858,5.082a.782.782,0,0,0,.684,1.173h1.747l-5.021,5.509.892-2.944a.782.782,0,0,0-.745-1H18.319ZM4.65,21.173a.783.783,0,1,0,.073,1.564h6.254a.782.782,0,1,0,0-1.564H4.723Q4.687,21.171,4.65,21.173Zm28.144,0a.783.783,0,0,0,.073,1.564h6.254a.782.782,0,1,0,0-1.564H32.868Q32.831,21.171,32.795,21.173ZM14.007,28.979a.782.782,0,0,0-.452.232L11.21,31.556a.782.782,0,1,0,1.1,1.112l2.345-2.345a.782.782,0,0,0-.647-1.344Zm15.648,0a.782.782,0,0,0-.464,1.344l2.345,2.345a.782.782,0,1,0,1.1-1.112L30.29,29.211a.782.782,0,0,0-.635-.232Zm-7.818,3.127a.782.782,0,0,0-.7.794v6.254a.782.782,0,1,0,1.564,0V32.9a.782.782,0,0,0-.867-.794Z"
          transform="translate(-18969.904 -21107.957)"
        />
      </g>
    </g>
  </svg>
)
export default EngInd
