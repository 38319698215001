/* eslint-disable fp/no-mutation */
import React from 'react'

const energyGridToHomeConnectedLmab = ({className}) => {
  let pointer = 'pointer-grid-home'
  let motionPath = 'motion-path-grid-home'

  if (className) {
    pointer = `${pointer}-${className}`
    motionPath = `${motionPath}-${className}`
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="131.537" height="13" viewBox="0 0 131.537 13">
      <g id="GH" transform="translate(1 -0.5)">
        <g id="line">
          <path
            id={motionPath}
            data-name="Path 20503"
            d="M-9,0H120"
            transform="translate(8 7)"
            fill="none"
            stroke="#6b7073"
            strokeWidth="1"
          />
          <path
            id="Path_20504"
            data-name="Path 20504"
            d="M-12650.872-16320.572a.225.225,0,0,1-.127-.205v-6.441a.23.23,0,0,1,.127-.205.2.2,0,0,1,.226.049l3.024,3.222a.217.217,0,0,1,.062.155.212.212,0,0,1-.062.155l-3.024,3.221a.2.2,0,0,1-.148.065A.2.2,0,0,1-12650.872-16320.572Z"
            transform="translate(12777.598 16330.875)"
            fill="#6b7073"
            stroke="rgba(0,0,0,0)"
            strokeWidth="1"
          />
        </g>
        <g id={pointer} transform="translate(0 0.5)">
          <circle id="Oval_Copy_8" data-name="Oval Copy 8" cx="6.5" cy="6.5" r="6.5" fill="#6b7073" opacity="0.2" />
          <circle
            id="Oval_Copy_9"
            data-name="Oval Copy 9"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(4 3.999)"
            fill="#6b7073"
          />
        </g>
      </g>
      <animateMotion xlinkHref={`#${pointer}`} dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
        <mpath xlinkHref={`#${motionPath}`} />
      </animateMotion>
    </svg>
  )
}

export default energyGridToHomeConnectedLmab
