import React from 'react'

const Error = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.75 28C54.75 42.7736 42.7736 54.75 28 54.75C13.2264 54.75 1.25 42.7736 1.25 28C1.25 13.2264 13.2264 1.25 28 1.25C42.7736 1.25 54.75 13.2264 54.75 28Z"
      stroke="#FFC400"
      strokeWidth="2.5"
    />
    <path
      d="M31.4998 42.4168V38.0884C31.5046 37.8954 31.4332 37.7083 31.3011 37.5675C31.1691 37.4267 30.9869 37.3435 30.794 37.3359H26.419C26.2199 37.3375 26.0294 37.4172 25.8886 37.558C25.7478 37.6988 25.668 37.8893 25.6665 38.0884V42.4168C25.668 42.6159 25.7478 42.8064 25.8886 42.9472C26.0294 43.088 26.2199 43.1677 26.419 43.1693H30.794C30.9869 43.1617 31.1691 43.0785 31.3011 42.9377C31.4332 42.7969 31.5046 42.6098 31.4998 42.4168Z"
      fill="#FFC400"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M31.1351 30.67L31.4991 10.1856C31.5061 10.0781 31.4918 9.97035 31.4569 9.86843C31.4221 9.76651 31.3674 9.6725 31.2961 9.59179C31.2412 9.51323 31.1688 9.44852 31.0845 9.40276C31.0003 9.357 30.9066 9.33145 30.8108 9.32812H26.3541C26.2583 9.33145 26.1646 9.357 26.0804 9.40276C25.9961 9.44852 25.9237 9.51323 25.8688 9.59179C25.7975 9.6725 25.7428 9.76651 25.708 9.86843C25.6731 9.97035 25.6588 10.0781 25.6658 10.1856L26.0158 30.67C25.9973 30.8698 26.0587 31.0688 26.1866 31.2234C26.3146 31.378 26.4985 31.4756 26.6983 31.4948H30.4491C30.6465 31.4694 30.827 31.3703 30.9543 31.2173C31.0816 31.0642 31.1462 30.8687 31.1351 30.67Z"
      fill="#FFC400"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

Error.propTypes = {}

Error.defaultProps = {}

export default Error
