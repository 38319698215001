import React from 'react'
import i18next from 'i18next'
import {TUTORIAL_TYPE} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import './styles.scss'
import Analytics from 'lib/utils/analytics'

export function renderSelectionContent({
  chooseProfile,
  details,
  setBrSlice,
  showDialog,
  batteryReserve,
  closeThisDialog,
  setPdSlice,
  screen,
  tutorialObj,
}) {
  return (
    <div className="crp__selection">
      <div
        className="crp__selection__item"
        role="button"
        tabIndex="0"
        onClick={() => {
          Analytics.sendClickEvent('Learn_Help_Profile', 'Help_Profile')
          chooseProfile({
            val: TUTORIAL_TYPE.CHOOSE_PROFILE,
            details,
            setBrSlice,
            showDialog,
            batteryReserve,
            closeThisDialog,
            setPdSlice,
            screen,
            tutorialObj,
          })
        }}
        onKeyDown={() => {}}>
        <Icon src="chooseRightProfileTutorial" />
        {i18next.t('bp129')}
      </div>

      <div
        className="crp__selection__item"
        role="button"
        tabIndex="0"
        onClick={() => {
          Analytics.sendClickEvent('Suggest_Help_Profile', 'Help_Profile')
          chooseProfile({
            val: TUTORIAL_TYPE.SUGGEST_PROFILE,
            details,
            setBrSlice,
            showDialog,
            batteryReserve,
            closeThisDialog,
            setPdSlice,
            screen,
            tutorialObj,
          })
        }}
        onKeyDown={() => {}}>
        <Icon src="findRightProfileTutorial" />
        {i18next.t('bp159')}
      </div>
    </div>
  )
}
