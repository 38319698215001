import React from 'react'

const ToastSuccess = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="radio_button" data-name="radio button" transform="matrix(-1, -0.017, 0.017, -1, 19.041, 19.372)">
      <g
        id="Rectangle"
        transform="matrix(-1, 0.017, -0.017, -1, 19.376, 19.037)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2">
        <rect width="20" height="20" rx="10" stroke="none" />
        <rect x="1" y="1" width="18" height="18" rx="9" fill="none" />
      </g>
      <path
        id="Rectangle-2"
        data-name="Rectangle"
        d="M9.716,1.68l-.646.646L6.245,5.151q-.888.885-1.771,1.771a1,1,0,0,1-1.4,0q-.175-.175-.345-.347L.282,4.119a1,1,0,0,1,0-1.4,1,1,0,0,1,1.4,0q.175.175.345.347c.584.584,1.169,1.172,1.752,1.757L6.549,2.054,8.32.282a1,1,0,0,1,1.4,0,1,1,0,0,1,0,1.4Z"
        transform="translate(14.255 12.125) rotate(179)"
        fill="#fff"
      />
    </g>
  </svg>
)

ToastSuccess.propTypes = {}

ToastSuccess.defaultProps = {}

export default ToastSuccess
