/* eslint-disable fp/no-mutation */
import e from 'e_modules'
import i18next from 'i18next'
import envDetails from './utils/envDetails'
import {TOAST_TYPE} from './utils/constants'

function postToParent(obj) {
  const {isDevServer, environments} = e.envDetails
  let origin = environments.dev

  if (!isDevServer()) {
    origin = envDetails.getOrigin() || window.location.origin
  }

  parent.postMessage(obj, origin)
}

// Navigate to Electricity Rate
export function navigateToElectricityRate() {
  postToParent({id: 'navigateToElectricityRate'})
}

// Navigate to Grid Services
export function navigateToGridServices() {
  postToParent({id: 'navigateToGridServices'})
}

// Navigate to Schedule
export function navigateToSchedule(data) {
  postToParent({id: 'navigateToIqevseSchedule', ...data})
}

// Navigate to Third Party EVSE Schedule
export function navigateToThirdPartyEvseSchedule(data) {
  postToParent({id: 'navigateToThirdPartyEvseSchedule', ...data})
}

// Navigate to Envoy/Gateway Device
export function navigateToEnvoyDevice(data) {
  postToParent({id: 'navigateToEnvoyDevice', ...data})
}

// Open Url from Iframe
export function openUrlFromIframe(data) {
  postToParent({id: 'openUrlFromIframe', ...data})
}

// Open popup at bottom from Iframe
export function popupFromIframe(data) {
  postToParent({id: 'popupFromIframe', ...data})
}

// Handle Error
export function handleError({error, showToast}) {
  const err = error.error || error

  if (err.response.status === 409) {
    const message = err.response.data.error.message || err.response.data.message

    showToast({
      type: TOAST_TYPE.INFO,
      message,
      autoClose: 3000,
    })
  } else {
    showToast({
      type: TOAST_TYPE.ERROR,
      message: i18next.t('bp68'),
      autoClose: 3000,
    })
  }
}

// Navigate back to settings Menu
export function navigateBack() {
  postToParent({id: 'navigateBack'})
}

// Close web popup
export function handleCloseWeb(data) {
  postToParent({id: 'handleCloseWeb', ...data})
}

// contact support
export function contactSupport(data = {redirectFrom: null}) {
  postToParent({id: 'contactSupport', ...data})
}
