import React from 'react'
import classNames from 'classnames'
import {showStormGuard} from 'lib/utils/siteDetails'
import {StormGuard} from '../../molecules/StormGuard'
import {hasStormGuardPage} from './sgCore'
import './styles.scss'

/* *********************** *********************** *********************** *
 *  Storm Guard Details
 * *********************** *********************** *********************** */

export const StormGuardDetails = () => {
  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  // Hide Storm Guard Details
  if (!showStormGuard()) {
    return null
  }

  return (
    // Storm Guard Details
    <div id="bp-storm-guard-details" className={classNames({'bp-storm-guard__has-page': hasStormGuardPage()})}>
      <StormGuard showArrow={hasStormGuardPage()} />
    </div>
  )
}

StormGuardDetails.propTypes = {}

StormGuardDetails.defaultProps = {}
