/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */

import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import classNames from 'classnames'
import Switch from 'react-switch'
import {TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import {isDarkTheme} from 'lib/hook/useTheme'
import Icon from '../../../../utils/icon'
import './styles.scss'

/*
  --- --- --- --- --- ---
  How to use <ToggleSwitch />
  --- --- --- --- --- ---
  <ToggleSwitch
    key="String"
    id="String"
    className="String"
    status="on|off|loading"
    label="String"
    disabled={true|false}
    handleChange={handleToggleChange}
  />
*/

const bgColorOnDark = '#535353'
const bgColorOffDark = '#535353'

// Toggle Switch :: Get Toggle Status Details
function getToggleStatus(obj) {
  const {loading, status, loadingText, onText, offText, loadingOnText, loadingOffText, onTextClass, offTextClass} = obj

  switch (status) {
    case TOGGLE_SWITCH_STATUS.LOADING:
      return [loadingText || i18next.t('bp93')]
    case TOGGLE_SWITCH_STATUS.ON:
      if (loading) {
        return [loadingOnText || i18next.t('bp89'), 'bp__status-text--enabled']
      }
      return [onText || i18next.t('bp7'), onTextClass || 'bp__status-text--enabled']
    case TOGGLE_SWITCH_STATUS.OFF:
      if (loading) {
        return [loadingOffText || i18next.t('bp90'), 'bp__status-text--disabled']
      }
      return [offText || i18next.t('bp8'), offTextClass || 'bp__status-text--disabled']
    default:
      return null
  }
}

export class ToggleSwitch extends React.Component {
  constructor() {
    super()

    this.loadingClassName = 'bp_ts_loading-icon'
    this.handleToggleChange = this.handleToggleChange.bind(this)
  }

  handleToggleChange(checked) {
    const {handleChange} = this.props
    const value = checked ? TOGGLE_SWITCH_STATUS.ON : TOGGLE_SWITCH_STATUS.OFF

    handleChange(value, {...this.props})
  }

  getClassNames() {
    const {className, disabled, status} = this.props
    const classes = [className]

    if (status) {
      classes.push(`bp_ts_${status}`)
    }

    if (disabled) {
      classes.push('bp_ts_disabled')
    }

    return classes.join(' ')
  }

  renderSwitch() {
    const {id, height, width, bgColorOn, bgColorOff, handleColorOn, handleColorOff, handleDiameter, status, disabled} =
      this.props
    const checked = status === TOGGLE_SWITCH_STATUS.ON

    return (
      <Switch
        id={`material-switch_${id}`}
        className="bp_ts_handler"
        height={height}
        width={width}
        onColor={isDarkTheme() ? bgColorOnDark : bgColorOn}
        offColor={isDarkTheme() ? bgColorOffDark : bgColorOff}
        onHandleColor={handleColorOn}
        offHandleColor={handleColorOff || '#cbcbcb'}
        handleDiameter={handleDiameter}
        boxShadow="0px 0px 0px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.2)"
        checked={checked}
        onChange={this.handleToggleChange}
        uncheckedIcon={false}
        checkedIcon={false}
        disabled={disabled}
      />
    )
  }

  renderLoading() {
    return (
      <div className={this.loadingClassName}>
        <Icon src="loaderV1" />
      </div>
    )
  }

  renderLabel() {
    const {label} = this.props

    if (!label || label === '') {
      return null
    }

    return <div className="bp_ts_label">{label}</div>
  }

  render() {
    const {id, status} = this.props
    const className = this.getClassNames()
    const ariaId = `bp-ts-${id}`

    return (
      <div id={ariaId} aria-labelledby={ariaId} className={`bp_toggle-switch ${className}`}>
        <div className="bp_ts_wrapper">
          {status && status !== TOGGLE_SWITCH_STATUS.LOADING ? this.renderSwitch() : this.renderLoading()}
          {this.renderLabel()}
        </div>
      </div>
    )
  }
}

export function toggleStatusInfo({
  loading,
  status,
  loadingText,
  onText,
  offText,
  loadingOnText,
  loadingOffText,
  onTextClass,
  offTextClass,
}) {
  const sgStatusDetails = getToggleStatus({
    loading,
    status,
    loadingText,
    onText,
    offText,
    loadingOnText,
    loadingOffText,
    onTextClass,
    offTextClass,
  })
  let sgStatus = null
  let sgStatusClass = null

  if (sgStatusDetails) {
    ;[sgStatus, sgStatusClass] = sgStatusDetails
  }

  return (
    <div className="ts__status-info">
      {status === TOGGLE_SWITCH_STATUS.LOADING && (
        <div className="ts__status-icon">
          <Icon src="clockCircular" />
        </div>
      )}

      {sgStatus && <div className={classNames('ts__status-text', sgStatusClass)}>{sgStatus}</div>}
    </div>
  )
}

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  bgColorOn: PropTypes.string,
  bgColorOff: PropTypes.string,
  handleColorOn: PropTypes.string,
  handleColorOff: PropTypes.string,
  handleDiameter: PropTypes.number,
  status: PropTypes.oneOf([TOGGLE_SWITCH_STATUS.LOADING, TOGGLE_SWITCH_STATUS.ON, TOGGLE_SWITCH_STATUS.OFF]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
}

ToggleSwitch.defaultProps = {
  id: '',
  className: '',
  height: 14,
  width: 34,
  bgColorOn: '#e3e3e6',
  bgColorOff: '#e3e3e6',
  handleColorOn: '#01B5DE',
  handleColorOff: '#cbcbcb',
  handleDiameter: 20,
  status: TOGGLE_SWITCH_STATUS.LOADING,
  label: null,
  disabled: false,
  handleChange: null,
}
