import {getSiteSettingsDetails} from 'lib/store'

export const FEATURE_KEYS = {
  HEMS_BATTERY_PROFILE: '734ed62b3f489c98',
  CHOOSE_RIGHT_PROFILE: '671b7d2591d1adc1',
  LEARN_ABOUT_PROFILE: '66f7a67f71be52f7',
  SAVINGS_MODE_CUSTOM_PEAK_SCHEDULE: '714ed62b9f489c98',
  SAVINGS_MODE_ENERGY_INDEPENDENCE: '53e7663e493f1548',
  OJAS: '41653b2db237546a',
  CFG_NEW_LOGIC: '98daf27c08a25a0a',
  EV_CHARGING: 'b707fae2750a4965',
  HEMS_EV_CUSTOM_SCHEDULE: 'HEMS_EV_Custom_Schedule',
  STORM_GUARD_OPTOUT: '534ed33b3f489c98',
  STORM_GUARD_EVSE_OPTOUT: '554ed34b3f489c08',
  SAVINGS_AI_POWERED_BETA: 'ae9fc99447fa52a0',
}

export function isFeatureSupported(featureId) {
  const data = getSiteSettingsDetails().featureDetails
  return data ? data[featureId] : false
}
