import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'

const initialState = {
  loading: false,
  requestType: null,

  brReserve: null,
  reserveProfile: {},
  rangeErrorText: null,
}

export const brSlice = createSlice({
  name: 'batteryReserve',
  initialState,
  reducers: {
    setBrValue: (state, action) => {
      const {payload} = action
      const states = {...state}
      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setBrValue} = brSlice.actions
export default brSlice.reducer
