import moment from 'moment-timezone'

export function minutesToString(min) {
  return moment.utc(min * 60 * 1000).format('h:mm a')
}
export function stringToMinutes(string) {
  return moment(string, 'h:mm a').diff(moment().startOf('day'), 'minutes')
}

export function minutesToUTCDate(min) {
  return moment.utc(min * 60 * 1000)
}

export function addMinuteToDate(date, min) {
  return moment(date).add(min, 'minutes')
}
