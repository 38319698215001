import React from 'react'

const TutorialVideoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_24676_406511)">
      <path
        d="M19.7962 2.66705H4.20687C2.9911 2.66705 2.00195 3.65617 2.00195 4.87197V13.7949C2.00195 15.0107 2.99107 15.9998 4.20687 15.9998H19.7962C21.012 15.9998 22.0011 15.0107 22.0011 13.7949L22.002 4.87197C22.002 3.6562 21.0128 2.66705 19.797 2.66705H19.7962ZM20.6678 13.7949C20.6678 14.2757 20.277 14.6665 19.7962 14.6665H4.20687C3.72606 14.6665 3.33525 14.2757 3.33525 13.7949V4.87197C3.33525 4.39116 3.72606 4.00035 4.20687 4.00035H19.7962C20.277 4.00035 20.6678 4.39116 20.6678 4.87197V13.7949Z"
        fill="#01B5DE"
      />
      <path
        d="M15.688 8.76856L10.3549 5.43537C10.1491 5.30621 9.88992 5.29954 9.67826 5.41787C9.4666 5.53536 9.33493 5.7587 9.33493 6.00118V12.6676C9.33493 12.9101 9.46659 13.1326 9.67826 13.2509C9.77909 13.3067 9.88991 13.3342 10.0016 13.3342C10.1241 13.3342 10.2466 13.3 10.3549 13.2326L15.688 9.89936C15.883 9.7777 16.0013 9.56437 16.0013 9.33439C16.0013 9.1044 15.883 8.89108 15.688 8.76942L15.688 8.76856ZM10.6683 11.4643V7.20276L14.0766 9.33351L10.6683 11.4643Z"
        fill="#01B5DE"
      />
      <path
        d="M21.3345 18.6664H18.5455C18.2697 17.8923 17.5364 17.3331 16.6681 17.3331C15.7998 17.3331 15.0665 17.8923 14.7906 18.6664H2.66868C2.30036 18.6664 2.00204 18.9647 2.00204 19.333C2.00204 19.7014 2.30036 19.9997 2.66868 19.9997H14.7906C15.0665 20.7738 15.7998 21.3329 16.6681 21.3329C17.5364 21.3329 18.2697 20.7738 18.5455 19.9997H21.3345C21.7028 19.9997 22.0011 19.7014 22.0011 19.333C22.0011 18.9647 21.7028 18.6664 21.3345 18.6664ZM16.668 19.9997C16.3005 19.9997 16.0014 19.7005 16.0014 19.333C16.0014 18.9655 16.3005 18.6664 16.668 18.6664C17.0355 18.6664 17.3347 18.9655 17.3347 19.333C17.3347 19.7005 17.0355 19.9997 16.668 19.9997Z"
        fill="#01B5DE"
      />
    </g>
    <defs>
      <clipPath id="clip0_24676_406511">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

TutorialVideoIcon.propTypes = {}

TutorialVideoIcon.defaultProps = {}

export default TutorialVideoIcon
