/* eslint-disable fp/no-mutation */
import React from 'react'

const energySolarToBatteryLmab = ({className}) => {
  let pointer = 'pointer-solar-battery'
  let motionPath = 'motion-path-solar-battery'

  if (className) {
    pointer = `${pointer}-${className}`
    motionPath = `${motionPath}-${className}`
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="81.535" viewBox="0 0 13 81.535">
      <g id="SB" transform="translate(0 0.129)">
        <g id="line" transform="translate(3.441)">
          <path
            id="Path_20494"
            data-name="Path 20494"
            d="M3.441,3.441A.228.228,0,0,0,3.6,3.38L6.817.356A.2.2,0,0,0,6.865.129.223.223,0,0,0,6.66,0H.222A.223.223,0,0,0,.017.129.2.2,0,0,0,.065.356L3.284,3.38A.228.228,0,0,0,3.441,3.441Z"
            transform="translate(-0.382 77.965)"
            fill="#01b5de"
          />
          <path
            id={motionPath}
            data-name="Path 20499"
            d="M-11739.376-16250.6v79.907"
            transform="translate(11742.436 16250.475)"
            fill="none"
            stroke="#01b5de"
            strokeWidth="1"
          />
        </g>
        <g id={pointer} transform="translate(11739.3 16245)">
          <circle
            id="Oval_Copy_8"
            data-name="Oval Copy 8"
            cx="6.5"
            cy="6.5"
            r="6.5"
            transform="translate(0 0.453)"
            fill="#01b5de"
            opacity="0.2"
          />
          <circle
            id="Oval_Copy_9"
            data-name="Oval Copy 9"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(4 4.452)"
            fill="#01b5de"
          />
        </g>
      </g>
      <animateMotion xlinkHref={`#${pointer}`} dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
        <mpath xlinkHref={`#${motionPath}`} />
      </animateMotion>
    </svg>
  )
}

export default energySolarToBatteryLmab
