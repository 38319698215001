import React from 'react'

const Alert = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50.3" height="50.3" viewBox="0 0 50.3 50.3">
    <g id="error" transform="translate(1.15 1.15)">
      <path
        id="_copy"
        data-name=" copy"
        d="M24,0A24,24,0,1,1,0,24,24.006,24.006,0,0,1,24,0Z"
        fill="none"
        stroke="#f7ba00"
        strokeWidth="2.3"
      />
      <path
        id="Path"
        d="M5,4.355V.645A.63.63,0,0,0,4.395,0H.645A.65.65,0,0,0,0,.645V4.355A.65.65,0,0,0,.645,5h3.75A.63.63,0,0,0,5,4.355Z"
        transform="translate(22 32)"
        fill="#f7ba00"
        stroke="none"
        strokeWidth="2"
      />
      <path
        id="Path-2"
        data-name="Path"
        d="M4.688,18.293,5,.735A.7.7,0,0,0,4.826.226.53.53,0,0,0,4.41,0H.59A.53.53,0,0,0,.174.226.7.7,0,0,0,0,.735L.3,18.293A.65.65,0,0,0,.885,19H4.1A.675.675,0,0,0,4.688,18.293Z"
        transform="translate(22 8)"
        fill="#f7ba00"
        stroke="none"
        strokeWidth="2"
      />
    </g>
  </svg>
)

Alert.propTypes = {}

Alert.defaultProps = {}

export default Alert
