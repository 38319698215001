import React from 'react'

const PrfAssistant = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="134.139" viewBox="0 0 180 134.139">
    <g transform="translate(0 -54.953)">
      <g transform="translate(0 54.953)">
        <rect className="a" width="180" height="0.09" transform="translate(0 117.88)" />
        <rect className="a" width="7.635" height="0.09" transform="translate(18.885 121.309)" />
        <rect className="a" width="30.59" height="0.09" transform="translate(61.61 120.332)" />
        <rect className="a" width="13.719" height="0.09" transform="translate(24.36 124.652)" />
        <rect className="a" width="15.55" height="0.09" transform="translate(146.415 124.047)" />
        <rect className="a" width="4.31" height="0.09" transform="translate(140.93 124.047)" />
        <rect className="a" width="8.28" height="0.09" transform="translate(125.24 122.527)" />
        <rect className="a" width="17.44" height="0.09" transform="translate(103.64 122.527)" />
        <path
          className="a"
          d="M109.778,156.778H40.262a2.057,2.057,0,0,1-2.055-2.055V57.008a2.057,2.057,0,0,1,2.055-2.055h69.516a2.057,2.057,0,0,1,2.055,2.055v97.716A2.057,2.057,0,0,1,109.778,156.778ZM40.262,55.043A1.967,1.967,0,0,0,38.3,57.008v97.716a1.967,1.967,0,0,0,1.965,1.965h69.516a1.967,1.967,0,0,0,1.965-1.965V57.008a1.967,1.967,0,0,0-1.965-1.965Z"
          transform="translate(-24.452 -54.953)"
        />
        <path
          className="a"
          d="M326.075,156.778H256.56a2.057,2.057,0,0,1-2.055-2.055V57.008a2.057,2.057,0,0,1,2.055-2.055h69.515a2.057,2.057,0,0,1,2.055,2.055v97.716A2.057,2.057,0,0,1,326.075,156.778ZM256.56,55.043a1.967,1.967,0,0,0-1.965,1.965v97.716a1.967,1.967,0,0,0,1.965,1.965h69.515a1.967,1.967,0,0,0,1.965-1.965V57.008a1.967,1.967,0,0,0-1.965-1.965Z"
          transform="translate(-162.883 -54.953)"
        />
        <g transform="translate(26.363 7.874)">
          <g transform="translate(0 66.755)">
            <g transform="translate(0.648 0.584)">
              <rect className="b" width="5.543" height="42.667" transform="translate(5.543 42.667) rotate(180)" />
              <rect className="c" width="48.523" height="42.667" transform="translate(5.543 0)" />
              <rect className="d" width="42.603" height="22.82" transform="translate(11.906 16.101)" />
              <rect className="d" width="42.603" height="7.513" transform="translate(11.906 6.167)" />
              <rect className="e" width="0.38" height="22.82" transform="translate(11.906 38.921) rotate(180)" />
              <rect className="e" width="0.38" height="7.513" transform="translate(11.906 13.679) rotate(180)" />
              <rect className="e" width="54.189" height="0.965" transform="translate(54.189 2.765) rotate(180)" />
            </g>
            <rect className="b" width="6.191" height="2.79" transform="translate(6.191 2.79) rotate(180)" />
            <rect className="c" width="49.171" height="2.79" transform="translate(6.191)" />
          </g>
          <g transform="translate(6.506 44.583)">
            <g transform="translate(0 0)">
              <path
                className="f"
                d="M129.89,222.505a2.928,2.928,0,0,1-.523-1.5,4.794,4.794,0,0,1,.14-1.62,7.517,7.517,0,0,1,1.478-2.845,12.989,12.989,0,0,1,1.078-1.157c.2-.169.4-.341.6-.505a6.313,6.313,0,0,1,.619-.475,14.939,14.939,0,0,1,1.32-.819c.467-.228.928-.458,1.416-.639-.34.4-.7.756-1.065,1.111s-.7.724-1.061,1.065-.675.721-1.023,1.066c-.167.179-.319.368-.478.55a6.574,6.574,0,0,0-.468.553,11.063,11.063,0,0,0-1.523,2.4,6.26,6.26,0,0,0-.445,1.346A4.083,4.083,0,0,0,129.89,222.505Z"
                transform="translate(-115.653 -208.524)"
              />
              <path
                className="f"
                d="M97.125,221.488a3.956,3.956,0,0,0,.235-1.336,4.068,4.068,0,0,0-.048-.63,4.629,4.629,0,0,0-.167-.591,5.581,5.581,0,0,0-1.436-2c-.152-.151-.332-.28-.5-.423a4.831,4.831,0,0,0-.512-.415c-.369-.251-.7-.55-1.1-.785q-.285-.187-.567-.385c-.187-.135-.376-.269-.575-.388-.389-.257-.782-.512-1.162-.82a13.174,13.174,0,0,1,1.407.286,4.7,4.7,0,0,1,.683.219q.336.127.67.265l.334.14c.109.052.213.116.32.173.213.116.423.241.634.364a4.765,4.765,0,0,1,.6.425c.192.158.394.3.576.474a4.88,4.88,0,0,1,1.525,2.65,1.677,1.677,0,0,1,.037.395c0,.133.013.264,0,.394a3.381,3.381,0,0,1-.16.761A2.731,2.731,0,0,1,97.125,221.488Z"
                transform="translate(-91.302 -209.016)"
              />
              <path
                className="f"
                d="M100.22,217.855a8.254,8.254,0,0,0,.068-2.9,7.711,7.711,0,0,0-.91-2.586l-.359-.594c-.133-.191-.283-.372-.42-.563l-.207-.287-.24-.264c-.16-.176-.317-.357-.474-.541-.338-.343-.693-.68-1.038-1.04-.37-.33-.746-.661-1.1-1.044a14.834,14.834,0,0,1,1.44.562,14.706,14.706,0,0,1,1.326.818c.2.166.4.334.6.508l.3.261.265.3c.175.2.351.4.518.613l.432.685a6.139,6.139,0,0,1,.8,3.12A4.886,4.886,0,0,1,100.22,217.855Z"
                transform="translate(-94.012 -205.382)"
              />
              <path
                className="f"
                d="M123.36,213.671a14.059,14.059,0,0,1-1.812-2.921,18.948,18.948,0,0,1-1.184-3.234,16.075,16.075,0,0,1-.5-3.429,9.74,9.74,0,0,1,.523-3.42c.1,1.153.226,2.242.414,3.329.207,1.083.45,2.151.72,3.22s.586,2.129.9,3.2S123.063,212.558,123.36,213.671Z"
                transform="translate(-109.583 -200.667)"
              />
              <path
                className="f"
                d="M118.714,213.6a16.266,16.266,0,0,1-2.281-2.728,22.061,22.061,0,0,1-1.754-3.095A18.541,18.541,0,0,1,113.5,204.4a10.894,10.894,0,0,1-.257-3.568c.362,1.141.736,2.22,1.157,3.288s.893,2.109,1.366,3.155.98,2.08,1.479,3.127S118.247,212.5,118.714,213.6Z"
                transform="translate(-105.311 -200.773)"
              />
              <path
                className="f"
                d="M118.056,220.149a16.27,16.27,0,0,1-2.833-2.15,22.068,22.068,0,0,1-2.4-2.625,18.539,18.539,0,0,1-1.9-3.028,10.883,10.883,0,0,1-1.048-3.42c.608,1.031,1.214,2,1.862,2.947s1.342,1.857,2.036,2.771,1.42,1.809,2.14,2.718S117.355,219.183,118.056,220.149Z"
                transform="translate(-103.188 -205.953)"
              />
              <path
                className="f"
                d="M113.191,217.441a12.439,12.439,0,0,1-2.722-1.844,16.766,16.766,0,0,1-2.254-2.4,14.262,14.262,0,0,1-1.7-2.845,9.549,9.549,0,0,1-.806-3.2c.551.962,1.053,1.88,1.635,2.759s1.178,1.732,1.818,2.564,1.3,1.653,1.974,2.472S112.5,216.579,113.191,217.441Z"
                transform="translate(-100.525 -204.814)"
              />
              <path
                className="f"
                d="M128.642,221.719a3.606,3.606,0,0,1-1.483-2.472,5.186,5.186,0,0,1,.469-2.939l.15-.339.184-.317.386-.62q.221-.287.454-.561a5.4,5.4,0,0,1,.478-.533,12.332,12.332,0,0,1,1.075-.928,12.736,12.736,0,0,1,1.2-.746,13.855,13.855,0,0,1-.782,1.158c-.266.371-.5.756-.767,1.114-.247.37-.466.751-.706,1.113l-.3.571-.156.281-.121.293a6.292,6.292,0,0,0-.627,2.394A5.217,5.217,0,0,0,128.642,221.719Z"
                transform="translate(-114.217 -208.088)"
              />
              <path
                className="f"
                d="M126.382,217.936a5.759,5.759,0,0,1-1.525-2.563,5.69,5.69,0,0,1-.044-3.1,5.286,5.286,0,0,1,.632-1.446,5.646,5.646,0,0,1,.985-1.2,6.487,6.487,0,0,1,2.583-1.484,19.468,19.468,0,0,0-1.881,2.111,7.468,7.468,0,0,0-.7,1.128,5.843,5.843,0,0,0-.466,1.2,7.062,7.062,0,0,0-.19,2.6A13.375,13.375,0,0,0,126.382,217.936Z"
                transform="translate(-112.625 -205.455)"
              />
              <path className="f" d="M114.615,223.356l-5.632-5.407.226,5.3Z" transform="translate(-102.618 -211.727)" />
            </g>
            <g transform="translate(3.188 11.054)">
              <path
                className="c"
                d="M115.124,231.374H100.158l1.96,11.118h11.046Z"
                transform="translate(-100.158 -231.374)"
              />
              <path
                className="a"
                d="M105.774,231.374h-5.616l1.96,11.118h2.95Z"
                transform="translate(-100.158 -231.374)"
              />
            </g>
          </g>
          <g transform="translate(9.976)">
            <rect className="b" width="30.209" height="37.973" />
            <rect className="c" width="35.72" height="37.973" transform="translate(1.157)" />
            <rect className="g" width="31.988" height="29.735" transform="translate(4.15 34.981) rotate(-90)" />
          </g>
          <g transform="translate(80.363 34.027)">
            <path
              className="a"
              d="M346.492,247.325H300.538l-4.078-75.979h45.954Z"
              transform="translate(-296.46 -171.346)"
            />
            <path
              className="c"
              d="M350.392,247.325H304.439l-4.078-75.979h45.954Z"
              transform="translate(-298.956 -171.346)"
            />
            <g transform="translate(13.43 7.838)">
              <path className="g" d="M368.021,253.421h-4.1l.167-60.3h4.1Z" transform="translate(-353.063 -193.117)" />
              <path className="g" d="M398.175,253.421h-4.1l.167-60.3h4.1Z" transform="translate(-372.361 -193.117)" />
              <path className="g" d="M337.868,253.421h-4.1l.167-60.3h4.1Z" transform="translate(-333.765 -193.117)" />
            </g>
          </g>
        </g>
      </g>
      <g transform="translate(20.2 180.939)">
        <ellipse className="c" cx="69.8" cy="4.076" rx="69.8" ry="4.076" />
      </g>
      <g transform="translate(85.179 67.21)">
        <rect
          className="h"
          width="8.489"
          height="0.36"
          transform="matrix(0.055, -0.999, 0.999, 0.055, 53.59, 82.303)"
        />
        <rect
          className="h"
          width="4.373"
          height="0.36"
          transform="matrix(0.055, -0.999, 0.999, 0.055, 54.21, 71.005)"
        />
        <path
          className="i"
          d="M283.478,187.467h-42.2a4.633,4.633,0,0,1-4.662-4.932l4.861-88.6A5.29,5.29,0,0,1,246.68,89h42.2a4.633,4.633,0,0,1,4.662,4.932l-4.861,88.6A5.29,5.29,0,0,1,283.478,187.467Z"
          transform="translate(-236.608 -89)"
        />
        <path
          className="j"
          d="M280.927,187.467H241.278a4.633,4.633,0,0,1-4.662-4.932l4.861-88.6A5.29,5.29,0,0,1,246.68,89h39.649a4.633,4.633,0,0,1,4.662,4.932l-4.861,88.6A5.29,5.29,0,0,1,280.927,187.467Z"
          transform="translate(-236.608 -89)"
        />
        <path
          className="g"
          d="M281.385,126.212H270a1.957,1.957,0,0,1-1.968-2.083h0a2.234,2.234,0,0,1,2.2-2.083h11.385a1.956,1.956,0,0,1,1.968,2.083h0A2.233,2.233,0,0,1,281.385,126.212Z"
          transform="translate(-256.717 -110.149)"
        />
        <path
          className="i"
          d="M297.894,121.167a4.008,4.008,0,0,1-3.955,3.749,3.512,3.512,0,0,1-3.543-3.749,4.008,4.008,0,0,1,3.955-3.749A3.511,3.511,0,0,1,297.894,121.167Z"
          transform="translate(-271.029 -107.188)"
        />
        <path
          className="g"
          d="M279.354,163.221H267.969A1.956,1.956,0,0,1,266,161.139h0a2.233,2.233,0,0,1,2.2-2.083h11.386a1.956,1.956,0,0,1,1.968,2.083h0A2.234,2.234,0,0,1,279.354,163.221Z"
          transform="translate(-255.417 -133.836)"
        />
        <g transform="translate(12.05 22.002)">
          <ellipse
            className="h"
            cx="3.853"
            cy="3.647"
            rx="3.853"
            ry="3.647"
            transform="translate(0 5.374) rotate(-44.214)"
          />
          <ellipse
            className="k"
            cx="3.853"
            cy="3.647"
            rx="3.853"
            ry="3.647"
            transform="translate(0 5.374) rotate(-44.214)"
          />
        </g>
        <path
          className="g"
          d="M263.908,237.241h11.385a2.234,2.234,0,0,0,2.2-2.083h0a1.956,1.956,0,0,0-1.969-2.083H264.136a2.233,2.233,0,0,0-2.2,2.083h0A1.956,1.956,0,0,0,263.908,237.241Z"
          transform="translate(-252.818 -181.209)"
        />
        <g transform="translate(7.565 48.649)">
          <ellipse
            className="h"
            cx="3.853"
            cy="3.647"
            rx="3.853"
            ry="3.647"
            transform="translate(0 5.374) rotate(-44.212)"
          />
          <ellipse
            className="l"
            cx="3.853"
            cy="3.647"
            rx="3.853"
            ry="3.647"
            transform="translate(0 5.374) rotate(-44.212)"
          />
        </g>
        <path
          className="g"
          d="M273.263,274.251H261.877a1.957,1.957,0,0,1-1.969-2.083h0a2.234,2.234,0,0,1,2.2-2.083h11.386a1.957,1.957,0,0,1,1.968,2.083h0A2.234,2.234,0,0,1,273.263,274.251Z"
          transform="translate(-251.518 -204.894)"
        />
        <path
          className="i"
          d="M289.772,269.206a4.009,4.009,0,0,1-3.955,3.749,3.512,3.512,0,0,1-3.543-3.749,4.009,4.009,0,0,1,3.955-3.749A3.512,3.512,0,0,1,289.772,269.206Z"
          transform="translate(-265.831 -201.933)"
        />
        <path
          className="g"
          d="M265.938,200.231h11.386a2.234,2.234,0,0,0,2.2-2.083h0a1.956,1.956,0,0,0-1.969-2.083H266.167a2.234,2.234,0,0,0-2.2,2.083h0A1.956,1.956,0,0,0,265.938,200.231Z"
          transform="translate(-254.117 -157.522)"
        />
        <g transform="translate(9.846 36.877)">
          <path
            className="h"
            d="M263.965,195.186a3.512,3.512,0,0,0,3.543,3.749,4.009,4.009,0,0,0,3.955-3.749,3.512,3.512,0,0,0-3.543-3.749A4.009,4.009,0,0,0,263.965,195.186Z"
            transform="translate(-263.959 -191.437)"
          />
          <path
            className="l"
            d="M263.965,195.186a3.512,3.512,0,0,0,3.543,3.749,4.009,4.009,0,0,0,3.955-3.749,3.512,3.512,0,0,0-3.543-3.749A4.009,4.009,0,0,0,263.965,195.186Z"
            transform="translate(-263.959 -191.437)"
          />
        </g>
        <path
          className="g"
          d="M343.009,123.133H326.132a.783.783,0,0,1-.787-.833h0a.894.894,0,0,1,.879-.833H343.1a.783.783,0,0,1,.787.833h0A.894.894,0,0,1,343.009,123.133Z"
          transform="translate(-293.399 -109.779)"
        />
        <path
          className="g"
          d="M337.1,131.233H325.688a.782.782,0,0,1-.787-.833h0a.893.893,0,0,1,.879-.833h11.414a.782.782,0,0,1,.787.833h0A.893.893,0,0,1,337.1,131.233Z"
          transform="translate(-293.115 -114.963)"
        />
        <path
          className="g"
          d="M332.755,271.173H318.011a.782.782,0,0,1-.787-.833h0a.893.893,0,0,1,.879-.833h14.744a.783.783,0,0,1,.787.833h0A.894.894,0,0,1,332.755,271.173Z"
          transform="translate(-288.201 -204.524)"
        />
        <path
          className="g"
          d="M324.852,279.272h-7.286a.783.783,0,0,1-.787-.833h0a.894.894,0,0,1,.879-.833h7.285a.783.783,0,0,1,.788.833h0A.894.894,0,0,1,324.852,279.272Z"
          transform="translate(-287.917 -209.708)"
        />
        <path
          className="g"
          d="M340.625,166.577H323.749a.894.894,0,0,0-.879.833h0a.783.783,0,0,0,.788.833h16.876a.893.893,0,0,0,.879-.833h0A.782.782,0,0,0,340.625,166.577Z"
          transform="translate(-291.815 -138.649)"
        />
        <path
          className="g"
          d="M335.607,158.477H324.194a.894.894,0,0,0-.879.833h0a.782.782,0,0,0,.787.833h11.414a.893.893,0,0,0,.879-.833h0A.783.783,0,0,0,335.607,158.477Z"
          transform="translate(-292.1 -133.465)"
        />
        <path
          className="g"
          d="M330.727,197.153h-8.655a.783.783,0,0,1-.788-.833h0a.894.894,0,0,1,.879-.833h8.656a.783.783,0,0,1,.787.833h0A.894.894,0,0,1,330.727,197.153Z"
          transform="translate(-290.8 -157.152)"
        />
        <path
          className="g"
          d="M359.886,197.153h-5.1a.783.783,0,0,1-.788-.833h0a.894.894,0,0,1,.879-.833h5.1a.782.782,0,0,1,.787.833h0A.893.893,0,0,1,359.886,197.153Z"
          transform="translate(-311.739 -157.152)"
        />
        <path
          className="g"
          d="M333.041,205.253H321.627a.782.782,0,0,1-.787-.833h0a.893.893,0,0,1,.879-.833h11.414a.782.782,0,0,1,.787.833h0A.893.893,0,0,1,333.041,205.253Z"
          transform="translate(-290.516 -162.336)"
        />
        <path
          className="g"
          d="M278.491,310.552H259.061a1.094,1.094,0,0,1-1.1-1.165h0a1.25,1.25,0,0,1,1.229-1.165h19.431a1.1,1.1,0,0,1,1.1,1.165h0A1.25,1.25,0,0,1,278.491,310.552Z"
          transform="translate(-250.272 -229.301)"
        />
        <path
          className="g"
          d="M339.971,310.552H328.04a1.094,1.094,0,0,1-1.1-1.165h0a1.25,1.25,0,0,1,1.229-1.165H340.1a1.1,1.1,0,0,1,1.1,1.165h0A1.25,1.25,0,0,1,339.971,310.552Z"
          transform="translate(-294.419 -229.301)"
        />
        <path
          className="g"
          d="M273.233,321.882H258.439a1.094,1.094,0,0,1-1.1-1.165h0a1.25,1.25,0,0,1,1.229-1.165h14.794a1.1,1.1,0,0,1,1.1,1.165h0A1.25,1.25,0,0,1,273.233,321.882Z"
          transform="translate(-249.874 -236.553)"
        />
        <path
          className="g"
          d="M288.9,333.212H257.817a1.094,1.094,0,0,1-1.1-1.165h0a1.25,1.25,0,0,1,1.229-1.165h31.085a1.1,1.1,0,0,1,1.1,1.165h0A1.249,1.249,0,0,1,288.9,333.212Z"
          transform="translate(-249.476 -243.804)"
        />
        <path
          className="g"
          d="M324.684,240.6h-5a.893.893,0,0,0-.879.833h0a.783.783,0,0,0,.787.833h5a.894.894,0,0,0,.879-.833h0A.783.783,0,0,0,324.684,240.6Z"
          transform="translate(-289.217 -186.021)"
        />
        <path
          className="g"
          d="M348.032,240.6H342.24a.894.894,0,0,0-.879.833h0a.783.783,0,0,0,.787.833h5.792a.894.894,0,0,0,.879-.833h0A.783.783,0,0,0,348.032,240.6Z"
          transform="translate(-303.649 -186.021)"
        />
        <path
          className="g"
          d="M331.546,232.5H320.133a.893.893,0,0,0-.879.833h0a.782.782,0,0,0,.787.833h11.414a.893.893,0,0,0,.879-.833h0A.782.782,0,0,0,331.546,232.5Z"
          transform="translate(-289.501 -180.838)"
        />
      </g>
      <g transform="translate(39.716 77.976)">
        <path
          className="m"
          d="M160.335,168.324c-1.176.978-2.392,2.038-3.512,3.1-.562.537-1.111,1.083-1.615,1.64a13.946,13.946,0,0,0-1.347,1.689l-.068.1-.034.058a1.173,1.173,0,0,0-.075.146,3.77,3.77,0,0,0-.146.4,7.517,7.517,0,0,0-.224,1,20.921,20.921,0,0,0-.218,2.266c-.076,1.557-.075,3.178-.046,4.767l-1.683.252a30.737,30.737,0,0,1-.866-4.966,18.727,18.727,0,0,1-.06-2.615,10.531,10.531,0,0,1,.168-1.41,5.955,5.955,0,0,1,.206-.77,4.209,4.209,0,0,1,.165-.415l.1-.216c.033-.067.059-.107.09-.162a14.539,14.539,0,0,1,1.534-2.249,25.607,25.607,0,0,1,1.719-1.93,37.084,37.084,0,0,1,3.787-3.388Z"
          transform="translate(-135.984 -148.804)"
        />
        <path
          className="i"
          d="M168.018,160.614a32.709,32.709,0,0,0-4.633,3.888l3.42,4.1s4.849-2.907,5.437-4.512C173.231,161.4,169.76,159.581,168.018,160.614Z"
          transform="translate(-144.283 -145.411)"
        />
        <path
          className="m"
          d="M154.1,213.361l.416,2.439-2.263-.327s-.464-1.657.707-2.227Z"
          transform="translate(-137.099 -179.284)"
        />
        <path
          className="m"
          d="M152.759,221.243l-2.039-.633.609-1.177,2.263.327Z"
          transform="translate(-136.177 -183.243)"
        />
        <path
          className="n"
          d="M214.318,136.434c0,.248.139.45.3.453s.293-.2.29-.444-.139-.45-.3-.453S214.315,136.186,214.318,136.434Z"
          transform="translate(-176.88 -129.839)"
        />
        <path
          className="n"
          d="M214.924,135.734l.6-.163S215.221,136.032,214.924,135.734Z"
          transform="translate(-177.268 -129.572)"
        />
        <path
          className="o"
          d="M214.913,137.9a6.826,6.826,0,0,0,.914,1.593,1.076,1.076,0,0,1-.89.153Z"
          transform="translate(-177.261 -131.063)"
        />
        <path
          className="n"
          d="M212.744,132a.147.147,0,0,1-.071,0,1.211,1.211,0,0,0-1.07.125.146.146,0,0,1-.177-.233,1.505,1.505,0,0,1,1.338-.17.146.146,0,0,1-.019.283Z"
          transform="translate(-174.992 -127.051)"
        />
        <path
          className="m"
          d="M190.632,144.561c.165,1.86-.458,4.91-1.863,5.948,0,0,1.059,1.958,4.452,2.413,3.73.5,1.978-1.6,1.978-1.6-1.985-.756-1.773-2.248-1.269-3.609Z"
          transform="translate(-160.529 -135.325)"
        />
        <path
          className="m"
          d="M210.6,166.742c1.776.486,3.64.949,5.47,1.36.921.2,1.844.382,2.765.56.922.161,1.845.324,2.765.442l.345.047.235.024a5.147,5.147,0,0,0,.543-.007,9.084,9.084,0,0,0,1.242-.169,25.383,25.383,0,0,0,2.64-.692c1.775-.557,3.577-1.225,5.334-1.917l.8,1.5a38.126,38.126,0,0,1-5.21,2.854,22.975,22.975,0,0,1-2.871,1.071,12.035,12.035,0,0,1-1.6.36,8.357,8.357,0,0,1-.9.075c-.159,0-.338,0-.488-.011l-.374-.027a47.562,47.562,0,0,1-5.906-.785,55.315,55.315,0,0,1-5.773-1.4Z"
          transform="translate(-173.868 -149.296)"
        />
        <path
          className="m"
          d="M270.37,164.956l.521-1.769,1.918.722s-.44,2.656-2.084,1.923Z"
          transform="translate(-212.753 -147.246)"
        />
        <path
          className="m"
          d="M272.93,159.93l1.96.221-1.153,1.673-1.918-.722Z"
          transform="translate(-213.68 -145.161)"
        />
        <path
          className="m"
          d="M178.642,394.276l3.086.227.916-7.086-3.086-.227Z"
          transform="translate(-154.048 -290.608)"
        />
        <path
          className="m"
          d="M115.444,361.531l2.095,2.324,5.852-4.045-2.095-2.324Z"
          transform="translate(-113.6 -271.597)"
        />
        <path
          className="h"
          d="M124.036,376.473a.382.382,0,0,1-.111-.019c-.374-.121-.63-.815-.75-1.217a.073.073,0,0,1,.1-.085c.113.06,1.1.6,1.138.994a.249.249,0,0,1-.1.224A.44.44,0,0,1,124.036,376.473Zm-.665-1.1c.178.532.4.88.6.946a.265.265,0,0,0,.253-.061.1.1,0,0,0,.049-.1C124.255,375.949,123.761,375.6,123.371,375.369Z"
          transform="translate(-118.547 -282.898)"
        />
        <path
          className="h"
          d="M124.154,374.949a2.207,2.207,0,0,1-.946-.233.073.073,0,0,1,0-.126c.032-.018.792-.441,1.214-.295a.391.391,0,0,1,.244.228.255.255,0,0,1-.055.318A.715.715,0,0,1,124.154,374.949Zm-.748-.3a1.553,1.553,0,0,0,1.112.076c.027-.023.053-.06.015-.15a.248.248,0,0,0-.157-.145A1.63,1.63,0,0,0,123.406,374.654Z"
          transform="translate(-118.547 -282.336)"
        />
        <path
          className="n"
          d="M115.2,368.545l-1.729-3.066a.246.246,0,0,0-.3-.116l-2.552.979a.5.5,0,0,0-.225.7c.621,1.059.972,1.54,1.733,2.888.468.83,1.188,2.638,1.742,3.831.541,1.167,1.728.725,1.6.18a10.834,10.834,0,0,1-.172-4.851A.832.832,0,0,0,115.2,368.545Z"
          transform="translate(-110.324 -276.628)"
        />
        <path
          className="h"
          d="M189.108,406.394a5.208,5.208,0,0,1-.969-.111.073.073,0,0,1-.019-.137c.141-.072,1.393-.7,1.763-.492a.23.23,0,0,1,.118.195.414.414,0,0,1-.141.355A1.179,1.179,0,0,1,189.108,406.394Zm-.724-.214a1.983,1.983,0,0,0,1.38-.085.27.27,0,0,0,.091-.236.085.085,0,0,0-.044-.077C189.607,405.667,188.9,405.936,188.383,406.18Z"
          transform="translate(-160.088 -302.398)"
        />
        <path
          className="h"
          d="M188.153,405a.07.07,0,0,1-.043-.014.073.073,0,0,1-.03-.067c.006-.055.148-1.317.839-1.317a.582.582,0,0,1,.06,0c.2.02.257.121.27.2.056.349-.691,1.018-1.067,1.185A.071.071,0,0,1,188.153,405Zm.769-1.251c-.445,0-.621.733-.675,1.047.384-.229.892-.755.859-.964,0-.017-.011-.067-.141-.08Z"
          transform="translate(-160.088 -301.109)"
        />
        <path
          className="n"
          d="M180.3,406.285h-3.468a.252.252,0,0,0-.252.211l-.465,2.744a.482.482,0,0,0,.481.549c1.21-.021,1.794-.092,3.32-.092.939,0,2.877.1,4.173.1,1.268,0,1.4-1.282.861-1.4a8.128,8.128,0,0,1-4.137-1.923A.786.786,0,0,0,180.3,406.285Z"
          transform="translate(-152.423 -302.829)"
        />
        <g transform="translate(5.196 85.889)">
          <path
            className="p"
            d="M183.43,387.423l-.509,3.938-3.05-.511.472-3.655Z"
            transform="translate(-160.03 -376.501)"
          />
          <path
            className="p"
            d="M127.255,357.487l2.1,2.325-2.422,1.672-2.171-2.273Z"
            transform="translate(-124.758 -357.487)"
          />
        </g>
        <path
          className="i"
          d="M174.255,179.116c2.416.323,7.54,1.012,12.5,1.679,4.843-17.529,3.414-18,3.414-18a25.61,25.61,0,0,0-2.88-1.139c-.061-.021-.118-.037-.171-.054-.807-.242-.976-.276-2.052-.522-1.845-.324-3.56-.577-5.674-.76-.136-.012-.272-.024-.4-.032-.383-.026-.761-.043-1.127-.058-.247-.013-.493-.015-.727-.017-.278,0-.547,0-.8,0l-.418.006a1.728,1.728,0,0,0-1.68,1.93C175.3,171.367,174.955,174.4,174.255,179.116Z"
          transform="translate(-151.216 -145.34)"
        />
        <path
          className="p"
          d="M210.034,176.719c.085.813,1.966,2.3,2.615,1.9.189-.974.325-1.786.421-2.463C212.117,176.085,210.269,175.043,210.034,176.719Z"
          transform="translate(-174.138 -155.3)"
        />
        <path
          className="i"
          d="M204.367,164.936c-.091-2.868,6.89.293,8.491,1.007l-1.772,4.816a9.844,9.844,0,0,1-3.824-.94A6.044,6.044,0,0,1,204.367,164.936Z"
          transform="translate(-170.511 -147.609)"
        />
        <path
          className="m"
          d="M191,127.17c.231,3.045.263,4.337,1.837,5.92,2.368,2.381,6.092,1.538,6.784-1.489.623-2.724.141-7.246-2.885-8.5A4.084,4.084,0,0,0,191,127.17Z"
          transform="translate(-161.948 -121.372)"
        />
        <path
          className="n"
          d="M172.959,121.484c-3.059-.765-3.155,1.817-3.091,3.155s-2.184,2-2.184,3.7c0,2.21,1.667,3.529-2.387,4.2-3.433.566-4.49,4.854.587,7.361,5.618,2.774,14.1-1.129,13.066-4.841-1.592-5.729,1.258-5.709.937-8.907-.233-2.327,1.967-2.442,1.177-4.381C180.026,119.222,172.689,116.991,172.959,121.484Z"
          transform="translate(-143.65 -118.906)"
        />
        <path
          className="n"
          d="M181.535,129.464c-.421-2.214-.19-3.686.684-4.374,1.127-.886,2.859-.117,2.933-.084l-.149.328c-.016-.008-1.61-.712-2.562.04-.76.6-.946,1.952-.552,4.023Z"
          transform="translate(-155.768 -122.592)"
        />
        <path
          className="n"
          d="M168.188,149.038a7.046,7.046,0,0,1-2.305-.465l.134-.334c.024.01,2.452.962,3.573.032.547-.454.7-1.306.463-2.532l.354-.069c.267,1.365.069,2.334-.588,2.878A2.519,2.519,0,0,1,168.188,149.038Z"
          transform="translate(-145.882 -136.035)"
        />
        <path
          className="m"
          d="M208.678,137.243a2.764,2.764,0,0,0,.42,1.612c.417.679.983.555,1.168-.023a2.055,2.055,0,0,0-.414-1.994C209.3,136.351,208.735,136.611,208.678,137.243Z"
          transform="translate(-173.265 -130.221)"
        />
        <path
          className="n"
          d="M152.24,213.965s-4.852,20.508-7.12,32.289c-2.393,12.429-16.56,22.57-16.56,22.57l-3.111-3.2s9.124-7.984,11.467-19.59c4.757-23.558,1.536-28.3,6.128-33.3Z"
          transform="translate(-120.004 -178.954)"
        />
        <path
          className="q"
          d="M127.921,360.637l-3.884-4,1.094-1.174,4.51,3.973Z"
          transform="translate(-119.1 -270.306)"
        />
        <path
          className="p"
          d="M186.988,227.537c-3.773,3.275-3.365,14.9-2.97,20.136,1.583-7.447,3.486-15.74,4.6-20.539C188.222,226.836,187.682,226.935,186.988,227.537Z"
          transform="translate(-157.331 -188.068)"
        />
        <path
          className="n"
          d="M188.958,215.237s2.183,24.347.992,33.634c-1.238,9.66-7.542,30.677-7.542,30.677l-4.451-.575s3.616-20.827,4.258-30.391c.7-10.426-2.408-34.574-2.408-34.574Z"
          transform="translate(-153.609 -179.772)"
        />
        <path
          className="q"
          d="M181.672,391.98l-6.344-.819.1-1.874,6.882.667Z"
          transform="translate(-151.927 -291.95)"
        />
        <g transform="translate(22.233 32.955)">
          <path
            className="h"
            d="M172.779,210.517l-.682,1.035c-.053.08.041.182.185.2l13.105,1.759c.113.015.212-.027.226-.1l.23-1.1c.016-.076-.075-.154-.2-.171l-12.655-1.7A.223.223,0,0,0,172.779,210.517Z"
            transform="translate(-172.082 -210.448)"
          />
          <path
            className="i"
            d="M172.779,210.517l-.682,1.035c-.053.08.041.182.185.2l13.105,1.759c.113.015.212-.027.226-.1l.23-1.1c.016-.076-.075-.154-.2-.171l-12.655-1.7A.223.223,0,0,0,172.779,210.517Z"
            transform="translate(-172.082 -210.448)"
          />
        </g>
        <path
          className="n"
          d="M177.53,212.3l-.339-.046c-.067-.009-.115-.05-.106-.092l.312-1.421c.009-.041.072-.068.139-.059l.339.046c.067.009.115.05.106.092l-.312,1.421C177.66,212.277,177.6,212.3,177.53,212.3Z"
          transform="translate(-153.05 -177.638)"
        />
        <path
          className="n"
          d="M201.307,215.541l-.339-.046c-.068-.009-.115-.05-.106-.092l.312-1.421c.009-.042.072-.068.139-.059l.339.046c.067.009.115.05.106.092l-.312,1.421C201.437,215.523,201.375,215.55,201.307,215.541Z"
          transform="translate(-168.267 -179.716)"
        />
      </g>
    </g>
  </svg>
)

export default PrfAssistant
