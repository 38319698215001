import React from 'react'

const Close = () => (
  <svg
    className="icon-dark-fill-white"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24">
    <path
      fill="#545456"
      fillRule="nonzero"
      stroke="#FFF"
      strokeWidth=".5"
      d="M22.427 19.505c.382.42.573.917.573 1.49s-.191 1.05-.573 1.432c-.382.382-.86.573-1.432.573-.573 0-1.07-.191-1.49-.573L12 14.922l-7.505 7.505c-.42.382-.898.573-1.433.573-.534 0-1.012-.191-1.432-.573-.42-.382-.63-.86-.63-1.432 0-.573.21-1.07.63-1.49L9.078 12 1.63 4.495c-.42-.42-.63-.917-.63-1.49s.21-1.05.63-1.432C2.05 1.19 2.528 1 3.063 1c.534 0 1.012.191 1.432.573L12 9.078l7.505-7.505c.42-.382.917-.573 1.49-.573s1.05.191 1.432.573c.382.382.573.86.573 1.432 0 .573-.191 1.07-.573 1.49L14.922 12l7.505 7.505z"
    />
  </svg>
)

Close.propTypes = {}

Close.defaultProps = {}

export default Close
