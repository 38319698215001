/* eslint-disable fp/no-mutation */
import React from 'react'

const energySolarToGridLmab = ({className}) => {
  let pointer = 'pointer-solar-grid'
  let motionPath = 'motion-path-solar-grid'

  if (className) {
    pointer = `${pointer}-${className}`
    motionPath = `${motionPath}-${className}`
  }

  return (
    <svg id="SG" xmlns="http://www.w3.org/2000/svg" width="56.498" height="29.318" viewBox="0 0 56.498 29.318">
      <g id="line" transform="translate(0 0)">
        <path
          id="Path_20511"
          data-name="Path 20511"
          d="M-12647.687-16320.572a.225.225,0,0,0,.127-.205v-6.441a.228.228,0,0,0-.127-.2.2.2,0,0,0-.226.047l-3.024,3.223a.21.21,0,0,0-.062.155.217.217,0,0,0,.063.154l3.023,3.222a.207.207,0,0,0,.149.065A.2.2,0,0,0-12647.687-16320.572Z"
          transform="translate(12650.999 16349.875)"
          fill="#01b5de"
        />
        <path
          id={motionPath}
          data-name="Path 20512"
          d="M46,0V19a7,7,0,0,1-7,7H-2"
          transform="translate(3.998)"
          fill="none"
          stroke="#01b5de"
          strokeWidth="1"
        />
      </g>
      <g id={pointer} transform="translate(-2.5 -6.5)">
        <ellipse
          id="Oval_Copy_8"
          data-name="Oval Copy 8"
          cx="6.5"
          cy="6.5"
          rx="6.5"
          ry="6.5"
          transform="translate(0)"
          fill="#01b5de"
          opacity="0.2"
        />
        <ellipse
          id="Oval_Copy_9"
          data-name="Oval Copy 9"
          cx="2.5"
          cy="2.5"
          rx="2.5"
          ry="2.5"
          transform="translate(4 3.999)"
          fill="#01b5de"
        />
      </g>
      <animateMotion xlinkHref={`#${pointer}`} dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
        <mpath xlinkHref={`#${motionPath}`} />
      </animateMotion>
    </svg>
  )
}

export default energySolarToGridLmab
