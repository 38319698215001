import React from 'react'

const UpHand = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38.133" height="47.588" viewBox="0 0 38.133 47.588">
    <g transform="translate(-0.323 -0.297)">
      <path
        fill="#ffffff"
        d="M37.935,24c-.776-4.62-4.033-5.358-5.563-5.445a1.524,1.524,0,0,1-1.193-.675,4.414,4.414,0,0,0-3.857-2.14,6.383,6.383,0,0,0-2.15.4.5.5,0,0,1-.172.031.518.518,0,0,1-.445-.26,4.509,4.509,0,0,0-4.079-2.43,6.519,6.519,0,0,0-1.965.322.487.487,0,0,1-.635-.466V3.406A3.264,3.264,0,0,0,14.481,0a3.264,3.264,0,0,0-3.4,3.406V20.877A1.657,1.657,0,0,1,9.4,22.539a1.618,1.618,0,0,1-1.376-.757L6.376,19.255a3.227,3.227,0,0,0-2.81-1.685A3.421,3.421,0,0,0,.549,22.785L13.406,47.359a.459.459,0,0,0,.432.229H31.9a.46.46,0,0,0,.487-.425l.452-7.343a.46.46,0,0,1,.08-.23C37.4,32.8,38.679,28.439,37.935,24Z"
        transform="translate(0.323 0.297)"
      />
    </g>
  </svg>
)

export default UpHand
