import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'
import {TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'

const initialState = {
  loading: false,
  inProgress: null,
  requestType: null,
  toggleStatus: TOGGLE_SWITCH_STATUS.ON,
}

export const cbfevSlice = createSlice({
  name: 'chargeBatteryForEv',
  initialState,
  reducers: {
    setCbfevValue: (state, action) => {
      const {payload} = action
      const states = {...state}
      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setCbfevValue} = cbfevSlice.actions
export default cbfevSlice.reducer
