import React from 'react'

const LeftArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.18" height="32.12" viewBox="0 0 18.18 32.12">
    <polygon points="16.06 32.12 0 16.06 16.06 0 18.18 2.12 4.24 16.06 18.18 30 16.06 32.12" fill="#ffffff" />
  </svg>
)

LeftArrow.propTypes = {}

LeftArrow.defaultProps = {}

export default LeftArrow
