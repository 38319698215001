import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'

const initialState = {
  loading: false,
  requestType: null,
  isBsInfoChanged: false,
  isToastOpened: false,

  veryLowSoc: null,
  rangeErrorText: null,
}

export const bsSlice = createSlice({
  name: 'batteryShutdown',
  initialState,
  reducers: {
    setBsValue: (state, action) => {
      const {payload} = action
      const states = {...state}
      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setBsValue} = bsSlice.actions
export default bsSlice.reducer
