import React from 'react'

const PowerOutage = () => (
  <svg
    id="Group_17442"
    data-name="Group 17442"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="icon-dark-fill-white">
    <rect id="Rectangle_6868" data-name="Rectangle 6868" width="24" height="24" fill="none" />
    <path
      id="Path_20533"
      data-name="Path 20533"
      d="M13,2.3a1,1,0,0,0-2,0V3.8a1,1,0,1,0,2,0ZM16,16V12a4,4,0,0,0-8,0v4ZM6,12v4a1.5,1.5,0,0,0-1.5,1.5V22h15V17.5A1.5,1.5,0,0,0,18,16V12A6,6,0,0,0,6,12Zm.5,8V18h11v2ZM20.207,6.093a1,1,0,0,1,0,1.414L19.146,8.568a1,1,0,0,1-1.414-1.414l1.061-1.061A1,1,0,0,1,20.207,6.093Zm-15,0A1,1,0,0,0,3.793,7.507L4.854,8.568A1,1,0,1,0,6.268,7.154Z"
      fill="#545456"
      fillRule="evenodd"
    />
  </svg>
)

PowerOutage.propTypes = {}

PowerOutage.defaultProps = {}

export default PowerOutage
