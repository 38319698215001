import React from 'react'

const closeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M14 8C14 6.81331 13.6481 5.65327 12.9888 4.66658C12.3295 3.67988 11.3925 2.91085 10.2961 2.45672C9.19974 2.0026 7.99334 1.88378 6.82946 2.11529C5.66557 2.3468 4.59647 2.91824 3.75736 3.75735C2.91824 4.59647 2.3468 5.66556 2.11529 6.82945C1.88377 7.99333 2.0026 9.19974 2.45672 10.2961C2.91085 11.3925 3.67988 12.3295 4.66658 12.9888C5.65327 13.6481 6.81331 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8ZM15 8C15 9.38447 14.5895 10.7378 13.8203 11.889C13.0511 13.0401 11.9579 13.9373 10.6788 14.4672C9.3997 14.997 7.99224 15.1356 6.63437 14.8655C5.2765 14.5954 4.02922 13.9287 3.05025 12.9498C2.07128 11.9708 1.4046 10.7235 1.1345 9.36563C0.864404 8.00776 1.00303 6.60029 1.53284 5.32121C2.06266 4.04213 2.95986 2.94888 4.11101 2.17971C5.26215 1.41054 6.61553 0.999995 8 0.999995C9.85652 0.999995 11.637 1.73749 12.9497 3.05024C14.2625 4.363 15 6.14348 15 8Z"
      fill="#DE3C41"
    />
    <path
      d="M4.90002 5.69496L7.20502 7.99995L4.90002 10.3049C4.84741 10.3574 4.80567 10.4198 4.77719 10.4884C4.74871 10.5571 4.73405 10.6306 4.73405 10.705C4.73405 10.7793 4.74871 10.8529 4.77719 10.9215C4.80567 10.9901 4.84741 11.0525 4.90002 11.1049C5.00581 11.2097 5.14866 11.2684 5.29752 11.2684C5.44638 11.2684 5.58923 11.2097 5.69502 11.1049L8.00002 8.79495L10.305 11.1C10.3571 11.1524 10.419 11.194 10.4872 11.2225C10.5554 11.2509 10.6286 11.2655 10.7025 11.2655C10.7764 11.2655 10.8496 11.2509 10.9178 11.2225C10.986 11.194 11.048 11.1524 11.1 11.1C11.1525 11.0479 11.1941 10.986 11.2225 10.9178C11.2509 10.8495 11.2656 10.7764 11.2656 10.7025C11.2656 10.6286 11.2509 10.5554 11.2225 10.4872C11.1941 10.4189 11.1525 10.357 11.1 10.3049L8.79502 7.99995L11.1 5.69496C11.1525 5.64291 11.1941 5.58098 11.2225 5.51276C11.2509 5.44453 11.2656 5.37136 11.2656 5.29746C11.2656 5.22355 11.2509 5.15038 11.2225 5.08215C11.1941 5.01393 11.1525 4.952 11.1 4.89995C11.048 4.84749 10.986 4.80586 10.9178 4.77745C10.8496 4.74904 10.7764 4.73441 10.7025 4.73441C10.6286 4.73441 10.5554 4.74904 10.4872 4.77745C10.419 4.80586 10.3571 4.84749 10.305 4.89995L8.00002 7.20496L5.69502 4.89995C5.58923 4.79522 5.44638 4.73648 5.29752 4.73648C5.14866 4.73648 5.00581 4.79522 4.90002 4.89995C4.79529 5.00574 4.73654 5.14859 4.73654 5.29746C4.73654 5.44632 4.79529 5.58917 4.90002 5.69496Z"
      fill="#DE3C41"
    />
  </svg>
)

closeIcon.propTypes = {}

closeIcon.defaultProps = {}

export default closeIcon
