import React from 'react'

const energyGeneratorToBattery = () => (
  <svg
    id="Grid_to_batery"
    data-name="Grid to batery"
    xmlns="http://www.w3.org/2000/svg"
    width="92"
    height="92"
    viewBox="0 0 92 92">
    <g id="Grid-to-batery">
      <rect id="container" width="92" height="92" fill="rgba(255,83,83,0.11)" opacity="0" />
      <g id="pointer-generator-battery" transform="translate(-6 0)">
        <circle id="Oval_Copy_8" cx="6" cy="6" r="6" fill="#9062AC" opacity="0.2" />
        <circle id="Oval_Copy_9" cx="2" cy="2" r="2" transform="translate(4 4)" fill="#9062AC" />
      </g>
      <g id="G_B" transform="translate(0 6)">
        <path
          id="Arrow_head"
          d="M88,84.032a.4.4,0,0,0-.089-.25l-3.429-3.691a.258.258,0,0,0-.312-.062.34.34,0,0,0-.17.3V87.67a.323.323,0,0,0,.17.3.27.27,0,0,0,.313-.052l3.428-3.646A.368.368,0,0,0,88,84.032Z"
          transform="translate(170.001 -2.001) rotate(90)"
          fill="#9062AC"
        />
        <path
          id="motion-path-generator-battery"
          d="M0,0H76A10,10,0,0,1,86,10V84h0"
          fill="none"
          stroke="#9062AC"
          strokeWidth="1"
          fillRule="evenodd"
        />
      </g>
    </g>
    <animateMotion xlinkHref="#pointer-generator-battery" dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
      <mpath xlinkHref="#motion-path-generator-battery" />
    </animateMotion>
  </svg>
)

export default energyGeneratorToBattery
