import React from 'react'

const Tutorial = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="help" transform="translate(20666 15571)">
      <rect
        data-name="Rectangle 6933"
        width="24"
        height="24"
        transform="translate(-20666 -15571)"
        fill="#01b5de"
        opacity="0"
      />
      <path
        d="M1.528,1.31a.218.218,0,0,0-.218.218V16.637a2.6,2.6,0,0,1,1.146-.266H3.6V1.31Zm3.384,0V16.371h9.495V1.528a.218.218,0,0,0-.218-.218ZM3.806,17.681H13.63a2.871,2.871,0,0,0-.208.982,2.871,2.871,0,0,0,.208.982H2.456a.994.994,0,1,1,0-1.965ZM14.189,0H1.528A1.528,1.528,0,0,0,0,1.528V18.663a2.378,2.378,0,0,0,2.456,2.292H15.062a.655.655,0,0,0,.071-1.306l-.005-.005a1.027,1.027,0,0,1-.182-.262,1.866,1.866,0,0,1-.214-.719,1.866,1.866,0,0,1,.214-.719,1.023,1.023,0,0,1,.182-.262l.005-.005a.655.655,0,0,0,.584-.651V1.528A1.528,1.528,0,0,0,14.189,0Zm.964,19.665,0,0Zm0-2,0,0ZM8.746,9.912a.273.273,0,0,0,.273.262h.618A.283.283,0,0,0,9.919,9.9a1.125,1.125,0,0,1,.189-.587,2.33,2.33,0,0,1,.534-.492,2.866,2.866,0,0,0,.8-.8,1.854,1.854,0,0,0,.262-.985,1.6,1.6,0,0,0-.6-1.3,2.617,2.617,0,0,0-1.729-.5,2.75,2.75,0,0,0-1.163.241,2.276,2.276,0,0,0-.859.671,1.951,1.951,0,0,0-.377.9.2.2,0,0,0,.034.14.2.2,0,0,0,.138.086l.91.137a.2.2,0,0,0,.214-.159,1.026,1.026,0,0,1,.308-.536,1.121,1.121,0,0,1,.8-.293,1.031,1.031,0,0,1,.681.21.606.606,0,0,1,.251.492.884.884,0,0,1-.126.482,1.88,1.88,0,0,1-.461.451,4.817,4.817,0,0,0-.555.482,1.476,1.476,0,0,0-.325.534A2.31,2.31,0,0,0,8.746,9.912Zm-.042,2.5a.88.88,0,0,0,.629.262.857.857,0,0,0,.629-.262.88.88,0,0,0,.262-.629.857.857,0,0,0-.262-.629.862.862,0,0,0-1.257,0,.857.857,0,0,0-.262.629A.88.88,0,0,0,8.7,12.416Z"
        transform="translate(-20661.859 -15569.478)"
        fill="#01b4de"
        fillRule="evenodd"
      />
    </g>
  </svg>
)

Tutorial.propTypes = {}

Tutorial.defaultProps = {}

export default Tutorial
