import React from 'react'
import '../styles/loaderV2.scss'

const LoaderV2 = () => (
  <svg className="loader-v2" viewBox="0 0 50 50" width="50px" height="50px">
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
  </svg>
)

LoaderV2.propTypes = {}

LoaderV2.defaultProps = {}

export default LoaderV2
