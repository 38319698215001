/* eslint-disable fp/no-mutation */
import {DOMAIN} from 'lib/utils/constants'
import {getEnlighten4Session} from 'lib/utils/utility'
import HTTP_REQUEST, {HTTPS_METHOD, HTTPS_API_STATUS} from './httpRequest'

/* *********************** *********************** *********************** *
 *  Site Settings API
 * *********************** *********************** *********************** */

let data = null
let promise = null

function callAPI(type, pathName, params = {}) {
  promise = HTTP_REQUEST.makeRequest(type, pathName, params)
  return promise
}

async function getSiteSettings() {
  try {
    data = await callAPI(HTTPS_METHOD.GET, 'SITE_SETTINGS')
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      // message: I18n.t('mobile.form.default.error'),
      error,
    }
    promise = null
    throw data
  }

  return data
}

// Get Hems site activation status
async function getHemsSiteActivationStatus() {
  try {
    data = await callAPI(HTTPS_METHOD.GET, 'HEMS_ACTIVATION_STATUS', {
      domain: DOMAIN.HEMS_INTEGRATION,
      bearerToken: getEnlighten4Session(),
    })

    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      error,
    }
    promise = null
    throw data
  }

  return data
}

export {getSiteSettings, getHemsSiteActivationStatus}
