import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../../../../utils/icon'
import './styles.scss'

class Loader extends React.Component {
  render() {
    const {className, show, inline, noBg} = this.props
    const domId = document.body
    const modal = show && (
      <div className={classNames('bp__loader', {'bp__loader--no-bg': noBg}, className)}>
        <div className="loader__wrapper">
          <Icon src="loaderV4" />
        </div>
      </div>
    )

    if (domId && !inline) {
      return ReactDOM.createPortal(modal, domId)
    }
    return modal
  }
}

Loader.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  inline: PropTypes.bool,
  noBg: PropTypes.bool,
}

Loader.defaultProps = {
  className: null,
  show: false,
  inline: false,
  noBg: false,
}

export default Loader
