import React from 'react'

const InfoV3 = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.8C11.76 14.8 14.8 11.76 14.8 8C14.8 4.24 11.76 1.19995 8 1.19995C4.24 1.19995 1.2 4.24 1.2 8C1.2 11.76 4.24 14.8 8 14.8ZM8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16ZM8 6.3501C8.36 6.3501 8.64999 6.64 8.64999 7V11.6699C8.64999 12.0299 8.36 12.3201 8 12.3201C7.64 12.3201 7.35001 12.0299 7.35001 11.6699V7C7.35001 6.64 7.64 6.3501 8 6.3501ZM8.64999 4.66992C8.64999 4.30992 8.36 4.02002 8 4.02002C7.64 4.02002 7.35001 4.30992 7.35001 4.66992V4.69995C7.35001 5.05995 7.64 5.3501 8 5.3501C8.36 5.3501 8.64999 5.05995 8.64999 4.69995V4.66992Z"
      fill="#A9A9AA"
    />
  </svg>
)

InfoV3.propTypes = {}

InfoV3.defaultProps = {}

export default InfoV3
