/* eslint-disable fp/no-mutation */
import {install} from 'resize-observer'
import Battery from './component/sections/Battery'
import Profile from './component/sections/Profile'
import StormGuard from './component/sections/StormGuard'
import './assets/styles/common.scss'

global.$ = {}
global.jQuery = require('jquery')

global.$ = global.jQuery

if (!window.ResizeObserver) install()

const pages = {Battery, Profile, StormGuard}
export default pages
