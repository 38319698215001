// @ts-nocheck
/* eslint-disable */

import {isWeb} from 'lib/utils/utility'

const Analytics = (function () {
  function triggerGA4Configuration() {
    window?.dataLayer.push({event: 'triggerGA4Configuration'})
  }

  function sendClickEvent(eventLabel, eventCategory) {
    if (canSendAnalytics()) {
      window?.dataLayer.push({event: 'triggerCustomClick', eventLabel, eventType: 'click', eventCategory})
      window?.dataLayer.push({eventLabel: '', eventType: '', eventCategory: ''})
    }
  }

  function canSendAnalytics() {
    let spAnalyticsList = null
    let spGoogleAnalytics = null
    let spConsent = localStorage.getItem('sp_consent')
    let isGoogleAnalyticsEnabled = null

    if (spConsent) {
      spConsent = JSON.parse(spConsent)
    }

    const spAnalytics = spConsent?.AllConsents?.find(obj => obj.ComplianceType === 'Analytics')

    if (spAnalytics) {
      spAnalyticsList = JSON.parse(spAnalytics.PluginPreferences)
      spGoogleAnalytics = spAnalyticsList?.find(obj => obj.ComplianceType === 'Google Analytics')
      isGoogleAnalyticsEnabled = !spGoogleAnalytics?.IsBlockEnabled
    }

    return window.sp && window.sp.settingsPage && isWeb ? isGoogleAnalyticsEnabled : true
  }

  return {
    triggerGA4Configuration,
    sendClickEvent,
    canSendAnalytics,
  }
})()

export default Analytics
