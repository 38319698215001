import React from 'react'

const ItkHelp = () => (
  <svg id="itk-help-icon" xmlns="http://www.w3.org/2000/svg" width="16.099" height="16" viewBox="0 0 16.099 16">
    <rect
      id="_Transparent_Rectangle_"
      data-name="&lt;Transparent Rectangle&gt;"
      width="16.099"
      height="16"
      fill="none"
    />
    <path
      id="Path_121"
      data-name="Path 121"
      d="M165.208-50.008A7.186,7.186,0,0,0,158-42.844a7.186,7.186,0,0,0,7.208,7.164,7.186,7.186,0,0,0,7.208-7.164A7.186,7.186,0,0,0,165.208-50.008Zm0,13.3a6.159,6.159,0,0,1-6.178-6.14,6.16,6.16,0,0,1,6.178-6.14,6.16,6.16,0,0,1,6.178,6.14A6.159,6.159,0,0,1,165.208-36.7Z"
      transform="translate(-156.97 50.653)"
      fill="#4a4a4a"
    />
    <path
      id="Path_123"
      data-name="Path 123"
      d="M.772,0A.768.768,0,1,1,0,.768.77.77,0,0,1,.772,0Z"
      transform="translate(7.466 10.879)"
      fill="#4a4a4a"
    />
    <path
      id="Path_122"
      data-name="Path 122"
      d="M169.99-44.008h-.673a2.31,2.31,0,0,0-2.317,2.3v.256h1.03v-.256a1.283,1.283,0,0,1,1.287-1.279h.7a1.335,1.335,0,0,1,1.354,1.147,1.282,1.282,0,0,1-1.28,1.411h0a1.027,1.027,0,0,0-1.03,1.023v1.279h1.03V-39.4a2.309,2.309,0,0,0,2.306-2.529A2.379,2.379,0,0,0,169.99-44.008Z"
      transform="translate(-161.336 47.723)"
      fill="#4a4a4a"
    />
  </svg>
)

export default ItkHelp
