import alert from './alert'
import leftArrow from './leftArrow'
import batteryReserve from './batteryReserve'
import batteryShutDown from './batteryShutDown'
import close from './close'
import clockCircular from './clockCircular'
import coinStack from './coinStack'
import externalLink from './externalLink'
import evCharger from './evCharger'
import info from './info'
import infoV2 from './infoV2'
import infoV3 from './infoV3'
import loaderV1 from './loaderV1'
import loaderV2 from './loaderV2'
import loaderV3 from './loaderV3'
import loaderV4 from './loaderV4'
import retry from './retry'
import rightTick from './rightTick'
import sun from './sun'
import powerOutage from './powerOutage'

import toastError from './toastError'
import toastInfo from './toastInfo'
import toastSuccess from './toastSuccess'

import tutorialHelp from './tutorialHelp'
import tutorial from './tutorial'
import tutorialVideoIcon from './tutorialVideoIcon'
import chooseRightProfileTutorial from './chooseRightProfileTutorial'
import findRightProfileTutorial from './findRightProfileTutorial'

import batteryCharge from './batteryCharge'
import menuOnGrid from './menuOnGrid'
import upHand from './upHand'
import swipeLeftRightHand from './swipeLeftRightHand'
import systemError from './systemError'

import energySolarCircle from './energySolarCircle'
import energySolarToHomeLmab from './energySolarToHomeLmab'
import energySolarToBatteryLmab from './energySolarToBatteryLmab'
import energySolarToGridLmab from './energySolarToGridLmab'
import energyHomeCircle from './energyHomeCircle'
import energyGridCircle from './energyGridCircle'
import energyGridToHomeConnectedLmab from './energyGridToHomeConnectedLmab'
import energyGridToHomeDisconnected from './energyGridToHomeDisconnected'
import energyGridToBattery from './energyGridToBattery'
import energyGeneratorCircle from './energyGeneratorCircle'
import energyGeneratorToHome from './energyGeneratorToHome'
import energyGenToHomeWoEncharge from './energyGenToHomeWoEncharge'
import energyGeneratorToBattery from './energyGeneratorToBattery'
import energyBatteryCircle from './energyBatteryCircle'
import energyBatteryToHomeLmab from './energyBatteryToHomeLmab'
import energyBatteryToGrid from './energyBatteryToGrid'
import infoAlert from './infoAlert'
import heatPumpIcon from './heatpump'
import checkIcon from './checkIcon'
import closeIcon from './closeIcon'
import warning from './warning'
import error from './error'

// rightprofile
import prfAssistant from './SuggestProfileIcons/prfAssistant'
import upHandDraggable from './SuggestProfileIcons/upHandDraggable'
import engInd from './SuggestProfileIcons/engInd'
import saveMoney from './SuggestProfileIcons/saveMoney'
import powerBkp from './SuggestProfileIcons/powerBkp'
import draggable from './SuggestProfileIcons/draggable'

// itk
import itkHelp from './itkHelp'
import './styles.scss'

export default {
  alert,
  leftArrow,
  batteryReserve,
  batteryShutDown,
  close,
  clockCircular,
  coinStack,
  externalLink,
  evCharger,
  info,
  infoV2,
  infoV3,
  loaderV1,
  loaderV2,
  loaderV3,
  loaderV4,
  retry,
  rightTick,
  sun,
  powerOutage,

  toastError,
  toastInfo,
  toastSuccess,

  tutorialHelp,
  tutorial,
  tutorialVideoIcon,
  chooseRightProfileTutorial,
  findRightProfileTutorial,

  batteryCharge,
  menuOnGrid,
  upHand,
  swipeLeftRightHand,
  systemError,

  energySolarCircle,
  energySolarToHomeLmab,
  energySolarToBatteryLmab,
  energySolarToGridLmab,
  energyHomeCircle,
  energyGridCircle,
  energyGridToHomeConnectedLmab,
  energyGridToHomeDisconnected,
  energyGridToBattery,
  energyGeneratorCircle,
  energyGeneratorToHome,
  energyGenToHomeWoEncharge,
  energyGeneratorToBattery,
  energyBatteryCircle,
  energyBatteryToHomeLmab,
  energyBatteryToGrid,
  prfAssistant,
  upHandDraggable,
  engInd,
  saveMoney,
  powerBkp,
  draggable,
  itkHelp,
  heatPumpIcon,
  infoAlert,
  checkIcon,
  closeIcon,
  warning,
  error,
}
