/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import Icon from 'lib/utils/icon'
import {DIALOG_TYPE} from 'lib/utils/constants'
import {hasSystemCard} from 'lib/utils/siteDetails'

export function getFullBackupDescription() {
  let desc = i18next.t('bp17')
  if (hasSystemCard()) {
    desc += ` ${i18next.t('bp83')}`
  }
  return desc
}

// Full Backup :: Active
function getFullBackupPopupDescription() {
  const desc = <>{i18next.t('bp96')}</>

  return (
    <div className="bp__popup__wrapper">
      <Icon src="alert" />
      {typeof desc === 'string' ? (
        <div className="e_dialog__content" dangerouslySetInnerHTML={{__html: desc}} />
      ) : (
        <div className="e_dialog__content">{desc}</div>
      )}
    </div>
  )
}

// Full Backup :: Popup Details
export const getFullBackupDetails = ({closeThisDialog}) => {
  const fbInfoDescription = getFullBackupPopupDescription()
  const obj = {
    className: 'bp__alert-popup e_dialog--button-w100',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: fbInfoDescription,
    buttons: null,
  }

  obj.buttons = [
    {
      value: i18next.t('bp46'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  return obj
}
