import {useEffect} from 'react'
import _ from 'lodash'
import {getQueryParam} from '../utils/utility'
import {KEY, SUPPORTED_THEME} from '../utils/constants'

export function getTheme(value) {
  const theme = value || getQueryParam(KEY.THEME)
  return theme && _.includes(SUPPORTED_THEME, theme) ? `${theme}-${KEY.THEME}` : `light-${KEY.THEME}`
}

export function isDarkTheme() {
  return getTheme() === getTheme('dark')
}

export function useTheme(value) {
  useEffect(() => {
    const selectedTheme = getTheme(value)
    document.body.classList.add(selectedTheme)

    return () => {
      document.body.classList.remove(selectedTheme)
    }
  })
}
