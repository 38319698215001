/* eslint-disable fp/no-mutation */
import React from 'react'
import {Trans} from 'react-i18next'
import i18next from 'i18next'
import _ from 'lodash'
import {ACTION_TYPE, PENDING_REQ_KEY, PROFILE_STATUS, TOAST_TYPE} from 'lib/utils/constants'
import {isIQGWScheduleSupportedSite} from 'lib/utils/siteDetails'
import {
  isAnyRequestPendingForChargeBatteryFromGrid,
  isAnyRequestPendingForDischargeBatteryToGrid,
  isVeryLowSocRequestPending,
} from '../../organisms/reqPending'
import {getPreviousBatteryReserveValue} from '../BatteryReserve/brCore'

import {isStormAlertActive} from '../../organisms/StormGuardDetails/sgCore'
import {showToast} from '../../atoms/Toast'

// Battery Reserve :: Card Details
export function getCardDetails(details) {
  const isAnyReqPending =
    isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT) ||
    isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  const isMqttReqPending = isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)
  const isReqPending = isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)

  if (details && !isAnyReqPending) {
    return {
      type: PROFILE_STATUS.ACTIVE,
      typeText: 'Active',
      buttonLabel: i18next.t('bp210'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isMqttReqPending) {
    return {
      type: PROFILE_STATUS.PROCESSING,
      typeText: null,
      buttonLabel: i18next.t('bp210'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isReqPending) {
    return {
      type: PROFILE_STATUS.PENDING,
      typeText: i18next.t('bp100'),
      buttonLabel: i18next.t('bp49'),
      buttonActionType: ACTION_TYPE.CANCEL,
    }
  }

  return {
    buttonLabel: i18next.t('bp210'),
    buttonActionType: ACTION_TYPE.SAVE,
  }
}

// Battery Shutdown :: Get Value
export function getBatteryShutdownValue(veryLowSoc, details) {
  const vlsMin = details?.veryLowSocMin
  const vlsMax = details?.veryLowSocMax
  let vls = _.isNil(veryLowSoc) ? details?.veryLowSoc || 5 : veryLowSoc

  if (isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)) {
    vls = details && details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT].veryLowSoc
  }

  if (isVeryLowSocRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)) {
    vls = details && details[PENDING_REQ_KEY.REQUESTED_CONFIG].veryLowSoc
  }

  if (vls > vlsMax) {
    vls = vlsMax
  } else if (vls < vlsMin) {
    vls = vlsMin
  }

  return vls
}

// Battery Shutdown :: Get Description
export function getBatteryShutdownDescription({value}) {
  let text = ''

  if (value) {
    text = (
      <Trans i18nKey="bp50">
        In an outage, your battery will shutdown at <span className="bp__card-view--value">{{value}}</span> charge to
        conserve energy. It restarts by using the conserved energy when the utility grid restores power or solar panels
        start producing energy.
      </Trans>
    )
  }
  return text
}

// Battery Shutdown :: Get Sub Description
export function getBatteryShutdownSubDescription() {
  let text = ''

  text = i18next.t('bp51')
  return text
}

// Battery Shutdown :: Get Range Error Text
export function getRangeErrorText(details, value, brReserve, min) {
  const prevBrReserve = getPreviousBatteryReserveValue(details, details?.prevProfile)

  if (isStormAlertActive(details) && value > prevBrReserve) {
    return <span className="rs__text--error">{i18next.t('bp52', {value})}</span>
  }

  // if (details?.profile === PROFILE_TYPE.BACKUP_ONLY && value > prevBrReserve) {
  //   return (
  //     <span className="rs__text--error">
  //       i18next.t('bp53', {value})
  //     </span>
  //   )
  // }

  if (value <= min) {
    return <span className="rs__text--error">{i18next.t('bp95', {value})}</span>
  }

  if (value > brReserve) {
    return <span className="rs__text--error">{i18next.t('bp54', {brReserve})}</span>
  }
  return null
}

// Battery Shutdown :: Restrict user to perform Battery Shutdown action
export function restrictUserToPerformBsAction({details, isBatteryRequestAnyRunning, showToastMsg, setIsToastOpened}) {
  if (
    isIQGWScheduleSupportedSite() &&
    (isBatteryRequestAnyRunning ||
      isAnyRequestPendingForChargeBatteryFromGrid(details) ||
      isAnyRequestPendingForDischargeBatteryToGrid(details))
  ) {
    const handleCloseToast = () => {
      setIsToastOpened(false)
    }

    if (showToastMsg) {
      setIsToastOpened(true)
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp407'),
        autoClose: 3000,
        closeToast: handleCloseToast,
      })
    }
    return true
  }
  return false
}
