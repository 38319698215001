import React from 'react'

const Retry = () => (
  <svg data-name="refresh_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="Path_20531" data-name="Path 20531" d="M0,0H20V20H0Z" fill="none" />
    <path
      id="Path_20532"
      data-name="Path 20532"
      d="M15.373,5.958a6.666,6.666,0,1,0,1.733,6.373H15.373a5,5,0,1,1-4.707-6.665,4.928,4.928,0,0,1,3.516,1.483L11.5,9.832h5.832V4Z"
      transform="translate(-0.666 -0.665)"
      fill="#01b4de"
    />
  </svg>
)

Retry.propTypes = {}

Retry.defaultProps = {}

export default Retry
