import React from 'react'

const TutorialHelp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="help" transform="translate(20666 15571)">
      <rect
        id="Rectangle_6933"
        data-name="Rectangle 6933"
        width="24"
        height="24"
        transform="translate(-20666 -15571)"
        fill="#fff"
        opacity="0"
      />
      <path
        id="noun-help-book-2637065"
        d="M178.067,50.878H165.435a2.1,2.1,0,0,1-1.053-.282V64.562a1.053,1.053,0,0,0,1.053,1.053h12.632Zm.9-.9a.525.525,0,0,1,.154.372v15.79a.526.526,0,0,1-.526.526H165.435a2.105,2.105,0,0,1-2.105-2.105V48.773a2.105,2.105,0,0,1,2.105-2.105h12.105a.526.526,0,0,1,.526.526v2.632h.526a.525.525,0,0,1,.372.154Zm-7.214,9a.526.526,0,1,1-1.053,0v-.546a1.368,1.368,0,0,1,.98-1.309,1.58,1.58,0,0,0,1.125-1.513,1.612,1.612,0,0,0-1.579-1.579,1.58,1.58,0,0,0-1.579,1.579.526.526,0,1,1-1.053,0,2.632,2.632,0,0,1,2.632-2.632,2.664,2.664,0,0,1,2.632,2.632,2.632,2.632,0,0,1-1.875,2.521.316.316,0,0,0-.23.3Zm-.526,1.368a1.053,1.053,0,1,1-1.053,1.053A1.053,1.053,0,0,1,171.225,60.352Zm5.79-10.526V47.721H165.435a1.053,1.053,0,0,0,0,2.105Z"
        transform="translate(-20825.33 -15615.668)"
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.5"
      />
    </g>
  </svg>
)

TutorialHelp.propTypes = {}

TutorialHelp.defaultProps = {}

export default TutorialHelp
