import React from 'react'

const RightTick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 19 19">
    <path
      fill="#35C37D"
      fillRule="evenodd"
      d="M16.217 2.783a9.498 9.498 0 0 0-13.434 0 9.498 9.498 0 0 0 0 13.434 9.498 9.498 0 0 0 13.434 0 9.498 9.498 0 0 0 0-13.434zm-2.062 4.99l-.635.634-2.774 2.775c-.582.58-1.162 1.16-1.74 1.74-.37.37-1 .37-1.372 0l-.34-.342L4.89 10.17c-.383-.383-.357-.984 0-1.372.354-.386 1.013-.358 1.372 0l.339.341c.574.574 1.148 1.152 1.72 1.726l2.724-2.724 1.74-1.74c.383-.383.984-.357 1.372 0 .384.357.357 1.014-.001 1.373z"
    />
  </svg>
)

RightTick.propTypes = {}

RightTick.defaultProps = {}

export default RightTick
