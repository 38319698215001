import React from 'react'
import {Provider} from 'react-redux'
import StormGuardPage from 'lib/component/common/organisms/StormGuardDetails/stormGuardPage'
import SubPage from 'lib/component/common/organisms/SubPage'
import {useAddUiClasses, useTheme} from 'lib/hook'
import '../../../locale/i18n'
import store from '../../../store'
import './styles.scss'

const StormGuard = () => (
  // Storm Guard
  <Provider store={store}>
    <div id="enho-storm-guard" className="bp__page bp__page--open">
      {useTheme()}
      {useAddUiClasses()}

      <StormGuardPage />
      <SubPage />
    </div>
  </Provider>
)

StormGuard.propTypes = {}

StormGuard.defaultProps = {}

export default StormGuard
