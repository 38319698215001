import React from 'react'

const CoinStack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.978" height="19.726" viewBox="0 0 21.978 19.726">
    <g className="icon-dark-fill-white" transform="translate(0 -0.021)">
      <path
        d="M6.386,6.485a13.554,13.554,0,0,0,1.78-.115V.115A13.563,13.563,0,0,0,6.386,0C2.859,0,0,1.31,0,2.925V3.56C0,5.176,2.859,6.485,6.386,6.485Z"
        transform="translate(0 4.976)"
        fill="#545456"
      />
      <path
        data-name="Path"
        d="M6.386,4.159a13.574,13.574,0,0,0,1.78-.115V2.21a13.561,13.561,0,0,1-1.78.116C3.307,2.326.738,1.328.134,0A1.439,1.439,0,0,0,0,.6v.634C0,2.849,2.859,4.159,6.386,4.159Z"
        transform="translate(0 10.568)"
        fill="#545456"
      />
      <path
        data-name="Path"
        d="M8.165,2.353V2.21a13.561,13.561,0,0,1-1.78.116C3.307,2.326.738,1.328.134,0A1.438,1.438,0,0,0,0,.6v.634C0,2.849,2.859,4.159,6.386,4.159A13.43,13.43,0,0,0,8.4,4.01a2.327,2.327,0,0,1-.238-1.022Z"
        transform="translate(0 13.834)"
        fill="#545456"
      />
      <path
        data-name="Path"
        d="M6.386,0C2.859,0,0,1.31,0,2.925V3.56C0,5.176,2.859,6.485,6.386,6.485s6.386-1.31,6.386-2.925V2.925C12.771,1.31,9.912,0,6.386,0Z"
        transform="translate(9.207 0.021)"
        fill="#545456"
      />
      <path
        data-name="Path"
        d="M6.386,2.326C3.307,2.326.738,1.328.134,0A1.438,1.438,0,0,0,0,.6v.634C0,2.849,2.859,4.159,6.386,4.159s6.386-1.31,6.386-2.925V.6a1.438,1.438,0,0,0-.134-.6C12.033,1.328,9.464,2.326,6.386,2.326Z"
        transform="translate(9.207 5.613)"
        fill="#545456"
      />
      <path
        data-name="Path"
        d="M6.386,2.326C3.307,2.326.738,1.328.134,0A1.438,1.438,0,0,0,0,.6v.634C0,2.849,2.859,4.159,6.386,4.159s6.386-1.31,6.386-2.925V.6a1.438,1.438,0,0,0-.134-.6C12.033,1.328,9.464,2.326,6.386,2.326Z"
        transform="translate(9.207 8.879)"
        fill="#545456"
      />
      <path
        data-name="Path"
        d="M6.386,2.326C3.307,2.326.738,1.328.134,0A1.439,1.439,0,0,0,0,.6v.634C0,2.849,2.859,4.159,6.386,4.159s6.386-1.31,6.386-2.925V.6a1.438,1.438,0,0,0-.134-.6C12.033,1.328,9.464,2.326,6.386,2.326Z"
        transform="translate(9.207 12.322)"
        fill="#545456"
      />
      <path
        data-name="Path"
        d="M6.386,2.326C3.307,2.326.738,1.328.134,0A1.439,1.439,0,0,0,0,.6v.634C0,2.849,2.859,4.159,6.386,4.159s6.386-1.31,6.386-2.925V.6a1.438,1.438,0,0,0-.134-.6C12.033,1.328,9.464,2.326,6.386,2.326Z"
        transform="translate(9.207 15.588)"
        fill="#545456"
      />
    </g>
  </svg>
)

CoinStack.propTypes = {}

CoinStack.defaultProps = {}

export default CoinStack
