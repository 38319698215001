import React from 'react'

const ToastError = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g id="icon" transform="translate(1)">
      <rect
        id="Rectangle_594"
        data-name="Rectangle 594"
        width="18"
        height="18"
        transform="translate(-1)"
        fill="#fff"
        opacity="0"
      />
      <path
        id="Subtraction_3"
        data-name="Subtraction 3"
        d="M1655-2739a8.009,8.009,0,0,1-8-8,8.009,8.009,0,0,1,8-8,8.009,8.009,0,0,1,8,8,8.009,8.009,0,0,1-8,8Zm0-5.658a1.074,1.074,0,0,0-.721.237.754.754,0,0,0-.275.595.746.746,0,0,0,.275.593,1.08,1.08,0,0,0,.721.233,1.093,1.093,0,0,0,.728-.233.747.747,0,0,0,.275-.593.753.753,0,0,0-.275-.6A1.091,1.091,0,0,0,1655-2744.658Zm-.953-6.343.211,5.484h1.486l.211-5.484Z"
        transform="translate(-1647 2756)"
        fill="#fff"
      />
    </g>
  </svg>
)

ToastError.propTypes = {}

ToastError.defaultProps = {}

export default ToastError
