import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'
import {TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'

const initialState = {
  loading: false,
  inProgress: null,
  requestType: null,
  isCbfgInfoChanged: false,
  isDisclaimerLoading: false,
  isDisclaimerSelected: false,
  toggleStatus: TOGGLE_SWITCH_STATUS.OFF,
  chargeFromGridScheduleEnabled: false,
  chargeBeginTime: {formatted12: '02:00 am'},
  chargeEndTime: {formatted12: '05:00 am'},
  showTimeKeeper: false,
  scheduleSupported: false,
}

export const cbfgSlice = createSlice({
  name: 'chargeBatteryFromGrid',
  initialState,
  reducers: {
    setCbfgValue: (state, action) => {
      const {payload} = action
      const states = {...state}

      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setCbfgValue} = cbfgSlice.actions
export default cbfgSlice.reducer
