/* eslint-disable fp/no-mutation */
import {useEffect} from 'react'

export function useTitle(value) {
  useEffect(() => {
    const prevValue = document.title
    document.title = value

    return () => {
      document.title = prevValue
    }
  })
}
