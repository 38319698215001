import _ from 'lodash'
import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import {SUPPORTED_LOCALE} from 'lib/utils/constants'
import en from './en.json'
import es from './es.json'
import enAU from './en_AU.json'
import de from './de.json'
import nl from './nl.json'
import it from './it.json'
import fr from './fr.json'
import pt from './pt.json'
import pl from './pl.json'

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  'en-AU': {
    translation: enAU,
  },
  de: {
    translation: de,
  },
  nl: {
    translation: nl,
  },
  it: {
    translation: it,
  },
  fr: {
    translation: fr,
  },
  pt: {
    translation: pt,
  },
  pl: {
    translation: pl,
  },
}

i18next.use(initReactI18next).init({
  resources,
  lng: SUPPORTED_LOCALE.EN,
  fallbackLng: SUPPORTED_LOCALE.EN,
  supportedLngs: _.values(SUPPORTED_LOCALE),
  interpolation: {
    escapeValue: false,
  },
})
export default i18next
