/* eslint-disable fp/no-delete */

/* eslint-disable fp/no-mutation */

import Paho from 'paho-mqtt'
import moment from 'moment-timezone'
import {getSiteId, isEvseSite, isHemsSite} from 'lib/utils/siteDetails'
import envDetails from 'lib/utils/envDetails'
import urlStrings from 'lib/utils/urlStrings'
import HTTP_REQUEST, {HTTPS_API_STATUS} from './httpRequest'

const mqttWebSocket = (function lsWebSocket() {
  const client = {}
  let latestDataReceivedTime = null
  let responseCounter = 0

  // Get web socket topic
  function getWebSocketTopic(obj) {
    console.debug('[WebSocket Topic] Init')

    return new Promise((resolve, reject) => {
      function cbSuccess(data) {
        console.debug('[WebSocket Topic] Success -', data.topic)
        resolve(data)
      }

      function cbError(error) {
        console.error('[WebSocket Topic] Error ', error)
        reject(error)
      }

      // Active Devices not found
      if (!obj) {
        reject(new Error('Invalid Parameters'))
      } else {
        // Get websocket details
        const pathname = urlStrings.getURL('TOPIC_DETAILS')
        let url = `${pathname}`

        if (obj.liveDebug) {
          url += `&live_debug=${obj.liveDebug}`
        }

        HTTP_REQUEST.GET(url, cbSuccess.bind(this), cbError.bind(this))
      }
    })
  }

  // Get Connection Details
  function getConnectionDetails(obj) {
    let userName = null
    let topic = null
    const awsIotEndpoint = obj.aws_iot_endpoint || ''
    const awsAuthorizerName = 'x-amz-customauthorizer-name'
    const awsAuthorizerValue = obj.aws_authorizer || ''
    const awsTokenKey = obj.aws_token_key || ''
    const awsTokenValue = obj.aws_token_value || ''
    const siteIdKey = 'site-id'
    const siteId = getSiteId() || ''
    const awsAuthorizerSignature = 'x-amz-customauthorizer-signature'
    const awsAuthorizerDigest = encodeURIComponent(obj.aws_digest) || ''
    const envKey = 'env'
    const env = envDetails.getEnv()
    const hostUri = awsIotEndpoint ? `wss://${awsIotEndpoint}/mqtt` : null

    userName = `?${awsAuthorizerName}=${awsAuthorizerValue}&${awsTokenKey}=${awsTokenValue}&${siteIdKey}=${siteId}&${awsAuthorizerSignature}=${awsAuthorizerDigest}&${envKey}=${env}`
    topic = obj.topic

    if (isEvseSite() || isHemsSite()) {
      const countKey = isHemsSite() ? 'hems-count' : 'evse-count'
      const devicesCount = 0
      userName += `&${countKey}=${devicesCount}`
    }

    return {hostUri, userName, topic}
  }

  // Set latest data received time
  function setLatestDataReceivedTime() {
    latestDataReceivedTime = moment()
  }

  // Is WebSocket connected?
  function isConnected(clientId) {
    return client && client[clientId] && client[clientId].isConnected()
  }

  // Is WebSocket disconnected publishing?
  function isDisconnectedPublishing(clientId) {
    return client && client[clientId] && client[clientId].disconnectedPublishing
  }

  // Disconnect WebSocket
  function disconnect(clientId) {
    if (client && client[clientId] && client[clientId].isConnected()) {
      console.debug(`[WebSocket] [${clientId}] Disconnecting...`)

      client[clientId].disconnect()
      delete client[clientId]

      console.debug(`[WebSocket] [${clientId}] Disconnected`)
    }
  }

  // WebSocket handshake happend on given signed url and now connecting to topic
  function onSuccess(clientId, topic) {
    console.debug(`[WebSocket] [${clientId}] Handshake Done. Connecting to topic`, topic)
    client[clientId].subscribe(topic)
  }

  // WebSocket message arrived
  function onMessageArrived(cb, obj, message) {
    console.debug('[WebSocket] Message Arrived', message, JSON.parse(message?.payloadString))
    setLatestDataReceivedTime()

    cb(null, {
      ...message,
      obj,
      status: HTTPS_API_STATUS.SUCCESS,
      latestDataReceivedTime,
    })
  }

  // WebSocket connection lost
  function onConnectionLost(cb, clientId, error) {
    console.error(`[WebSocket] [${clientId}] Connection Lost `, error)

    if (error.errorCode === 0) {
      disconnect(clientId)
    }

    cb(
      {
        ...error,
        status: HTTPS_API_STATUS.CONNECTION_LOST,
      },
      null
    )
  }

  // WebSocket handshake did not happen on given signed url
  function onFailure(cb, clientId, failure) {
    console.error(`[WebSocket] [${clientId}] Connection Failure: `, failure)

    delete client[clientId]
    cb({...failure, status: HTTPS_API_STATUS.FAILURE}, null)
  }

  // WebSocket connection
  function connect(type, clientId, data, cb) {
    const lsDetails = getConnectionDetails(data)
    const {userName} = lsDetails
    const wsUrl = lsDetails.hostUri
    const wsTopic = lsDetails.topic
    const canDecodeBuffer = false
    // let clientId = `bp-paho-mqtt-${getNanoSecTime()}`

    if (wsUrl) {
      responseCounter = 0

      setLatestDataReceivedTime()
      client[clientId] = new Paho.Client(wsUrl, clientId)
      client[clientId].connect({
        onSuccess: onSuccess.bind(this, clientId, wsTopic),
        onFailure: onFailure.bind(this, cb, clientId),
        reconnect: true,
        userName,
      })
      client[clientId].onMessageArrived = onMessageArrived.bind(this, cb, {
        clientId,
        canDecodeBuffer,
        responseCounter,
      })
      client[clientId].onConnectionLost = onConnectionLost.bind(this, cb, clientId)

      console.debug(`[WebSocket] [${clientId}] Initiating MQTT Connection`)
    }
  }

  return {
    getWebSocketTopic,
    setLatestDataReceivedTime,
    connect,
    disconnect,
    isConnected,
    isDisconnectedPublishing,
  }
})()

export default mqttWebSocket
