/* eslint-disable fp/no-mutation */
import _ from 'lodash'
import React from 'react'
import i18next from 'i18next'
import {navigateToEnvoyDevice} from 'lib/common'
import {DIALOG_TYPE, MQTT_PAYLOAD, PROFILE_TYPE, SCHEDULE_ERROR_STATUS} from './constants'
import Icon from './icon'
import {isDTSupportedSite, isIQGWScheduleSupportedSite, isNEM3Site, showDischargeToGrid} from './siteDetails'

// Get request failed alert dialog
export function getRequestFailedAlertDialog({details, closeThisDialog}) {
  let desc = i18next.t('bp359')
  let buttons = [
    {
      value: i18next.t('bp46'),
      action: closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  if (
    !_.includes(
      [SCHEDULE_ERROR_STATUS.THROTTLED, SCHEDULE_ERROR_STATUS.MISCONFIGURED_SITE, SCHEDULE_ERROR_STATUS.INTERNAL_ERROR],
      details.statusCode
    ) &&
    details.isGatewayNotReporting
  ) {
    desc = i18next.t('bp360')
    buttons = [
      {
        value: i18next.t('bp361'),
        action: () => {
          closeThisDialog()
          navigateToEnvoyDevice({redirectFrom: details.redirectFrom})
        },
        disabled: false,
        className: 'bp__ab--mobile-view-hr',
      },
      {
        value: i18next.t('bp99'),
        action: closeThisDialog,
        disabled: false,
        className: 'bp__ab--mobile-view-hr',
      },
    ]
  }

  const content = (
    <div className="bp__popup__wrapper">
      <Icon src="alert" />
      <div className="e_dialog__content">{desc}</div>
    </div>
  )

  const obj = {
    className: 'bp__alert-popup e_dialog--button-w100 dt-tou-alert',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content,
    buttons: null,
  }

  obj.buttons = buttons

  return obj
}

// Show request change error dialog
export function showRequestChangeErrorDialog({data}) {
  if (
    (isDTSupportedSite() || isNEM3Site() || showDischargeToGrid()) &&
    _.has(data, MQTT_PAYLOAD.STATUS_CODE) &&
    !data.systemTask &&
    data.status === 0
  )
    return true
  return false
}

// Show schedule handling msg
export function showScheduleHandlingMsg({data}) {
  if (
    isIQGWScheduleSupportedSite() &&
    data.profile === PROFILE_TYPE.COST_SAVINGS &&
    _.has(data, MQTT_PAYLOAD.STATUS_CODE) &&
    _.includes(
      [
        SCHEDULE_ERROR_STATUS.MISSING_FORECASTS,
        SCHEDULE_ERROR_STATUS.MISSING_TARIFFS,
        SCHEDULE_ERROR_STATUS.MISSING_DATA,
      ],
      data.statusCode
    )
  ) {
    return true
  }
  return false
}
