import {configureStore, combineReducers} from '@reduxjs/toolkit'

import siteSettings from './slices/siteSettings'
import batteryProfile from './slices/batteryProfile'

import loader from './slices/loaderSlice'
import dialogDetails from './slices/dialogDetails'
import profileDetails from '../component/common/organisms/ProfileDetails/pdSlice'
import chargeBatteryFromGrid from '../component/common/molecules/ChargeBatteryFromGrid/cbfgSlice'
import dischargeBatteryToGrid from '../component/common/molecules/DischargeBatteryToGrid/dbtgSlice'
import chargeBatteryForEv from '../component/common/molecules/ChargeBatteryForEv/cbfevSlice'
import batteryReserve from '../component/common/molecules/BatteryReserve/brSlice'
import batteryShutdown from '../component/common/molecules/BatteryShutdown/bsSlice'
import stormGuard from '../component/common/organisms/StormGuardDetails/sgSlice'
import stormAlert from '../component/common/molecules/StormAlerts/saSlice'
import deviceUpdate from '../component/common/molecules/DeviceBehaviour/dbSlice'

const system = combineReducers({
  siteSettings,
  batteryProfile,
})

const components = combineReducers({
  loader,
  profileDetails,
  dialogDetails,
  chargeBatteryFromGrid,
  dischargeBatteryToGrid,
  chargeBatteryForEv,
  batteryReserve,
  batteryShutdown,
  stormGuard,
  stormAlert,
  deviceUpdate,
})

const store = configureStore({
  reducer: {
    system,
    components,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

const getStore = () => store.getState()

// Get Site Settings Details
const getSiteSettingsDetails = () => getStore().system.siteSettings.details

// Get Battery Profile
const getBatteryProfile = () => getStore().system.batteryProfile

// Get Battery Profile Details
const getBatteryProfileDetails = () => getStore().system.batteryProfile.details

// Get Battery Profile Details
const getBatteryReserve = () => getStore().components.batteryReserve

export default store
export {getStore, getSiteSettingsDetails, getBatteryProfile, getBatteryProfileDetails, getBatteryReserve}
