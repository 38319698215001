/* eslint-disable fp/no-mutation */
import React from 'react'

const energyGridToBattery = ({className}) => {
  let pointer = 'pointer-grid-battery'
  let motionPath = 'motion-path-grid-battery'

  if (className) {
    pointer = `${pointer}-${className}`
    motionPath = `${motionPath}-${className}`
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58.363" height="33" viewBox="0 0 58.363 33">
      <g id="PV_to_Grid" data-name="PV to Grid" transform="translate(2.002 -1)">
        <path id="bg" d="M58,0H0V33H58Z" transform="translate(-2 1)" fill="#ffe2e2" opacity="0" />
        <path
          id={motionPath}
          data-name="solar-consumption "
          d="M71.99-10.809l43.194.189c6.732.667,10.933,6.289,10.8,10.792v4.49l.01,10.53"
          transform="translate(-73.99 16.809)"
          fill="none"
          stroke="#6b7073"
          strokeWidth="1"
          fillRule="evenodd"
        />
        <path
          id="Arrow_head"
          d="M0,4.326A.461.461,0,0,0,.089,4.6L3.518,8.624a.243.243,0,0,0,.312.068A.379.379,0,0,0,4,8.363v-8A.359.359,0,0,0,3.83.031a.254.254,0,0,0-.313.057L.089,4.064A.423.423,0,0,0,0,4.326Z"
          transform="translate(47.639 34) rotate(-90)"
          fill="#6b7073"
        />
        <g id={pointer} transform="translate(-81.99 10.809)">
          <ellipse
            id="Oval_Copy_8"
            cx="6.5"
            cy="6.5"
            rx="6.5"
            ry="6.5"
            transform="translate(0 0)"
            fill="#6b7073"
            opacity="0.2"
          />
          <ellipse id="Oval_Copy_9" cx="2.5" cy="2.5" rx="2.5" ry="2.5" transform="translate(4 4)" fill="#6b7073" />
        </g>
      </g>
      <animateMotion xlinkHref={`#${pointer}`} dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
        <mpath xlinkHref={`#${motionPath}`} />
      </animateMotion>
    </svg>
  )
}

export default energyGridToBattery
