/* eslint-disable fp/no-mutation */
import React from 'react'

const energyBatteryToHomeLmab = ({className}) => {
  let pointer = 'pointer-battery-home'
  let motionPath = 'motion-path-battery-home'

  if (className) {
    pointer = `${pointer}-${className}`
    motionPath = `${motionPath}-${className}`
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56.498" height="29.318" viewBox="0 0 56.498 29.318">
      <g id="SH" transform="translate(1.988 0)">
        <g id="line" transform="translate(4.512)">
          <path
            id="Path_20511"
            data-name="Path 20511"
            d="M-12650.872-16327.424a.225.225,0,0,0-.127.205v6.441a.228.228,0,0,0,.127.2.2.2,0,0,0,.226-.047l3.024-3.223a.21.21,0,0,0,.062-.155.217.217,0,0,0-.062-.154l-3.023-3.222a.207.207,0,0,0-.149-.065A.2.2,0,0,0-12650.872-16327.424Z"
            transform="translate(12697.559 16327.439)"
            fill="#78cb37"
          />
          <path
            id={motionPath}
            data-name="Path 20512"
            d="M-2,26V7A7,7,0,0,1,5,0H46"
            transform="translate(2 3.319)"
            fill="none"
            stroke="#78cc37"
            strokeWidth="1"
          />
        </g>
        <g id={pointer} transform="translate(0 -3)">
          <ellipse
            id="Oval_Copy_8"
            data-name="Oval Copy 8"
            cx="6.5"
            cy="6.5"
            rx="6.5"
            ry="6.5"
            transform="translate(0 0)"
            fill="#7acf38"
            opacity="0.2"
          />
          <ellipse
            id="Oval_Copy_9"
            data-name="Oval Copy 9"
            cx="2.5"
            cy="2.5"
            rx="2.5"
            ry="2.5"
            transform="translate(4 4.001)"
            fill="#7acf38"
          />
        </g>
      </g>
      <animateMotion xlinkHref={`#${pointer}`} dur="3s" begin="0s" fill="freeze" repeatCount="indefinite">
        <mpath xlinkHref={`#${motionPath}`} />
      </animateMotion>
    </svg>
  )
}

export default energyBatteryToHomeLmab
