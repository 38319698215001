import React from 'react'

const energyBatteryCircle = () => (
  <svg id="battery" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Oval_Copy_3" data-name="Oval Copy 3" fill="#fff" stroke="#5fd10b" strokeWidth="1.3">
      <circle cx="20" cy="20" r="20" stroke="none" />
      <circle cx="20" cy="20" r="19.35" fill="none" />
    </g>
    <g id="Batery" transform="translate(9.6 14.4)">
      <rect
        id="Rectangle_5236"
        data-name="Rectangle 5236"
        width="24"
        height="24"
        transform="translate(-1.6 -6.4)"
        fill="#f37320"
        opacity="0"
      />
      <g id="Group_17100" data-name="Group 17100" transform="translate(0.4 0.6)">
        <path
          id="Path_12514"
          data-name="Path 12514"
          d="M21.087,9H3V19.852H21.087Z"
          transform="translate(-3 -9)"
          fill="none"
          stroke="#7acf38"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        />
        <path
          id="Path_12515"
          data-name="Path 12515"
          d="M23,11h2.713v7.235H23"
          transform="translate(-4.912 -9.191)"
          fill="none"
          stroke="#7acf38"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
        />
      </g>
    </g>
  </svg>
)

export default energyBatteryCircle
