/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import i18next from 'i18next'
import Icon from 'lib/utils/icon'
import {ACTION_TYPE} from '../../../../utils/constants'
import './styles.scss'

export const IntractiveButton = props => {
  const {className, type, label, loading, loadingIcon, disabled, onClick} = props
  const btnRef = useRef(null)
  let buttonLabel = label

  function getClassNname() {
    const classes = [className]

    if (loading) {
      classes.push('ib__btn--loading')
    } else if (type === ACTION_TYPE.CANCEL) {
      buttonLabel = label || i18next.t('bp99')
      classes.push('ib__btn--cancel')
    } else if (type === ACTION_TYPE.SAVE) {
      buttonLabel = label || i18next.t('bp48')
      classes.push('ib__btn--save')
    } else if (type === ACTION_TYPE.DISABLED) {
      classes.push('bp__ab--disabled')
    }

    return classes.join(' ')
  }

  function onButtonClick() {
    onClick({type, ref: btnRef.current})
  }

  return (
    <button ref={btnRef} type="button" className={getClassNname()} disabled={disabled} onClick={onButtonClick}>
      {loading ? <Icon src={loadingIcon} /> : buttonLabel}
    </button>
  )
}

IntractiveButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(_.values(ACTION_TYPE)).isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  loadingIcon: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

IntractiveButton.defaultProps = {
  className: '',
  loading: false,
  loadingIcon: 'loaderV1',
  disabled: false,
}
