/* eslint-disable fp/no-mutation */
import HTTP_REQUEST, {HTTPS_METHOD, HTTPS_API_STATUS} from './httpRequest'

/* *********************** *********************** *********************** *
 *  Battery Details API
 * *********************** *********************** *********************** */

let data = null
let promise = null

function callAPI(type, pathName, params = {}) {
  promise = HTTP_REQUEST.makeRequest(type, pathName, params)
  return promise
}

async function getBatteryDetails() {
  try {
    data = await callAPI(HTTPS_METHOD.GET, 'BATTERY_CONFIG')
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    data = {
      status: HTTPS_API_STATUS.FAILURE,
      // message: I18n.t('mobile.form.default.error'),
      error,
    }
    promise = null
    throw data
  }

  return data
}

async function setBatteryDetails(body) {
  try {
    data = await callAPI(HTTPS_METHOD.PUT, 'SET_BATTERY_CONFIG', body)
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    promise = null
    throw error
  }

  return data
}

async function setBatteryEVChargeDetails(body) {
  try {
    data = await callAPI(HTTPS_METHOD.PUT, 'SET_BATTERY_EV_CONFIG', body)
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    promise = null
    throw error
  }

  return data
}

async function cancelBatteryRequest(body) {
  try {
    data = await callAPI(HTTPS_METHOD.PUT, 'CANCEL_BATTERY_REQUEST', body)
    data.status = HTTPS_API_STATUS.SUCCESS
  } catch (error) {
    promise = null
    throw error
  }

  return data
}

export {getBatteryDetails, setBatteryDetails, cancelBatteryRequest, setBatteryEVChargeDetails}
