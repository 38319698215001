import React from 'react'

const energyHomeCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_11786" data-name="Group 11786" transform="translate(-34 -255)">
      <g id="imported" transform="translate(34 255)">
        <g
          id="Oval_Copy_2"
          data-name="Oval Copy 2"
          transform="translate(0 0)"
          fill="#fff"
          stroke="#f37320"
          strokeWidth="1.3">
          <circle cx="20" cy="20" r="20" stroke="none" />
          <circle cx="20" cy="20" r="19.35" fill="none" />
        </g>
        <g id="HH" transform="translate(-21.998 -228.5)">
          <rect
            id="Rectangle_5223"
            data-name="Rectangle 5223"
            width="24"
            height="24"
            transform="translate(29.998 236.5)"
            fill="#f37320"
            opacity="0"
          />
          <g id="Consumption" transform="translate(32.399 238.598)">
            <path
              id="fill"
              d="M3.248,19.2a.522.522,0,0,1-.524-.521V10.122H.525A.523.523,0,0,1,.153,9.23L9.228.152A.522.522,0,0,1,9.6,0a.507.507,0,0,1,.368.152L19.046,9.23a.522.522,0,0,1-.371.892h-2.2v8.554a.523.523,0,0,1-.524.521Z"
              transform="translate(0 0)"
              fill="rgba(243,115,32,0.21)"
              opacity="0"
            />
            <path
              id="home_stroke"
              data-name="home stroke"
              d="M3.246,19.2a.523.523,0,0,1-.524-.523V10.123H.524a.524.524,0,0,1-.37-.894L9.229.153a.523.523,0,0,1,.74,0l9.076,9.076a.524.524,0,0,1-.37.894h-2.2v8.553a.523.523,0,0,1-.524.523Zm12.182-1.047V9.6a.523.523,0,0,1,.523-.524h1.459L9.6,1.264,1.788,9.076H3.246A.523.523,0,0,1,3.77,9.6v8.552H7.259V11.868a.524.524,0,0,1,.524-.524h3.63a.524.524,0,0,1,.523.524v6.283Zm-4.539,0v-5.76H8.307v5.76Z"
              transform="translate(0.003)"
              fill="#f37320"
              stroke="#f37320"
              strokeLinecap="round"
              strokeWidth="0.3"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default energyHomeCircle
