import React from 'react'

const Warning = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 32801">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10.25C8.34721 10.25 10.25 8.34721 10.25 6C10.25 3.65279 8.34721 1.75 6 1.75C3.65279 1.75 1.75 3.65279 1.75 6C1.75 8.34721 3.65279 10.25 6 10.25ZM6 11.25C8.8995 11.25 11.25 8.8995 11.25 6C11.25 3.10051 8.8995 0.75 6 0.75C3.10051 0.75 0.75 3.10051 0.75 6C0.75 8.8995 3.10051 11.25 6 11.25ZM5.63717 5.09473L5.45599 5.28476V8.52513L5.63717 8.71516H6.36126L6.54244 8.52513V5.28476L6.36126 5.09473H5.63717ZM5.45599 3.46554L5.63717 3.28436H6.36126L6.54244 3.46554V4.19024L6.36126 4.37142H5.63717L5.45599 4.19024V3.46554Z"
        fill="#6B7073"
      />
    </g>
  </svg>
)

Warning.propTypes = {}

Warning.defaultProps = {}

export default Warning
