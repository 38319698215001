/* eslint-disable fp/no-mutation */
import _ from 'lodash'
import {setBpDetailsValue} from 'lib/store/slices/batteryProfile'
import {getNanoSecTime, objDiff, omit} from 'lib/utils/utility'
import {HTTPS_REQUEST_TYPE, HTTPS_API_STATUS} from 'lib/services/httpRequest'
import webScocket from '../../../services/mqttWebSocket'
import {updateBatterySliceValues} from './BatteryDetails/bdCore'
import {updateProfileSliceValues} from './ProfileDetails/pdCore'
import {getBatteryProfileDetails} from '../../../store'
import {updateDeviceProfileSliceValues} from '../molecules/DeviceBehaviour/dbCore'

let mqttResponseCount = 0
// let latestDataReceivedTime = null

// Close WebSocket connection
export function closeConnection(clientId) {
  webScocket.disconnect(clientId || window.clientId)
}

// Update data in component
function updateMqttData({dispatch, latestData}) {
  const wsData = omit(latestData, ['v', 'messageType', 'type', 'ts', 'deviceDetails'])
  const batteryProfile = getBatteryProfileDetails()
  const dataDiff = objDiff(batteryProfile, wsData)
  console.debug('[updateMqttData] - dataDiff', batteryProfile, dataDiff)
  console.debug(
    '----------------- ----------------- ----------------- ----------------- ----------------- ----------------- -----------------'
  )

  if (_.keys(dataDiff).length > 0) {
    if (latestData.type === 'battery') {
      updateBatterySliceValues({source: wsData.requestType, dispatch, data: wsData})
    } else if (latestData.type === 'profile') {
      updateProfileSliceValues({source: wsData.requestType, dispatch, data: wsData})
    } else if (latestData.type === 'storm') {
      updateProfileSliceValues({type: latestData.type, dispatch, data: wsData})
    }
  }
  if (latestData.type === 'device') {
    updateDeviceProfileSliceValues({source: HTTPS_REQUEST_TYPE.MQTT, dispatch, data: latestData})
  }
}

// WebSocket Callback
function onWebSocketCallback(clientId, {data, wsObj, dispatch}, error) {
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  // const {mqttResponseCount} = this.state

  if (webScocket.isConnected(clientId) && !error) {
    if (!wsObj.keepAlive) {
      console.debug(`[WebSocket] Closing MQTT connection after getting ${mqttResponseCount} responses`)
      closeConnection(false)
    } else if (data && data.payloadString) {
      // latestDataReceivedTime = data.latestDataReceivedTime
      mqttResponseCount += 1
      try {
        const latestData = JSON.parse(data?.payloadString)
        setBatteryData({ws: latestData})

        if (
          data.status === HTTPS_API_STATUS.SUCCESS &&
          (latestData.messageType === 'profile_change_response' || latestData.messageType === 'storm_change_response')
        ) {
          updateMqttData({dispatch, latestData})
        }
      } catch (e) {
        webScocket.disconnect(clientId)
        console.debug(`[WebSocket] Error in data received`, e)
      }
    }
  }
}

// Initiate MQTT connection
export async function initMqtt({dispatch, wsObj}) {
  const clientId = `bp-paho-mqtt-${getNanoSecTime()}`
  window.clientId = clientId

  try {
    const wsDetails = await webScocket.getWebSocketTopic(wsObj)

    webScocket.connect(null, clientId, wsDetails, (error, data) =>
      onWebSocketCallback(clientId, {data, wsObj, dispatch}, error)
    )
  } catch (error) {
    console.error('[WebSocket initMqtt]', error)
  }
}

// Initiate MQTT connection
export function getRequestType(type, {details}) {
  if (type === HTTPS_REQUEST_TYPE.MQTT) {
    return details.supportsMqtt && webScocket.isConnected(window.clientId)
  }

  if (type === HTTPS_REQUEST_TYPE.API) {
    return true
  }

  return false
}
