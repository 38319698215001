import React from 'react'

const hemsHeatPumpOrange = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="HP" transform="translate(-29 -235)">
      <rect
        id="Rectangle_5223"
        data-name="Rectangle 5223"
        width="32"
        height="32"
        transform="translate(29 235)"
        fill="#f37320"
        opacity="0"
      />
      <path
        id="Union_53"
        data-name="Union 53"
        d="M2.132,19.2A2.139,2.139,0,0,1,0,17.068V2.133A2.138,2.138,0,0,1,2.132,0H21.868A2.14,2.14,0,0,1,24,2.133V17.068A2.14,2.14,0,0,1,21.868,19.2ZM1.066,2.133V17.068a1.068,1.068,0,0,0,1.066,1.067H21.868a1.068,1.068,0,0,0,1.066-1.067V2.133a1.067,1.067,0,0,0-1.066-1.066H2.132A1.067,1.067,0,0,0,1.066,2.133ZM2.666,9.6A6.133,6.133,0,0,1,8.8,3.467h0A6.134,6.134,0,1,1,2.666,9.6Zm1.066,0A5.067,5.067,0,1,0,8.8,4.533,5.074,5.074,0,0,0,3.732,9.6ZM16.8,12.8a.533.533,0,1,1,0-1.066h4a.533.533,0,0,1,0,1.066ZM9.113,10.476a.879.879,0,0,1-.624,0c-.571,1.279-1.533,2.166-2.388,2.166a1.377,1.377,0,0,1-1.19-.8c-.847-1.47.551-2.443,2.4-2.443a5.543,5.543,0,0,1,.571.033A.916.916,0,0,1,8.2,8.893c-1.252-1.728-1.266-3.782.6-3.782s1.854,2.054.6,3.782a.931.931,0,0,1,.312.541,5.535,5.535,0,0,1,.568-.033c1.856,0,3.254.974,2.4,2.443a1.37,1.37,0,0,1-1.187.8C10.643,12.643,9.684,11.755,9.113,10.476Zm2.388,1.1c.1,0,.228-.2.262-.264s.152-.271.1-.361a2.03,2.03,0,0,0-1.558-.483C10.73,11.192,11.25,11.576,11.5,11.576Zm-5.76-.625c-.053.09.059.3.1.36s.16.264.264.264c.25,0,.771-.384,1.2-1.107A2.022,2.022,0,0,0,5.741,10.951ZM8.427,6.3A2.135,2.135,0,0,0,8.8,7.867,2.144,2.144,0,0,0,9.172,6.3c-.014-.034-.051-.124-.371-.124S8.441,6.268,8.427,6.3ZM16.8,10.133a.533.533,0,1,1,0-1.066h4a.533.533,0,0,1,0,1.066Zm0-2.633a.533.533,0,1,1,0-1.066h4a.533.533,0,0,1,0,1.066Z"
        transform="translate(33 241.4)"
        fill="#f37320"
        stroke="#f37320"
        strokeWidth="0.3"
      />
      <g
        id="status"
        transform="translate(52 238)"
        fill="#35c37d"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1"
        opacity="0">
        <circle cx="4" cy="4" r="4" stroke="none" />
        <circle cx="4" cy="4" r="4.5" fill="none" />
      </g>
    </g>
  </svg>
)

export default hemsHeatPumpOrange
