import {createSlice} from '@reduxjs/toolkit'
import {updateSpecificKeyValue} from 'lib/utils'

const initialState = {
  show: false,
  inline: false,
  noBg: false,
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoaderValue: (state, action) => {
      const {payload} = action
      const states = {...state}

      return updateSpecificKeyValue(payload, states)
    },
  },
})

// Action creators are generated for each case reducer function
export const {setLoaderValue} = loaderSlice.actions
export default loaderSlice.reducer
