function getEnv() {
  return window.build_environment || 'development'
}

function isEnv(val) {
  return getEnv() === val
}

function getOrigin() {
  return window.build_domain_api || ''
}

const envDetails = {
  isEnv,
  getEnv,
  getOrigin,
}

export default envDetails
