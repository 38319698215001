import React from 'react'
import i18next from 'i18next'
import {PropTypes} from 'prop-types'
import './styles.scss'

const ShowErrorDetails = ({text, subText}) => (
  <div id="comp-show-error-details">
    <div>
      {text || 'Server Error'}
      <div>{subText || i18next.t('bp68')}</div>
    </div>
  </div>
)

ShowErrorDetails.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
}

ShowErrorDetails.defaultProps = {
  text: null,
  subText: null,
}

export default ShowErrorDetails
