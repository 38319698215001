import React from 'react'

const energyGridToHomeDisconnected = () => (
  <svg width="190px" height="13px" viewBox="0 0 190 13" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="none" x="0" y="0" width="190" height="13" />
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#B6B8B9"
        strokeDasharray="2,5"
        strokeLinecap="square"
        d="M10,6.5 L180,6.2485097"
      />
    </g>
  </svg>
)

export default energyGridToHomeDisconnected
