import React from 'react'
import {systemWithEnchargeAndEnpower, showSavingsMode} from 'lib/utils/siteDetails'

const UpHandDraggable = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="115.211" height="171.874" viewBox="0 0 115.211 171.874">
    <g id="Group_11778" data-name="Group 11778" transform="translate(4147.211 -3357.063)">
      <circle
        id="Ellipse_89"
        data-name="Ellipse 89"
        cx="14.5"
        cy="14.5"
        r="14.5"
        transform="translate(-4091 3443)"
        fill="#01b5de"
      />
      <circle
        id="Ellipse_88"
        data-name="Ellipse 88"
        cx="30.5"
        cy="30.5"
        r="30.5"
        transform="translate(-4107 3427)"
        fill="#01b5de"
        opacity="0.462"
      />
      <circle
        id="Ellipse_87"
        data-name="Ellipse 87"
        cx="44.5"
        cy="44.5"
        r="44.5"
        transform="translate(-4121 3413)"
        fill="#01b5de"
        opacity="0.148"
      />
      <g id="swipe-left" transform="translate(-4091 3454)">
        <path
          id="Path"
          d="M37.935,24c-.776-4.62-4.033-5.358-5.563-5.445a1.524,1.524,0,0,1-1.193-.675,4.414,4.414,0,0,0-3.857-2.14,6.383,6.383,0,0,0-2.15.4.5.5,0,0,1-.172.031.518.518,0,0,1-.445-.26,4.509,4.509,0,0,0-4.079-2.43,6.519,6.519,0,0,0-1.965.322.487.487,0,0,1-.635-.466V3.406A3.264,3.264,0,0,0,14.481,0a3.264,3.264,0,0,0-3.4,3.406V20.877A1.657,1.657,0,0,1,9.4,22.539a1.618,1.618,0,0,1-1.376-.757L6.376,19.255a3.227,3.227,0,0,0-2.81-1.685A3.421,3.421,0,0,0,.549,22.785L13.406,47.359a.459.459,0,0,0,.432.229H31.9a.46.46,0,0,0,.487-.425l.452-7.343a.46.46,0,0,1,.08-.23C37.4,32.8,38.679,28.439,37.935,24Z"
          transform="translate(0.323 0.297)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="2"
        />
      </g>
      <g id="Layer_2" data-name="Layer 2" transform="translate(-4101.71 3355.473) rotate(90)">
        {systemWithEnchargeAndEnpower() && showSavingsMode() && (
          <path
            id="Path_9145"
            data-name="Path 9145"
            d="M40.6,33.523l13-11.116L40.6,11.29l-4.989,4.255L40.18,19.41H10V25.4H40.18l-4.567,3.865Z"
            transform="translate(119.862 11.978)"
            fill="#01b5de"
          />
        )}
        <path
          id="Path_9146"
          data-name="Path 9146"
          d="M19.579,23.268,15.011,19.4h30.18V13.41H15.011l4.567-3.865L14.59,5.29l-13,11.116,13,11.116Z"
          transform="translate(0 17.977)"
          fill="#01b5de"
        />
      </g>
    </g>
  </svg>
)

export default UpHandDraggable
