import React from 'react'

const SwipeLeftRightHand = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45.289" height="54.882" viewBox="0 0 45.289 54.882">
    <g transform="translate(6.37 6.499)">
      <path
        fill="#ffffff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        d="M12009.16,7138.883a.458.458,0,0,1-.432-.227l-12.854-24.575a3.42,3.42,0,0,1,3.014-5.216,3.231,3.231,0,0,1,2.812,1.685l1.646,2.528a1.621,1.621,0,0,0,1.377.758,1.658,1.658,0,0,0,1.686-1.664V7094.7a3.4,3.4,0,1,1,6.792,0v9.933a.488.488,0,0,0,.483.489.437.437,0,0,0,.15-.024,6.593,6.593,0,0,1,1.964-.32,4.513,4.513,0,0,1,4.08,2.428.515.515,0,0,0,.443.26.478.478,0,0,0,.173-.03,6.4,6.4,0,0,1,2.15-.4,4.417,4.417,0,0,1,3.856,2.141,1.523,1.523,0,0,0,1.192.674c1.531.087,4.787.827,5.563,5.445.746,4.437-.537,8.8-5.02,15.587a.471.471,0,0,0-.081.232l-.453,7.342a.459.459,0,0,1-.486.423Zm13.485-40.2a1,1,0,0,1,.081-1.414l2.528-2.256h-6.868l0,1a1,1,0,0,1-2,0v-3.005c-.027-.105-.976-6-7.01-6-6.067,0-6.979,5.9-7.017,6.146l0,.825c0,.012.006.024.006.036s-.006.021-.006.033l.006,1.969a1,1,0,0,1-2,0v-1h-6.864l2.524,2.256a1,1,0,1,1-1.331,1.5l-5.319-4.75,5.319-4.751a1,1,0,1,1,1.331,1.492l-2.524,2.256h6.864c.4-2.86,2.779-8.006,9.007-8.006a9.025,9.025,0,0,1,9,7.865v.142h6.877l-2.528-2.256a1,1,0,1,1,1.335-1.492l5.318,4.751-5.318,4.75a1.014,1.014,0,0,1-.667.254A1,1,0,0,1,12022.646,7098.679Z"
        transform="translate(-11995 -7091)"
      />
    </g>
  </svg>
)

export default SwipeLeftRightHand
