import React from 'react'
import {createRoot} from 'react-dom/client'
import {App} from 'World'
import Analytics from './lib/utils/analytics'

Analytics.triggerGA4Configuration()
const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)
